
d3.selection.prototype.moveToFront = function () {
  return this.each(function () {
    this.parentNode.appendChild(this);
  });
};

const getClosestKey = function (num, arr) {
  let k = -1,
    nearestKey,
    nearestVal;
  while (k++ < arr.length - 1) {
    let distance = Math.abs(num - arr[k]);
    if (nearestVal == undefined || nearestVal > distance) {
      nearestKey = k;
      nearestVal = distance;
    };
  }
  return nearestKey;
},
  getKeyByValue = function (object, value) {
    return Object.keys(object).find(key => object[key] === value);
  },
  distance = (a, b) => Math.abs(a - b),
  clone = (obj) => {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = clone(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  };

var dragIni
class GanttChartClick {
  width = 'fit';
  height = 250;
  machine = '';
  startDate = new Date('2020-1-1');
  endDate = new Date((new Date('2020-1-1')).getTime() + (24 * 60 * 60 * 1000 * 7));
  allowedMachines = [];
  shifts = [];
  allowCheckboxex = false;
  tasksPadding = 8;

  __taskElements = [];
  __zoomBrushHeight = 20;
  __cachedDomain = [];
  __eventListeners = { 'task_clicked': [] };
  __margin = {
    top: 15,
    right: 15
  }

  constructor(elementID, config = {}, translate, height = 0, graficos = []) {
    d3.timeFormatDefaultLocale({
      "decimal": ".",
      "thousands": ",",
      "grouping": [3],
      "currency": ["$", ""],
      "dateTime": "%a %b %e %X %Y",
      "date": "%m/%d/%Y",
      "time": "%H:%M:%S",
      "periods": ["AM", "PM"],
      "days": [translate.instant('domingo'), translate.instant('lunes'), translate.instant('martes'), translate.instant('miercoles'), translate.instant('jueves'), translate.instant('viernes'), translate.instant('sabado')],
      "shortDays": [translate.instant('abrDomingo'), translate.instant('abrLunes'), translate.instant('abrMartes'), translate.instant('abrMiercoles'), translate.instant('abrJueves'), translate.instant('abrViernes'), translate.instant('abrSabado')],
      "months": [translate.instant('enero'), translate.instant('febrero'), translate.instant('marzo'), translate.instant('abril'), translate.instant('mayo'), translate.instant('junio'), translate.instant('julio'), translate.instant('agosto'), translate.instant('septiembre'), translate.instant('octubre'), translate.instant('noviembre'), translate.instant('diciembre')],
      "shortMonths": [translate.instant('abrEnero'), translate.instant('abrFebrero'), translate.instant('abrMarzo'), translate.instant('abrAbril'), translate.instant('abrMayo'), translate.instant('abrJunio'), translate.instant('abrJulio'), translate.instant('abrAgosto'), translate.instant('abrSeptiembre'), translate.instant('abrOctubre'), translate.instant('abrNoviembre'), translate.instant('abrDiciembre')]
    });

    if (height != 0) {
      this.height = height;
      this.tasksPadding = 4;
    }

    if (!elementID) return false;
    for (var k in config) {
      if (k == 'data') this.data = clone(config[k]);
      else this[k] = config[k];
    }
    this.__obj_container = document.getElementById(elementID);
    this.__obj_container.innerHTML = "";
    this.__obj_container.style.display = 'flex';

    if (this.width == 'fit') this.width = this.__obj_container.clientWidth;
    this.__realWidth = this.width - (this.__margin.right);
    this.__realHeight = this.height - (this.__margin.top)

    this.__contentHeight = this.__realHeight - (this.__zoomBrushHeight + 20/*bottom axis height*/)

    this.__taskHeight = (this.__contentHeight / this.data.machines.length);

    this.__obj_machione_container = document.createElement('div');
    this.__obj_machione_container.classList.add('machines-container');
    let machinesHTML = '';
    this.data.machines.forEach(value => {
      if (!this.allowCheckboxex) {
        machinesHTML += `<div class="machine" style="height: ${this.__taskHeight}px;"><div></div><div><span>${value.name}</span></div></div>`;
      } else {
        machinesHTML += `<div class="machine" style="height: ${this.__taskHeight}px;"><div></div><div><input type="checkbox" id="gantt-select-checkbox-${value.id}" class="k-checkbox gantt-select-checkbox" style="margin-top: -2px;" value="${value.id}" checked><span style="padding: 0 5px 0 5px;">${value.name}</span></div></div>`;
      }
    });
    this.__obj_machione_container.innerHTML = machinesHTML;
    this.__obj_machione_container.style.marginTop = (this.__margin.top + this.__zoomBrushHeight) + 'px';
    this.__obj_container.append(this.__obj_machione_container);
    this.__realWidth -= this.__obj_machione_container.clientWidth;

    this.__obj = document.createElementNS("http://www.w3.org/2000/svg", "svg")
    this.__obj_container.append(this.__obj);
    this.__obj_d3 = d3.select(this.__obj);
    let self = this;
    let selectRect;
    this.__obj_d3
      .attr('width', this.__realWidth + 'px')
      .attr('height', this.height + 'px')
      .attr('class', "gantt-chart")
      .on("contextmenu", () => d3.event.preventDefault())
      .on('mousedown', function () {
        if (d3.event.button == 2) {
          var e = this,
            origin = d3.mouse(e);
          selectRect.attr('width', 0).attr('display', '')
          origin = [origin[0], origin[1]]
          d3.select("body").classed("noselect", true);
          origin[0] = Math.max(0, Math.min(self.__realWidth, origin[0]));
          origin[1] = Math.max(0, Math.min(self.__realWidth, origin[1]));
          d3.select(window)
            .on("mousemove.zoomRect", function () {
              var m = d3.mouse(e);
              if (m[0] > self.__realWidth) m[0] = self.__realWidth;
              if (m[1] > self.__realHeight) m[1] = self.__realHeight;
              m = [m[0], m[1]]
              m[0] = Math.max(0, Math.min(self.__realWidth, m[0]));
              m[1] = Math.max(0, Math.min(self.__realWidth, m[1]));
              selectRect.attr("x", Math.min(origin[0], m[0]))
                .attr("y", self.__margin.top + self.__zoomBrushHeight)
                .attr("width", Math.abs(((m[0]) - (origin[0]))))
                .attr("height", self.__contentHeight);
            }, true)
            .on("mouseup.zoomRect", function () {
              d3.event.preventDefault();
              d3.select(window).on("mousemove.zoomRect", null).on("mouseup.zoomRect", null);
              d3.select("body").classed("noselect", false);
              var m = d3.mouse(e);
              m[0] = Math.max(0, Math.min(self.__realWidth, m[0]));
              m[1] = Math.max(0, Math.min(self.__contentHeight, m[1]));
              if (m[0] !== origin[0] && m[1] !== origin[1]) {
                let newDomain;
                if (origin[0] < m[0]) {
                  newDomain = [self.xScale.invert(origin[0]), self.xScale.invert(m[0])];
                } else {
                  newDomain = [self.xScale.invert(m[0]), self.xScale.invert(origin[0])];
                }

                self.__zoomBrush.call(self.__zoomBrushController.move, [self.xScaleRef(newDomain[0]), self.xScaleRef(newDomain[1])]);
                for (var i = 0; i < graficos.length; i++) {
                  graficos[i].__zoomBrush.call(graficos[i].__zoomBrushController.move, [graficos[i].xScaleRef(newDomain[0]), graficos[i].xScaleRef(newDomain[1])]);
                }
              }
              selectRect.attr('display', 'none')
            }, true);
        }
        d3.event.stopPropagation();
      })

    this.xScale = d3.scaleTime()
      .domain([this.startDate, this.endDate])
      .range([0, this.__realWidth]);
    this.__cachedRange = this.xScale.range();
    this.__cachedDomain = this.xScale.domain();

    this.yScale = d3.scaleOrdinal()
      .domain(this.data.machines.map((d) => d.id))
      .range(this.data.machines.map((_, k) => k * this.__taskHeight))
    this.__cachedYScaleRange = this.yScale.range();

    this.__taskHeight = this.__taskHeight - (this.tasksPadding * 2); // apply padding after setting yDomain

    this.xScaleRef = d3.scaleTime()
      .domain(this.xScale.domain())
      .range([0, this.__realWidth]);

    this.xGridScale = d3.scaleTime()
      .domain(this.xScale.domain())
      .range(this.xScale.range());

    this.__zoomBrush = this.__obj_d3.append("g")
      .attr('class', "zoom-brush")
      .attr('transform', `translate(0,${this.__margin.top})`);

    this.__zoomBrush.append('rect')
      .attr('class', "bg")
      .attr('width', this.__realWidth)
      .attr('height', this.__zoomBrushHeight);

    this.__xScaleAxis = d3.axisBottom(this.xScale)
      .ticks(this.getXTicks(1))
      .tickFormat(d3.timeFormat("%H:%M"));

    let xScalOffsetX = this.__margin.top + this.__zoomBrushHeight + this.__contentHeight;

    this.__bottomAxis = this.__obj_d3.append("g")
      .attr('class', "axis-bottom")
      .attr('transform', `translate(0,${xScalOffsetX})`)
      .call(this.__xScaleAxis);
    this.__bottomAxis.selectAll('.domain, line').remove();


    this.__zoomBrushController = d3.brushX()
      .extent([[1, 1], [this.__realWidth - 2, this.__zoomBrushHeight - 1]])
      .on("start", function () {
        this.classList.add('grabbing');
      })
      .on("end", function () {
        this.classList.remove('grabbing');
      })
      .on("brush end", () => {
        if ((d3.event.selection && d3.event.sourceEvent) || d3.brushEventException)
          this.setDomain(d3.event.selection.map(this.xScaleRef.invert, this.xScale));
        for (var i = 0; i < graficos.length; i++) {
          graficos[i].setDomain(d3.event.selection.map(this.xScaleRef.invert, this.xScale));
        }
        try {
          var a = d3.event.sourceEvent.sourceEvent.path;
        } catch (error) {
          this.eventoMoverBarraZoom();
        }
      });

    this.eventoMoverBarraZoom = function func() {
    }


    this.__xGridAxis = d3.axisTop()
      .scale(this.xGridScale)
      .ticks(this.getXTicks(0))
      .tickSize(this.__contentHeight, 0, 0)
      .tickFormat("");

    this.__xGridContainer = this.__obj_d3.append("g")
      .attr('class', "x grid")
      .attr('transform', `translate(0,${xScalOffsetX})`)
      .call(this.__xGridAxis);

    this.__yGridAxis = d3.axisRight()
      .scale(this.yScale)
      .tickSize(this.__realWidth, 0, 0)
      .tickFormat("");

    this.__yGridContainer = this.__obj_d3.append("g")
      .attr('class', "y grid")
      .attr('transform', `translate(0,${this.__margin.top + this.__zoomBrushHeight})`)
      .call(this.__yGridAxis)
      .call((e) => e.selectAll('path, g.tick:first-of-type').remove());

    this.shiftContainers = this.__obj_d3.append('g')
      .attr('transform', `translate(0, ${this.__margin.top + this.__zoomBrushHeight})`)
      .attr('clip-path', "url(#taskClip)");

    this.shiftLimiters = this.shiftContainers.selectAll('.delimiter')
      .data(this.shifts)
      .enter().append('g')
      .attr('class', (d) => {
        return "delimiter " + d.clase;
      })
      .attr('transform', (d) => {
        d.x = this.xScale(d.min);
        return `translate(${d.x}, 0)`;
      });

    this.shiftLimiters.append('rect')
      .attr('width', (d) => {
        d.wide = this.xScale(d.max) - d.x;
        return d.wide;
      })
      .attr('height', this.__contentHeight)

    this.shiftLimiters.append('line')
      .attr('class', 'end-stroke')
      .attr('x1', (d) => d.wide)
      .attr('x2', (d) => d.wide)
      .attr('y2', this.__contentHeight)

    this.shiftLimiters.append('line')
      .attr('y2', this.__contentHeight)

    this.__taskContainer = this.__obj_d3.append('g')
      .attr('transform', `translate(0, ${this.__margin.top + this.__zoomBrushHeight})`)
      .attr('class', "task-container")
      .attr('clip-path', "url(#taskClip)");



    this.__definitions = this.__taskContainer.append('defs')
      .html(`
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style="stop-color:rgb(255,255,255,0.15);stop-opacity:1" />
                    <stop offset="100%" style="stop-color:rgb(255,255,255,0);stop-opacity:1" />
                </linearGradient>`);


    let showAllButtonWidth = 110,
      showAllButtonHeight = 35;
    this.__obj_d3.append('g')
      .attr('class', "show-all-button")
      .attr('transform', `translate(${this.__realWidth - showAllButtonWidth},${this.__margin.top + this.__zoomBrushHeight})`)
      .html(` <rect x="0" y="0" width="${showAllButtonWidth}" height="${showAllButtonHeight}" opacity="0" ></rect>
                    <image x="5" y="${showAllButtonHeight / 2 - 10}" width="20" height="20" xlink:href="assets/img/lens.svg" class="amcharts-zoom-out-image"></image>
                    <text x="30" y="${showAllButtonHeight / 2 + 4}" fill="#000000" font-family="Verdana" font-size="11px" opacity="1" text-anchor="center" class="amcharts-zoom-out-label">` + translate.instant('mostrarTodos') + `</text>`)
      //.on('click', () => this.__zoomBrush.call(this.__zoomBrushController.move, [this.xScaleRef(this.startDate), this.xScaleRef(this.endDate)]));
      .on('click', () => this.updateZoomAllCharts(this, graficos, this.startDate, this.endDate));

    selectRect = self.__obj_d3.append("rect").attr("class", "zoom-rect").attr('display', 'none');


    this.__zoomBrushSidesHandlers = this.__zoomBrush.selectAll('.handle--custom')
      .data([{ type: "w" }, { type: "e" }])
      .enter()
      .append('image')
      .attr('class', (d) => `handle--custom handle--${d}`)
      .attr("width", '30px')
      .attr("height", '30px')
      .attr("x", (d) => this.xScaleRef(d.type == 'w' && this.__cachedDomain[0] || this.__cachedDomain[1]) - 15)
      .attr("y", '-6px')
      .attr("xlink:href", 'assets/img/dragIconRoundBig.svg');

    this.__zoomBrush
      .call(this.__zoomBrushController);

    this.__zoomBrush.call(this.__zoomBrushController.move, this.xScale.range()); //we set the brush with the default domain

    this.__zoomBrushTicks = this.__zoomBrush.append('g')
      .attr('class', "x-top-axis")
      .attr('clip-path', "url(#taskClip)")
      .append('g')
      .attr('transform', `translate(0,${this.__zoomBrushHeight + 3})`)
      .call(d3.axisTop(this.xScaleRef)
        .ticks(Math.floor(this.__realWidth / 70))
        .tickFormat((d) => {
          var dayNames = [translate.instant('abrDomingo'), translate.instant('abrLunes'), translate.instant('abrMartes'), translate.instant('abrMiercoles'), translate.instant('abrJueves'), translate.instant('abrViernes'), translate.instant('abrSabado')];
          let checkDate = new Date(d.getTime());
          checkDate.setHours(0); checkDate.setMinutes(0); checkDate.setSeconds(0);
          return checkDate.getTime() == d.getTime() && dayNames[d.getDay()] + " " + d.getDate() || (d.getHours() < 10 ? '0' : '') + d.getHours() + ":" + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
        })
      ).call((d) => this.tickSizeFix(d));

    this.__zoomBrushTicks.selectAll('.domain, line').remove();

    this.__zoomBrushTicks.selectAll("g.tick text")
      .attr("style", (d) => {
        let checkDate = new Date(d.getTime());
        checkDate.setHours(0); checkDate.setMinutes(0); checkDate.setSeconds(0);
        return checkDate.getTime() == d.getTime() && 'font-weight: bold!important;' || null;
      });

    this.__zoomBrush.moveToFront(); // <=
    this.__zoomBrushSidesHandlers.moveToFront(); // <=

    this.__obj_d3.append('clipPath')
      .attr('id', "taskClip")
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.__realWidth)
      .attr('height', this.__contentHeight);

    //Base loaded, load tasks
    this.__loadDataTasks()
  }
  setDomain(domain) {
    this.__cachedDomain = domain;
    this.xScale.domain(domain);
    this.__bottomAxis.call(this.__xScaleAxis)
    this.xGridScale.domain(domain);
    this.__xGridContainer.call(this.__xGridAxis)

    this.__zoomBrushSidesHandlers.attr("x", (d) => this.xScaleRef(d.type == 'w' && this.__cachedDomain[0] || this.__cachedDomain[1]) - 15)

    //Checking x grid ticks to be updated or not
    let targetTickIntervals = this.calcXTickInterval();
    if (!this.__xTickIntervals || this.__xTickIntervals[0] != targetTickIntervals[0]) {
      this.__xTickIntervals = targetTickIntervals;
      this.__xScaleAxis.ticks(this.getXTicks(1));
      this.__xGridAxis.ticks(this.getXTicks(0));
    }
    this.__updateTasks();
  }
  calcXTickInterval() {
    let localDomain = this.__cachedDomain,
      timerXpixelGroup = (localDomain[1] - localDomain[0]) / this.__realWidth; //group of 10 pixels
    if (timerXpixelGroup < 4000) {
      return ['2.5m', '5m'];
    } else if (timerXpixelGroup < 9000) {
      return ['5m', '10m'];
    } else if (timerXpixelGroup < 35192) {
      return ['15m', '30m'];
    } else if (timerXpixelGroup < 61314) {
      return ['30m', '1h'];
    } else if (timerXpixelGroup < 132061) {
      return ['1h', '2h'];
    } else if (timerXpixelGroup < 207524) {
      return ['2h', '4h'];
    } else if (timerXpixelGroup < 731053) {
      return ['4h', '8h'];
    } else if (timerXpixelGroup < 339586) {
      return ['8h', '12h'];
    } else if (timerXpixelGroup < 1419658) {
      return ['12h', '1d'];
    } else {
      return ['1d', '2d'];
    }// no soporta más de 2 meses, pero se puede adaptar
  }
  __d3Times = {
    '2.5m': d3.timeMinute.every(2.5),
    '5m': d3.timeMinute.every(5),
    '10m': d3.timeMinute.every(10),
    '15m': d3.timeMinute.every(15),
    '30m': d3.timeMinute.every(30),
    '1h': d3.timeHour.every(1),
    '2h': d3.timeHour.every(2),
    '4h': d3.timeHour.every(4),
    '8h': d3.timeHour.every(8),
    '1d': d3.timeDay.every(1),
    '2d': d3.timeDay.every(2)
  }
  getXTicks(type) {
    if (!this.__xTickIntervals) this.__xTickIntervals = this.calcXTickInterval();
    return this.__d3Times[this.__xTickIntervals[type]];
  }
  tickSizeFix(d, checkStart = false) {
    let self = this;
    d.selectAll('g').each(function () {
      let x = parseFloat(this.getAttribute('transform').replace('translate(', '').replace(')', '').split(',')[0]);
      if ((checkStart && x < 0) || self.__realWidth < x + this.getBBox().width) this.remove();
    });
  }
  on(event, callback) {
    this.__eventListeners[event].push(callback);
    return this;
  }
  __callEvent(event, ...args) {
    this.__eventListeners[event].forEach((func) => func.apply(this, args));
  }
  __imageFiltersCache = { length: 0 };
  __getFillFilter(d) {
    if (d.filter) {
      if (!this.__imageFiltersCache[d.filter.src + (d.filter.animated && 'animated' || '')]) {
        let uid = 'task-filter-' + this.__imageFiltersCache.length;
        this.__imageFiltersCache.length++;
        this.__imageFiltersCache[d.filter.src] = {
          uid: '#' + uid,
          element: this.__definitions.append('pattern')
            .attr('id', uid)
            .attr('x', 0)
            .attr('y', 0)
            .attr('class', d.filter.animated && 'filter-animated' || undefined)
            .attr('width', this.__taskHeight / d.filter.size[1] * d.filter.size[0] + 'px')
            .attr('height', this.__taskHeight + 'px')
            .attr('patternUnits', 'userSpaceOnUse')
            .html(`<image xlink:href="${d.filter.src}" x="0" y="0" height="${this.__taskHeight}"></image>`)
        };
      }
      return this.__imageFiltersCache[d.filter.src].uid;
    }
    return '#gradient';
  }
  updateTasks(newData) {
    this.data.tasks = clone(newData);
    this.__displayTasks.remove();
    this.__loadDataTasks();
  }
  updateTask(taskId, newData) {
    for (var taskKey in this.data.tasks) if (taskId == this.data.tasks[taskKey].id) this.data.tasks[taskKey] = clone(newData);
    this.__displayTasks.remove();
    this.__loadDataTasks();
  }
  async __loadDataTasks() {
    for (var k in this.data.tasks) {
      this.data.tasks[k].startDate = new Date(this.data.tasks[k].startDate);
      this.data.tasks[k].endDate = new Date(this.data.tasks[k].endDate);
    }

    this.__displayTasks = this.__taskContainer.selectAll('rect')
      .data(this.data.tasks)
      .enter().append('g')
      .attr('transform', (d) => {
        d.y = this.yScale(d.machine) + this.tasksPadding;
        return this.__getTaskTransform(d);
      });
    let self = this;
    this.__displayTasksRects = this.__displayTasks.append('rect')
      .attr('fill', (d) => d.backgroundColor)
      // .attr('rx', (d) => 5)
      // .attr('ry', (d) => 5)
      .attr('width', function (d) { return self.__getTaskWide(d, this.parentElement); })
      .attr('height', this.__taskHeight);

    let automaticScrollMargin = this.__realWidth * .1,
      [tooltip, tooltipText, tooltipArrow] = (() => {
        let arrow = document.createElement('div');
        arrow.classList.add('arrow');
        let p = document.createElement('p');
        let ttip = document.createElement('div');
        ttip.classList.add('tooltip-gantt');
        ttip.style.display = 'none';
        ttip.style.position = 'fixed';
        ttip.append(p);
        ttip.append(arrow);
        //this.__obj_container.append(ttip);
        document.body.append(ttip);
        return [ttip, p, arrow];
      })();

    let mousePressed;
    this.__displayTasksRectsGradient = this.__displayTasks.append('rect')
      .attr('fill', (d) => `url(${this.__getFillFilter(d)})`)
      .attr('x', (d) => ((d.wide > .3 && .4) || 0))
      .attr('y', '.2')
      // .attr('rx', (d) => 5)
      // .attr('ry', (d) => 5)
      .attr('width', (d) => ((d.wide > .5 && d.wide - .4) || d.wide))
      .attr('height', this.__taskHeight - .4)
      .on('click', (d) => { this.__callEvent('task_clicked', d.id, d.identificadorGrid) })
      .on('mouseenter, mousemove', (d) => {
        tooltipText.innerHTML = d.detail(d);
        tooltip.style.borderColor = d.backgroundColor;
        tooltipArrow.style.borderTopColor = d.backgroundColor;
        tooltip.style.display = '';
        let offsetTop = (d.y + this.getChartSVGOffset().top + self.__zoomBrushHeight - tooltip.clientHeight - 20);
        if (offsetTop < 0) {
          tooltip.style.top = (d.y + this.getChartSVGOffset().top + self.__zoomBrushHeight + self.__taskHeight + 20) + 'px';
          if (!tooltip.classList.contains('bottom-reverse')) tooltip.classList.add('bottom-reverse');
        } else {
          tooltip.style.top = offsetTop + 20 + 'px';
          if (tooltip.classList.contains('bottom-reverse')) tooltip.classList.remove('bottom-reverse');
        }
        tooltip.style.left = (this.getSVGPosFromScreenPos(d3.event.pageX) - (tooltip.clientWidth / 2)) + 'px';
      })
      .on('mouseleave', () => tooltip.style.display = 'none')
      .call(d3.drag()
        .on("start", function (d) {
          setTimeout(() => {
            if (tooltip.style.display != 'none')
              tooltip.style.display = 'none';
            let shouldAdd = this.parentElement.classList.contains('fadeout') ? true : -1;
            self.__displayTasksRectsGradient.each(function (taskD) {
              if (taskD.of != d.of) {
                if (shouldAdd == -1)
                  shouldAdd = !this.parentElement.classList.contains('fadeout');
                if (shouldAdd)
                  this.parentElement.classList.add('fadeout');
                else
                  this.parentElement.classList.remove('fadeout');
              } else {
                if (this.parentElement.classList.contains('fadeout'))
                  this.parentElement.classList.remove('fadeout');
                d3.select(this.parentElement).moveToFront();
              }
            })
          }, 150);
        }));
    this.__displayTasks.each(function (d) {
      let textWide = d.of.length * 10;
      if (d.wide > textWide && d.isOfVisible != true) self.__showTaskOf(this, d);
      if ((!d.isVisible || d.wide <= textWide) && d.isOfVisible == true) self.__hideTaskOp(this, d);
    })
    let filterAnimated = d3.selectAll('pattern.filter-animated'),
      x = 0;
    setInterval(() => {
      x += .5;
      filterAnimated.each(function () {
        this.setAttribute('x', x);
      });
    }, 50)
    this.__updateTasks();
  }

  getChartSVGOffset() {
    return this.__obj.getBoundingClientRect();
  }
  getSVGPosFromScreenPos(x, y) {
    let svgBCR = this.getChartSVGOffset();
    return !isNaN(x) ? (
      !isNaN(y) ? {
        top: x - svgBCR.top,
        left: y - svgBCR.left
      } : x
    ) : !isNaN(y) ? y : false;
  }
  __getTaskTransform(d) {
    d.x = this.xScale(d.startDate);
    d.isVisible = this.__isVisible(d);
    return this.__getTranslation(d);
  }
  __getTranslation = (d) => 'translate(' + d.x + ', ' + d.y + ')';
  __getTaskWide(d) {
    if (d.isVisible) {
      d.wide = this.xScale(d.endDate) - d.x;
    } else {
      d.wide = 0;
    }
    return d.wide;
  }
  __isVisible(d) {
    return (d.startDate.getTime() < this.__cachedDomain[1].getTime() && d.endDate.getTime() > this.__cachedDomain[0].getTime());
  }
  __showTaskOf(taskElement, d) {
    d.isOfVisible = true;
    d3.select(taskElement).append('text')
      .attr('x', 5)
      .attr('y', 15)
      .text((d) => d.of)
  }
  __hideTaskOp(taskElement, d) {
    delete d.isOfVisible;
    taskElement.children[taskElement.children.length - 1].remove()
  }
  async __updateTasks() {
    let self = this;
    //check if scale was modifyed or not (to cahnge only the transforms)        
    this.__displayTasks
      .each(function (d) {
        if (!d.dragging) {
          this.setAttribute('transform', self.__getTaskTransform(d));
          this.children[0].setAttribute('width', self.__getTaskWide(d))
          this.children[1].setAttribute('width', (d.wide > .5 && d.wide - .4) || d.wide)
          let textWide = d.of.length * 10;
          if (d.wide > textWide && d.isOfVisible != true) self.__showTaskOf(this, d);
          if ((!d.isVisible || d.wide <= textWide) && d.isOfVisible == true) self.__hideTaskOp(this, d);
        }
      });


    this.shiftLimiters
      .attr('transform', (d) => {
        d.x = this.xScale(d.min);
        return `translate(${d.x}, 0)`;
      });

    this.shiftLimiters.selectAll('rect')
      .attr('width', (d) => {
        d.wide = this.xScale(d.max) - d.x;
        return d.wide;
      });

    this.shiftLimiters.selectAll('line.end-stroke')
      .attr('x1', (d) => d.wide)
      .attr('x2', (d) => d.wide);

  }
  updateZoomAllCharts(self, graficos, startDate, endDate) {
    self.__zoomBrush.call(self.__zoomBrushController.move, [self.xScaleRef(startDate), self.xScaleRef(endDate)]);
    for (var i = 0; i < graficos.length; i++) {
      graficos[i].__zoomBrush.call(graficos[i].__zoomBrushController.move, [graficos[i].xScaleRef(startDate), graficos[i].xScaleRef(endDate)]);
    }
  }
}


//#region GANTT PLANIFICADOR CORTO
var desvioMarcador = 0;
var OFfiltradas = [];
class GanttChartDrag {
  width = 'fit';

  height = 500;
  machine = '';
  startDate = new Date('2020-1-1');
  endDate = new Date((new Date('2020-1-1')).getTime() + (24 * 60 * 60 * 1000 * 7));
  allowedMachines = [];
  shifts = [];
  tasksPadding = 8;
  enableHoverLines = false;
  marcadores = false;
  __taskElements = [];
  __zoomBrushHeight = 20;
  __cachedDomain = [];
  __eventListeners = { 'task-dropped': [], 'task-ctrl-clicked': [] };
  __margin = { top: 0, right: 0 }
  constructor(elementID, config = {}, translate) {

    if (this.OFfiltradas == undefined) this.OFfiltradas = []; // Para facilitar el contro de los task resaltadas

    d3.timeFormatDefaultLocale({
      "decimal": ".",
      "thousands": ",",
      "grouping": [3],
      "currency": ["$", ""],
      "dateTime": "%a %b %e %X %Y",
      "date": "%m/%d/%Y",
      "time": "%H:%M:%S",
      "periods": ["AM", "PM"],
      "days": [translate.instant('domingo'), translate.instant('lunes'), translate.instant('martes'), translate.instant('miercoles'), translate.instant('jueves'), translate.instant('viernes'), translate.instant('sabado')],
      "shortDays": [translate.instant('abrDomingo'), translate.instant('abrLunes'), translate.instant('abrMartes'), translate.instant('abrMiercoles'), translate.instant('abrJueves'), translate.instant('abrViernes'), translate.instant('abrSabado')],
      "months": [translate.instant('enero'), translate.instant('febrero'), translate.instant('marzo'), translate.instant('abril'), translate.instant('mayo'), translate.instant('junio'), translate.instant('julio'), translate.instant('agosto'), translate.instant('septiembre'), translate.instant('octubre'), translate.instant('noviembre'), translate.instant('diciembre')],
      "shortMonths": [translate.instant('abrEnero'), translate.instant('abrFebrero'), translate.instant('abrMarzo'), translate.instant('abrAbril'), translate.instant('abrMayo'), translate.instant('abrJunio'), translate.instant('abrJulio'), translate.instant('abrAgosto'), translate.instant('abrSeptiembre'), translate.instant('abrOctubre'), translate.instant('abrNoviembre'), translate.instant('abrDiciembre')]
    });

    if (!elementID) return false;
    for (var k in config) {
      if (k == 'data') this.data = clone(config[k]);
      else this[k] = config[k];
    }
    this.__obj_container = document.getElementById(elementID);
    this.__obj_container.innerHTML = "";
    this.__obj_container.style.display = 'flex';
    this.__obj_container.style.overflow = 'hidden';
    this.__obj_container.style.paddingRight = '20px';

    if (this.width == 'fit') this.width = this.__obj_container.clientWidth;
    this.__realWidth = this.width - (this.__margin.right);
    this.__realHeight = this.height - (this.__margin.top) - 27;

    this.__contentHeight = this.__realHeight - (this.__zoomBrushHeight + 20/*bottom axis height*/)

    this.__taskHeight = 47;// (this.__contentHeight / this.data.machines.length);

    this.bottomScaleHeight = 20;

    this.__obj_machione_container = document.createElement('div');
    this.__obj_machione_container.style.overflow = 'hidden';
    this.__obj_machione_container.classList.add('machines-container');
    let machinesHTML = '';
    this.data.machines.forEach(value => {
      if (value.name != '')
        machinesHTML += `<div class="machine" style="height: ${this.__taskHeight}px;"><div><img src="${value.imagenBase64}"></div><div><span>${value.name}</span></div></div>`;
      else
        machinesHTML += `<div class="machine" style="height: ${this.__taskHeight}px;"></div>`;
    });
    this.__obj_machione_container.innerHTML = machinesHTML;
    this.__obj_machione_container.style.marginTop = (this.__margin.top + this.__zoomBrushHeight) + 'px';
    this.__obj_machione_container.style.height = 'calc( 100% - ' + (this.__margin.top + this.__zoomBrushHeight + this.bottomScaleHeight) + 'px )';
    this.__obj_container.append(this.__obj_machione_container);
    this.__realWidth -= this.__obj_machione_container.clientWidth;
    this.__realHeight = this.__obj_machione_container.clientHeight;

    this.__obj = document.createElementNS("http://www.w3.org/2000/svg", "svg")
    this.__obj_container.append(this.__obj);
    this.__obj_d3 = d3.select(this.__obj);
    let self = this;
    let selectRect;
    this.__obj_d3
      .attr('width', this.__realWidth + 'px')
      .attr('height', this.__realHeight + 'px')
      .attr('class', "gantt-chart")
      .style('margin-top', this.__margin.top + 'px')
      .style('overflow', 'hidden')
      .style('width', 'calc(100% - 200px)')
      .on("contextmenu", () => d3.event.preventDefault())
      .on('mousedown', function () {
        if (d3.event.button == 2) {
          var e = this,
            origin = d3.mouse(e);
          selectRect.attr('width', 0).attr('display', '')
          origin = [origin[0], origin[1]]
          d3.select("body").classed("noselect", true);
          origin[0] = Math.max(0, Math.min(self.__realWidth, origin[0]));
          origin[1] = Math.max(0, Math.min(self.__realWidth, origin[1]));
          d3.select(window)
            .on("mousemove.zoomRect", function () {
              var m = d3.mouse(e);
              if (m[0] > self.__realWidth) m[0] = self.__realWidth;
              if (m[1] > self.__realHeight) m[1] = self.__realHeight;
              m = [m[0], m[1]]
              m[0] = Math.max(0, Math.min(self.__realWidth, m[0]));
              m[1] = Math.max(0, Math.min(self.__realWidth, m[1]));
              selectRect.attr("x", Math.min(origin[0], m[0]))
                .attr("y", self.__margin.top + self.__zoomBrushHeight)
                .attr("width", Math.abs(((m[0]) - (origin[0]))))
                .attr("height", self.__contentHeight);
            }, true)
            .on("mouseup.zoomRect", function () {
              d3.event.preventDefault();
              d3.select(window).on("mousemove.zoomRect", null).on("mouseup.zoomRect", null);
              d3.select("body").classed("noselect", false);
              var m = d3.mouse(e);
              m[0] = Math.max(0, Math.min(self.__realWidth, m[0]));
              m[1] = Math.max(0, Math.min(self.__contentHeight, m[1]));
              if (m[0] !== origin[0] && m[1] !== origin[1]) {
                let newDomain;
                if (origin[0] < m[0]) {
                  newDomain = [self.xScale.invert(origin[0]), self.xScale.invert(m[0])];
                } else {
                  newDomain = [self.xScale.invert(m[0]), self.xScale.invert(origin[0])];
                }
                self.__zoomBrush.call(self.__zoomBrushController.move, [self.xScaleRef(newDomain[0]), self.xScaleRef(newDomain[1])]);
              }
              selectRect.attr('display', 'none')
            }, true);
        }
        d3.event.stopPropagation();
      })
      .on('wheel', () => this.onWheel(d3.event));

    d3.select(this.__obj_machione_container)
      .on('wheel', () => this.onWheel(d3.event));

    this.xScale = d3.scaleTime()
      .domain([this.startDate, this.endDate])
      .range([0, this.__realWidth]);
    this.__cachedRange = this.xScale.range();
    this.__cachedDomain = this.xScale.domain();

    this.yScale = d3.scaleOrdinal()
      .domain(this.data.machines.map((d) => d.id))
      .range(this.data.machines.map((_, k) => k * this.__taskHeight))
    this.__cachedYScaleRange = this.yScale.range();

    this.__taskHeight = this.__taskHeight - (this.tasksPadding * 2); // apply padding after setting yDomain

    this.xScaleRef = d3.scaleTime()
      .domain(this.xScale.domain())
      .range([0, this.__realWidth]);

    this.xGridScale = d3.scaleTime()
      .domain(this.xScale.domain())
      .range(this.xScale.range());

    this.__zoomBrush = this.__obj_d3.append("g")
      .attr('class', "zoom-brush")
      .attr('fill', "#FFFFFF")
      .attr('transform', `translate(0,${this.__margin.top})`);

    this.__zoomBrush.append('rect')
      .attr('class', "bg")
      .attr('width', this.__realWidth)
      .attr('height', this.__zoomBrushHeight);

    this.__xScaleAxis = d3.axisBottom(this.xScale)
      .ticks(this.getXTicks(1))
      .tickFormat(function (d) {
        if (d.getHours() === 0 && d.getMinutes() === 0) {
          return d3.timeFormat("%B %d")(d);
        } else {
          return d3.timeFormat("%H:%M")(d);
        }
      });

    let xScalOffsetX = this.__margin.top + this.__zoomBrushHeight + this.__contentHeight;

    this.__zoomBrushController = d3.brushX()
      .extent([[1, 1], [this.__realWidth - 2, this.__zoomBrushHeight - 1]])
      .on("start", function () { this.classList.add('grabbing'); })
      .on("end", function () { this.classList.remove('grabbing'); })
      .on("brush end", () => {
        if ((d3.event.selection && d3.event.sourceEvent) || d3.brushEventException) this.setDomain(d3.event.selection.map(this.xScaleRef.invert, this.xScale));
      });

    this.__xGridAxis = d3.axisTop()
      .scale(this.xGridScale)
      .ticks(this.getXTicks(0))
      .tickSize(this.__contentHeight, 0, 0)
      .tickFormat("");

    this.__xGridContainer = this.__obj_d3.append("g")
      .attr('class', "x grid")
      .attr('transform', `translate(0,${xScalOffsetX})`)
      .call(this.__xGridAxis);

    this.__yGridAxis = d3.axisRight()
      .scale(this.yScale)
      .tickSize(this.__realWidth, 0, 0)
      .tickFormat("");

    this.__yGridContainer = this.__obj_d3.append("g")
      .attr('class', "y grid")
      .attr('transform', `translate(0,${this.__margin.top + this.__zoomBrushHeight})`)
      .call(this.__yGridAxis)
      .call((e) => e.selectAll('path, g.tick:first-of-type').remove());

    this.shiftContainers = this.__obj_d3.append('g')
      .attr('transform', `translate(0, ${this.__margin.top + this.__zoomBrushHeight})`)
      .attr('clip-path', "url(#taskClip)");

    this.shiftLimiters = this.shiftContainers.selectAll('.delimiter')
      .data(this.shifts)
      .enter().append('g')
      .attr('class', "delimiter")
      .attr('transform', (d) => {
        d.x = this.xScale(d.min);
        return `translate(${d.x}, 0)`;
      });

    this.shiftLimiters.append('rect')
      .attr('width', (d) => {
        d.wide = this.xScale(d.max) - d.x;
        return d.wide;
      })
      .attr('height', this.__contentHeight)

    this.shiftLimiters.append('line')
      .attr('class', 'end-stroke')
      .attr('x1', (d) => d.wide)
      .attr('x2', (d) => d.wide)
      .attr('y2', this.__contentHeight)

    this.shiftLimiters.append('line')
      .attr('y2', this.__contentHeight)

    this.__taskContainer = this.__obj_d3.append('g')
      .attr('transform', `translate(0, ${this.__margin.top + this.__zoomBrushHeight})`)
      .attr('class', "task-container")
      .attr('clip-path', "url(#taskClip)");

    if((xScalOffsetX - (this.__obj_machione_container.scrollHeight - this.__obj_machione_container.clientHeight)) < 480 )
      this.__bottomAxis = this.__obj_d3.append("g")
        .attr('class', "axis-bottom")
        .attr('transform', 'translate(0, ' + (xScalOffsetX - (this.__obj_machione_container.scrollHeight - this.__obj_machione_container.clientHeight)) + ')');
    else 
      this.__bottomAxis = this.__obj_d3.append("g")
        .attr('class', "axis-bottom")
        .attr('transform', 'translate(0, ' + 480 + ')');

    this.__bottomAxis.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '101%')
      .attr('height', this.bottomScaleHeight + 1)
      .attr('fill', 'white');

    this.__bottomAxis.call(this.__xScaleAxis);
    //this.__bottomAxis.selectAll('.domain, line').remove(); descomentar para hacer aparcer líneas de dominio

    this.__definitions = this.__taskContainer.append('defs')
      .html(`
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style="stop-color:rgb(255,255,255,0.15);stop-opacity:1" />
                    <stop offset="100%" style="stop-color:rgb(255,255,255,0);stop-opacity:1" />
                </linearGradient>`);

    let showAllButtonWidth = 110,
      showAllButtonHeight = 35;
    this.__obj_d3.append('g')
      .attr('class', "show-all-button")
      .attr('transform', `translate(${this.__realWidth - showAllButtonWidth},${this.__margin.top + this.__zoomBrushHeight})`)
      .html(` <rect x="0" y="0" width="${showAllButtonWidth}" height="${showAllButtonHeight}" opacity="0" ></rect>
                    <image x="5" y="${showAllButtonHeight / 2 - 10}" width="20" height="20" xlink:href="assets/img/lens.svg" class="amcharts-zoom-out-image"></image>
                    <text x="30" y="${showAllButtonHeight / 2 + 4}" fill="#000000" font-family="Verdana" font-size="11px" opacity="1" text-anchor="center" class="amcharts-zoom-out-label">` + translate.instant('mostrarTodos') + `</text>`)
      .on('click', () => this.__zoomBrush.call(this.__zoomBrushController.move, [this.xScaleRef(this.startDate), this.xScaleRef(this.endDate)]));

    selectRect = self.__obj_d3.append("rect").attr("class", "zoom-rect").attr('display', 'none');

    this.__zoomBrushSidesHandlers = this.__zoomBrush.selectAll('.handle--custom')
      .data([{ type: "w" }, { type: "e" }])
      .enter()
      .append('image')
      .attr('class', (d) => `handle--custom handle--${d.id}`)
      .attr("width", '25px')
      .attr("height", '25px')
      .attr("x", (d) => this.xScaleRef(d.type == 'w' && this.__cachedDomain[0] || this.__cachedDomain[1]) - 15)
      .attr("y", '-3px')
      .attr("xlink:href", 'assets/img/dragIconRoundBig.svg');

    this.__zoomBrush
      .call(this.__zoomBrushController);

    this.__zoomBrush.call(this.__zoomBrushController.move, this.xScale.range()); //we set the brush with the default domain

    this.__zoomBrushTicks = this.__zoomBrush.append('g')
      .attr('class', "x-top-axis")
      .attr('clip-path', "url(#taskClip)")
      .append('g')
      .attr('transform', `translate(0,${this.__zoomBrushHeight + 3})`)
      .call(d3.axisTop(this.xScaleRef)
        .ticks(Math.floor(this.__realWidth / 70))
        .tickFormat((d) => {
          var dayNames = [translate.instant('abrDomingo'), translate.instant('abrLunes'), translate.instant('abrMartes'), translate.instant('abrMiercoles'), translate.instant('abrJueves'), translate.instant('abrViernes'), translate.instant('abrSabado')];
          let checkDate = new Date(d.getTime());
          checkDate.setHours(0); checkDate.setMinutes(0); checkDate.setSeconds(0);
          return checkDate.getTime() == d.getTime() && dayNames[d.getDay()] + " " + d.getDate() || (d.getHours() < 10 ? '0' : '') + d.getHours() + ":" + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
        })
      ).call((d) => this.tickSizeFix(d));

    this.__zoomBrushTicks.selectAll('.domain, line').remove();

    this.__zoomBrushTicks.selectAll("g.tick text")
      .attr("style", (d) => {
        let checkDate = new Date(d.getTime());
        checkDate.setHours(0); checkDate.setMinutes(0); checkDate.setSeconds(0);
        return checkDate.getTime() == d.getTime() && 'font-weight: bold!important;' || null;
      });

    this.__zoomBrush.moveToFront(); // <=
    this.__zoomBrushSidesHandlers.moveToFront(); // <=

    this.__obj_d3.append('clipPath')
      .attr('id', "taskClip")
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.__realWidth)
      .attr('height', this.__contentHeight);

    //Base loaded, load tasks
    this.__loadDataTasks()
  }
  setDateRange(startDate, endDate) {
    this.startDate = startDate;
    this.endDate = endDate;
    let oldDom = this.xScale.domain();
    oldDom[0] = (startDate < oldDom[0]) ? oldDom[0] : startDate;
    oldDom[1] = (endDate > oldDom[1]) ? oldDom[1] : endDate;
    this.xScaleRef.domain([startDate, endDate]);
    this.__updateTopDomain();
    this.__zoomBrush.call(this.__zoomBrushController.move, [this.xScaleRef(oldDom[0]), this.xScaleRef(oldDom[1])]);
    this.setDomain(oldDom);

  }
  __updateTopDomain() {
    this.__zoomBrushTicks
      .call(d3.axisTop(this.xScaleRef)
        .ticks(Math.floor(this.__realWidth / 70))
      ).call((d) => this.tickSizeFix(d));

    this.__zoomBrushTicks.selectAll('.domain, line').remove();
  }
  setDomain(domain) {
    this.__cachedDomain = domain;
    this.xScale.domain(domain);
    this.xGridScale.domain(domain);

    this.__zoomBrushSidesHandlers.attr("x", (d) => this.xScaleRef(d.type == 'w' && this.__cachedDomain[0] || this.__cachedDomain[1]) - 15)

    //Checking x grid ticks to be updated or not
    let targetTickIntervals = this.calcXTickInterval();
    if (!this.__xTickIntervals || this.__xTickIntervals[0] != targetTickIntervals[0]) {
      this.__xTickIntervals = targetTickIntervals;
      this.__xScaleAxis.ticks(this.getXTicks(1));
      this.__xGridAxis.ticks(this.getXTicks(0));
    }
    this.__bottomAxis.call(this.__xScaleAxis)
    this.__xGridContainer.call(this.__xGridAxis)
    this.__updateTasks();
  }
  calcXTickInterval() {
    let localDomain = this.__cachedDomain,
      timerXpixelGroup = (localDomain[1] - localDomain[0]) / this.__realWidth; //group of 10 pixels
    if (timerXpixelGroup < 4000) {
      return ['2.5m', '5m'];
    } else if (timerXpixelGroup < 9000) {
      return ['5m', '10m'];
    } else if (timerXpixelGroup < 35192) {
      return ['15m', '30m'];
    } else if (timerXpixelGroup < 61314) {
      return ['30m', '1h'];
    } else if (timerXpixelGroup < 132061) {
      return ['1h', '2h'];
    } else if (timerXpixelGroup < 207524) {
      return ['2h', '4h'];
    } else if (timerXpixelGroup < 731053) {
      return ['4h', '8h'];
    } else if (timerXpixelGroup < 339586) {
      return ['8h', '12h'];
    } else if (timerXpixelGroup < 1419658) {
      return ['12h', '1d'];
    } else {
      return ['1d', '2d'];
    }// no soporta más de 2 meses, pero se puede adaptar
  }
  __d3Times = {
    '2.5m': d3.timeMinute.every(2.5),
    '5m': d3.timeMinute.every(5),
    '10m': d3.timeMinute.every(10),
    '15m': d3.timeMinute.every(15),
    '30m': d3.timeMinute.every(30),
    '1h': d3.timeHour.every(1),
    '2h': d3.timeHour.every(2),
    '4h': d3.timeHour.every(4),
    '8h': d3.timeHour.every(8),
    '1d': d3.timeDay.every(1),
    '2d': d3.timeDay.every(2)
  }
  getXTicks(type) {
    if (!this.__xTickIntervals) this.__xTickIntervals = this.calcXTickInterval();
    return this.__d3Times[this.__xTickIntervals[type]];
  }
  tickSizeFix(d, checkStart = false) {
    let self = this;
    d.selectAll('g').each(function () {
      let x = parseFloat(this.getAttribute('transform').replace('translate(', '').replace(')', '').split(',')[0]);
      if ((checkStart && x < 0) || self.__realWidth < x + this.getBBox().width) this.remove();
    });
  }
  on(event, callback) {
    this.__eventListeners[event].push(callback);
    return this;
  }
  __callEvent(event, ...args) {
    //this.__eventListeners[event].forEach((func) => func.apply(this, args));
    if (this.__eventListeners[event]) this.__eventListeners[event].forEach((func) => func.apply(this, args));
  }
  scrollTop = 0;
  async onWheel(e) {
    if (this.tooltip) this.tooltip.hide();
    e.preventDefault();
    this.__obj_machione_container.scrollTop += e.deltaY;
    this.scrollTop = this.__obj_machione_container.scrollTop;
    let xScalOffsetX = this.__zoomBrushHeight + this.__contentHeight;
    this.__taskContainer.attr('transform', 'translate(0, ' + (this.__zoomBrushHeight - this.scrollTop) + ')');
    this.__yGridContainer.attr('transform', 'translate(0, ' + (this.__zoomBrushHeight - this.scrollTop) + ')');
    if ((xScalOffsetX - (this.__obj_machione_container.scrollHeight - this.__obj_machione_container.clientHeight)) < 480 )
      this.__bottomAxis.attr('transform', 'translate(0, ' + (xScalOffsetX - (this.__obj_machione_container.scrollHeight - this.__obj_machione_container.clientHeight)) + ')');
    else
      this.__bottomAxis.attr('transform', 'translate(0, ' + 480 + ')');
  }
  __imageFiltersCache = { length: 0 };
  __getFillFilter(d) {
    if (d.filter) {
      if (!this.__imageFiltersCache[d.filter.src + (d.filter.animated && 'animated' || '')]) {
        let uid = 'task-filter-' + this.__imageFiltersCache.length;
        this.__imageFiltersCache.length++;
        this.__imageFiltersCache[d.filter.src] = {
          uid: '#' + uid,
          element: this.__definitions.append('pattern')
            .attr('id', uid)
            .attr('x', 0)
            .attr('y', 0)
            .attr('class', d.filter.animated && 'filter-animated' || undefined)
            .attr('width', this.__taskHeight + 'px')
            .attr('height', this.__taskHeight + 'px')
            .attr('patternUnits', 'userSpaceOnUse')
            .html(`<image xlink:href="${d.filter.src}" x="0" y="0" height="${this.__taskHeight}"></image>`)
        };
      }
      return this.__imageFiltersCache[d.filter.src].uid;
    }
    return '#gradient';
  }
  updateTasks(newData, referenciaof) {
    this.data.tasks = clone(newData);
    this.__displayTasks.remove();
    this.__loadDataTasks(referenciaof);
  }
  updateTask(taskId, newData) {
    for (var taskKey in this.data.tasks) if (taskId == this.data.tasks[taskKey].id) this.data.tasks[taskKey] = clone(newData);
    this.__displayTasks.remove();
    this.__loadDataTasks();
  }
  // CREAR TASKS
  async __loadDataTasks(referenciaof) {
    for (var k in this.data.tasks) {
      this.data.tasks[k].startDate = new Date(this.data.tasks[k].startDate);
      this.data.tasks[k].endDate = new Date(this.data.tasks[k].endDate);
    }

    var desvio_Y = 0;
    var clickado_of = '';

    if (this.marcadores) desvio_Y = desvioMarcador;

    this.__displayTasks = this.__taskContainer.selectAll('rect')
      .data(this.data.tasks)
      .enter().append('g')
      .attr('transform', (d) => {
        d.y = this.yScale(d.machine) + this.tasksPadding;
        return this.__getTaskTransform(d);
      })
      .each(function (d) {
        d.element = this;
      });
    let self = this;

    /* MARCADOR (triangulo superior) */
    this.__displayTasksRects = this.__displayTasks.append('svg')
      .attr('y', - 6 + desvioMarcador)
      .attr('height', (d) => { if (this.marcadores) { return 9 } else { return 0 } })
      if (this.marcadores) this.__displayTasksRects.html((d) => '<path fill="' + d.backgroundColor + '" d="M0 6.69547V1.3024C0 0.583104 0.62444 0 1.39472 0H4.60327C5.66673 0 6.33894 1.06681 5.82248 1.9349L2.61393 7.32797C1.91501 8.50276 0 8.03937 0 6.69547Z"/>');
    /* TASK CON COLOR */
    this.__displayTasksRects = this.__displayTasks.append('rect')
      .attr('fill', (d) => d.backgroundColor)
      .attr('y', desvio_Y)
      .attr('class', 'fondo-task')
      .attr('rx', 5)
      .attr('ry', 5)
      .attr('width', function (d) { return self.__getTaskWide(d, this.parentElement); })
      .attr('height', this.__taskHeight);
    /* Color TOP */
    this.__displayTasksRects = this.__displayTasks.append('rect')
      .attr('fill', (d) => d.backgroundColor)
      .attr('class', 'parpadeo')
      .attr('y', desvio_Y + 1)
      .attr('x', 1.5)
      .attr('rx', 5)
      .attr('ry', 5)
      .attr('width', function (d) { return self.__getTaskWide(d, this.parentElement) - 3.5; })
      .attr('height', 1);
    /* Color BOT */
    this.__displayTasksRects = this.__displayTasks.append('rect')
      .attr('fill', (d) => { return '#F44E42'; })
      .attr('class', 'parpadeo')
      .attr('y', this.__taskHeight + 2)
      // .attr('y', desvio_Y + this.__taskHeight + 2)
      .attr('rx', 2)
      .attr('ry', 2)
      .attr('width', function (d) { return self.__getTaskWide(d, this.parentElement) })
      .attr('height', (d) => { if (d.fueraPlazo == true) { return 4 } else { return 0 } });

    let automaticScrollMargin = this.__realWidth * .1,
      [tooltip, tooltipText, tooltipArrow] = (() => {
        let arrow = document.createElement('div');
        arrow.classList.add('arrow');
        let p = document.createElement('p');
        let ttip = document.createElement('div');
        ttip.classList.add('tooltip-gantt');
        ttip.style.display = 'none';
        ttip.style.position = 'fixed';
        ttip.append(p);
        ttip.append(arrow);
        document.body.append(ttip);
        return [ttip, p, arrow];
      })();
    let mousePressed, draggingElements;
    this.__dragInterval;
    /* TASK CON IMAGEN */
    this.__displayTasksRectsGradient = this.__displayTasks.append('rect')
      .attr('fill', (d) => `url(${this.__getFillFilter(d)})`)
      //.attr('fill', (d) => d.backgroundColor)
      .attr('x', (d) => ((d.wide > .3 && .4) || 0))
      .attr('y', desvio_Y + .2)
      .attr('rx', (d) => 5)
      .attr('ry', (d) => 5)
      .attr('width', (d) => ((d.wide > .5 && d.wide - .4) || d.wide))
      .attr('height', this.__taskHeight - .4)
      .on('click', (d) => {
        // if (d3.event.ctrlKey) {
        //   this.__displayTasksRectsGradient.each(function (taskD) {
        //     this.parentElement.classList.remove('fadeout');
        //     d3.select(this.parentElement).moveToFront();
        //   })
        //   this.__callEvent('task-ctrl-clicked', d.id);
        // }
      })
      .on('mouseenter, mousemove', (d) => {
        /* resaltar tasks */
        this.__displayTasksRectsGradient.each(function (taskD) {
          if (clickado_of == taskD.of) this.parentElement.classList.remove('fadeout'); // OF clicada
          else if (d.of == undefined && clickado_of == '') this.parentElement.classList.remove('fadeout');
          else if (d.of == taskD.of) this.parentElement.classList.remove('fadeout'); // OF raton
          else if (self.OFfiltradas.filter(element => element == taskD.of).length > 0) this.parentElement.classList.remove('fadeout'); // OF filtrada
          else this.parentElement.classList.add('fadeout'); // si no es niguna de la anteriores se pone en segundo plano
          d3.select(this.parentElement).moveToFront();
        })


        tooltipText.innerHTML = d.detail(d);
        tooltip.style.borderColor = d.backgroundColor;
        tooltipArrow.style.borderTopColor = d.backgroundColor;
        tooltip.style.display = '';

        let offsetTop = (d.y + this.getChartSVGOffset().top + self.__zoomBrushHeight - tooltip.clientHeight - 20 - this.scrollTop);
        if (offsetTop < 0) {
          tooltip.style.top = (d.y + this.getChartSVGOffset().top + self.__zoomBrushHeight + self.__taskHeight + 20 - this.scrollTop) + 'px';
          if (!tooltip.classList.contains('bottom-reverse')) tooltip.classList.add('bottom-reverse');
        } else {
          tooltip.style.top = offsetTop + 'px';
          if (tooltip.classList.contains('bottom-reverse')) tooltip.classList.remove('bottom-reverse');
        }
        tooltip.style.left = (this.getSVGPosFromScreenPos(d3.event.pageX) - (tooltip.clientWidth / 2)) + 'px';
      })
      .on('mouseleave', () => {

        /* resaltar tasks */
        this.__displayTasksRectsGradient.each(function (taskD) {
          if (clickado_of == taskD.of) this.parentElement.classList.remove('fadeout');
          else if (self.OFfiltradas.filter(element => element == taskD.of).length > 0) this.parentElement.classList.remove('fadeout');
          else if (clickado_of == '' && self.OFfiltradas.length == 0) this.parentElement.classList.remove('fadeout');
          else this.parentElement.classList.add('fadeout');
          d3.select(this.parentElement).moveToFront();
        })

        tooltip.style.display = 'none'

      })
      .call(d3.drag()
        .on("start", function (d) {
          draggingElements = ('linking' in d) ? self.data.tasks.filter(t => t.linking == d.linking) : [d];
          draggingElements.forEach(dl => {
            dl.startY = dl.y;
            dl.startX = dl.x;
            dl.startMachine = dl.machine;
            dl.dragStartOffsetX = d3.event.x;
            dl.dragging = true;
            d3.select(dl.element).moveToFront();
          });

          mousePressed = true;
          setTimeout(() => {
            if (mousePressed) {
              mousePressed = false;
              if (tooltip.style.display != 'none') tooltip.style.display = 'none';
            } else {
              /* se desmarcan como draging */
              draggingElements.forEach(dl => {
                dl.y = dl.startY;
                dl.x = dl.startX;
                dl.machine = dl.startMachine;
                dl.dragStartOffsetX = undefined;
                dl.dragging = undefined;
                d3.select(dl.element).moveToFront();
              });
              /* se vacian los draging */
              draggingElements = [];

              if (clickado_of != d.of) {
                clickado_of = d.of;
                self.__displayTasksRectsGradient.each(function (taskD) {
                  this.parentElement.classList.remove('fadeout');
                  d3.select(this.parentElement).moveToFront();
                })
              } else {
                clickado_of = '';
              }

              self.__displayTasksRectsGradient.each(function (taskD) {
                if (taskD.of == d.of) d3.select(this.parentElement).moveToFront();

                if (clickado_of == taskD.of) this.parentElement.classList.remove('fadeout'); // OF clicada
                else if (d.of == undefined && clickado_of == '') this.parentElement.classList.remove('fadeout');
                else if (d.of == taskD.of) this.parentElement.classList.remove('fadeout'); // OF raton
                else if (self.OFfiltradas.filter(element => element == taskD.of).length > 0) this.parentElement.classList.remove('fadeout'); // OF filtrada
                else this.parentElement.classList.add('fadeout'); // si no es niguna de la anteriores se pone en segundo plano
              })

              // Efecto de click definido desde la llamada al GANTT
              self.__callEvent('task-ctrl-clicked', d.id);
            }
          }, 150);
        })
        .on("drag", function (d) {
          let offset = self.getChartSVGOffset();
          this.dragIni = Date.now();
          let bandHeight = self.__cachedYScaleRange[1] - self.__cachedYScaleRange[0];
          let targetMachine = self.data.machines[getClosestKey(
            d3.event.sourceEvent.y - (offset.top + self.__zoomBrushHeight - self.scrollTop + bandHeight / 2 /* bandwidth */),
            self.__cachedYScaleRange
          )].id
          let sortedElements = draggingElements.sort((a, b) => a.startDate - b.startDate);
          let firstElement = sortedElements[0];
          let lastElement = sortedElements[sortedElements.length - 1];
          let targetX;
          (() => {
            let dl = firstElement;
            targetX = dl.x + d3.event.x - dl.dragStartOffsetX;
            if (self.xScale.invert(targetX) < self.startDate) dl.x = self.xScale(self.startDate);
            else if (self.xScale.invert(targetX + d.wide) > self.endDate) dl.x = self.xScale(self.endDate) - d.wide;
            else dl.x = targetX;
            targetX = dl.x;
          })();
          draggingElements.forEach(dl => {
            if (dl == firstElement) return;
            let distance = self.xScale(dl.startDate) - self.xScale(firstElement.startDate);
            dl.x = targetX + distance;
          });
          if (d.machine != targetMachine) {
            d.machine = targetMachine;
            if (d.allowedMachines.includes(d.machine)) {
              if (d.element.classList.contains('not-allowed'))
                draggingElements.forEach(dl => dl.element.classList.remove('not-allowed'));
            } else {
              if (!d.element.classList.contains('not-allowed'))
                draggingElements.forEach(dl => dl.element.classList.add('not-allowed'));
            }
            d.y = self.yScale(d.machine) + self.tasksPadding;
            draggingElements.forEach(dl => dl.y = d.y);
          }
          let offsets = self.__obj.getClientRects()[0];
          let leftSideDistance = distance(offsets.x, d3.event.sourceEvent.clientX),
            rightSideDistance = distance(offsets.x + offsets.width, d3.event.sourceEvent.clientX);


          clearInterval(self.__dragInterval);
          if (leftSideDistance < automaticScrollMargin) { // move to left
            self.__dragIntervalFunc = () => {
              if (self.__cachedDomain[0] > self.startDate) {
                let domainTimes = [self.__cachedDomain[0].getTime(), self.__cachedDomain[1].getTime()],
                  timeDistFromStart = domainTimes[0] - self.startDate.getTime(),
                  moveDistance = Math.min(timeDistFromStart, (domainTimes[1] - domainTimes[0]) * .005);
                d3.brushEventException = true;
                self.__zoomBrush.call(self.__zoomBrushController.move, [self.xScaleRef(new Date(domainTimes[0] - moveDistance)), self.xScaleRef(new Date(domainTimes[1] - moveDistance))]);
                d3.brushEventException = false;
              }
            };
            self.__dragInterval = setInterval(() => self.__dragIntervalFunc(), 10);
            self.__dragIntervalFunc();
          } else if (rightSideDistance < automaticScrollMargin) { // move to right
            self.__dragIntervalFunc = () => {
              if (self.__cachedDomain[1] < self.endDate) {
                let domainTimes = [self.__cachedDomain[0].getTime(), self.__cachedDomain[1].getTime()],
                  timeDistFromStart = self.endDate.getTime() - domainTimes[1],
                  moveDistance = Math.min(timeDistFromStart, (domainTimes[1] - domainTimes[0]) * .005);
                d3.brushEventException = true;
                self.__zoomBrush.call(self.__zoomBrushController.move, [self.xScaleRef(new Date(domainTimes[0] + moveDistance)), self.xScaleRef(new Date(domainTimes[1] + moveDistance))]);
                d3.brushEventException = false;
              };
            };
            self.__dragInterval = setInterval(() => self.__dragIntervalFunc(), 10);
            self.__dragIntervalFunc();
          }
          //this.parentElement.setAttribute('transform', self.__getTranslation(d));
          draggingElements.forEach(dl => dl.element.setAttribute('transform', self.__getTranslation(dl)));
        })
        .on('end', function (d) {
          if (!mousePressed) {
            clearInterval(self.__dragInterval);

            delete d.dragging;
            delete d.dragStartOffsetX;
            if (this.parentElement.classList.contains('not-allowed')) this.parentElement.classList.remove('not-allowed');
            let taskSize = d.endDate.getTime() - d.startDate.getTime();
            d.startDate = self.xScale.invert(d.x);
            d.endDate = new Date(d.startDate.getTime() + taskSize);

            /*ESTO ESTA EN EL CODIGO DE MARKEL PERO NO ES NECESARIO START */
            //draggingElements.forEach(dl => {
            //  delete dl.dragging;
            //  delete dl.dragStartOffsetX;
            //  if (dl.element.classList.contains('not-allowed')) dl.element.classList.remove('not-allowed');
            //  if (!dl.allowedMachines.includes(dl.machine)) {
            //    dl.x = dl.startX;
            //    dl.y = dl.startY;
            //    dl.machine = dl.startMachine;
            //    delete dl.startX;
            //    delete dl.startY;
            //    dl.element.setAttribute('transform', self.__getTranslation(d));
            //  } else {
            //    self.__callEvent('task-dropped', dl.id, dl.startDate, dl.machine != dl.startMachine && dl.machine || false);
            //  }
            //  let taskSize = dl.endDate.getTime() - dl.startDate.getTime();
            //  dl.startDate = self.xScale.invert(dl.x);
            //  dl.endDate = new Date(dl.startDate.getTime() + taskSize);
            //});
            /*ESTO ESTA EN EL CODIGO DE MARKEL PERO NO ES NECESARIO END */

            tooltip.style.display = 'none'; //si no oculto el tooltip se queda permanente  

            if (!d.allowedMachines.includes(d.machine)) {
              d.x = d.startX;
              d.y = d.startY;
              d.machine = d.startMachine;
              delete d.startX;
              delete d.startY;
              this.parentElement.setAttribute('transform', self.__getTranslation(d));
            } else {
              var dif = Date.now() - this.dragIni;
              if (!Number.isNaN(dif) && dif > 15) {
                this.dragIni = 1 / 0; // a veces no actualiza el drag la fecha. y para esos casos hago que salga directamente
                self.__callEvent('task-dropped', d.id, d.startDate, d.machine/* != d.startMachine && d.machine || false *//* aunque sea la misma maquina puede cambiar de fecha y me viene bien que me de el ID */);
              } else {
                this.dragIni = 1 / 0;// a veces no actualiza el drag la fecha. y para esos casos hago que salga directamente
                //si el peridodo es tan corto lo cuento como click, y deshago lo hecho          
                d.x = d.startX;
                d.y = d.startY;
                d.machine = d.startMachine;
                delete d.startX;
                delete d.startY;
                self.__callEvent('task-ctrl-clicked', d.id);
                this.parentElement.setAttribute('transform', self.__getTranslation(d));
              }
            }
          }
          mousePressed = false;
        }));

    this.__updateTasks();
    let filterAnimated = d3.selectAll('pattern.filter-animated'),
      x = 0;
    setInterval(() => {
      x += .5;
      filterAnimated.each(function () {
        this.setAttribute('x', x);
      });
    }, 50)
  }
  getChartSVGOffset() {
    return this.__obj.getBoundingClientRect();
  }
  getSVGPosFromScreenPos(x, y) {
    let svgBCR = this.getChartSVGOffset();
    return !isNaN(x) ? (
      !isNaN(y) ? {
        top: x - svgBCR.top,
        left: y - svgBCR.left
      } : x
    ) : !isNaN(y) ? y : false;
  }
  __getTaskTransform(d) {
    d.x = this.xScale(d.startDate);
    d.isVisible = this.__isVisible(d);
    return this.__getTranslation(d);
  }
  __getTranslation = (d) => 'translate(' + d.x + ', ' + d.y + ')';
  __getTaskWide(d) {
    if (d.isVisible) {
      d.wide = this.xScale(d.endDate) - d.x;
    } else {
      d.wide = 0;
    }
    return d.wide;
  }
  __isVisible(d) {
    return (d.startDate.getTime() < this.__cachedDomain[1].getTime() && d.endDate.getTime() > this.__cachedDomain[0].getTime());
  }

  /* MOSTRAR TEXTOS */
  __showTaskOf(taskElement, d, desvio_Y) {
    d.isOfVisible = true;

    var ofLenght = d.ofToShow.length * 7;
    if (d.ofToShow.includes('...')) ofLenght -= 10;

    d3.select(taskElement).append('rect')
      .attr('fill', (d) => d.backgroundColor)
      .attr('class', 'pointer-none')
      .attr('rx', 2)
      .attr('ry', 2)
      .attr('x', 5)
      .attr('y', desvio_Y + 4)
      .attr('width', ofLenght)
      .attr('height', 13)
    d3.select(taskElement).append('text')
      .attr('x', 9)
      .attr('y', desvio_Y + 14)
      .attr('class', 'refof')
      .text((d) => d.ofToShow)
  }
  __showTaskOperacion(taskElement, d, desvio_Y) {
    d.isOperacionVisible = true;

    var ofLenght = d.ofToShow.length * 7;
    if (d.ofToShow.includes('...')) ofLenght -= 14;

    d3.select(taskElement).append('text')
      .attr('x', ofLenght + 13)
      .attr('y', desvio_Y + 15)
      .text((d) => d.operacionToShow)
  }
  __showTaskTextoInferior(taskElement, d, desvio_Y) {
    d.isTextoInferiorVisible = true;

    d3.select(taskElement).append('text')
      .attr('x', 5)
      .attr('y', desvio_Y + 27)
      .attr('class', 'pieza')
      .text((d) => d.textoInferiorToShow);
  }
  /* OCULTAR TEXTOS */
  __hideTaskOf(taskElement, d) {
    delete d.isOfVisible;
    taskElement.children[taskElement.children.length - 1].remove()
    taskElement.children[taskElement.children.length - 1].remove()
  }
  __hideTaskOperacion(taskElement, d) {
    delete d.isOperacionVisible;
    taskElement.children[taskElement.children.length - 1].remove()
  }
  __hideTaskTextoInferior(taskElement, d) {
    delete d.isTextoInferiorVisible;
    taskElement.children[taskElement.children.length - 1].remove()
  }
  async __updateTasks() {
    let self = this;
    //check if scale was modifyed or not (to cahnge only the transforms)
    this.__displayTasks
      .each(function (d) {
        if (!d.dragging) {
          this.setAttribute('transform', self.__getTaskTransform(d));

          this.children[0].setAttribute('width', self.__getTaskWide(d));
          this.children[1].setAttribute('width', self.__getTaskWide(d));
          this.children[2].setAttribute('width', self.__getTaskWide(d) - 3.5);
          this.children[3].setAttribute('width', self.__getTaskWide(d));
          this.children[4].setAttribute('width', self.__getTaskWide(d));

          // DESVIO MARCADOR
          var desvio_Y = 0;
          if (self.marcadores) desvio_Y = desvioMarcador;

          // cantidad de letras que entran en el box
          var letraCant = Math.floor(d.wide / 7) - 3;
          if (letraCant < 0) letraCant = 0;
          d.ofToShow = "";
          d.operacionToShow = "";
          d.textoInferiorToShow = "";

          /*  SE AÑADEN DE PRIMERO A ULTIMO, SE ELIMINAN DE ULTIMO A PRIMERO */
          if (d.isTextoInferiorVisible) self.__hideTaskTextoInferior(this, d);
          if (d.isOperacionVisible) self.__hideTaskOperacion(this, d);
          if (d.isOfVisible) self.__hideTaskOf(this, d);

          // si no entra toda la descripcion en el box entonces hay que cortarlo

          var letrasMinimas = 2;

          /* OF */
          if (letraCant >= letrasMinimas) {
            if (letraCant < d.refOf.length) {
              d.ofToShow = d.refOf.slice(0, letraCant) + '...';
              self.__showTaskOf(this, d, desvio_Y);
            } else {
              d.ofToShow = d.refOf;
              self.__showTaskOf(this, d, desvio_Y);
            }
          }

          /* OPERACION */
          if (letraCant - d.ofToShow.length >= letrasMinimas) {
            if (letraCant - d.ofToShow.length + 2 < d.operacion.length) {
              d.operacionToShow = d.operacion.slice(0, letraCant - d.ofToShow.length) + '...';
              self.__showTaskOperacion(this, d, desvio_Y);
            } else {
              d.operacionToShow = d.operacion;
              self.__showTaskOperacion(this, d, desvio_Y);
            }
          }

          /* TEXTO INFERIOR */
          if (letraCant >= letrasMinimas) {
            var letraCantTextoInferior = letraCant + 2
            if (letraCantTextoInferior < d.textoInferior.length) {
              d.textoInferiorToShow = d.textoInferior.slice(0, letraCantTextoInferior) + '...';
              self.__showTaskTextoInferior(this, d, desvio_Y);
            } else {
              d.textoInferiorToShow = d.textoInferior;
              self.__showTaskTextoInferior(this, d, desvio_Y);
            }
          }

        }
      });


    this.shiftLimiters
      .attr('transform', (d) => {
        d.x = this.xScale(d.min);
        return `translate(${d.x}, 0)`;
      });

    this.shiftLimiters.selectAll('rect')
      .attr('width', (d) => {
        d.wide = this.xScale(d.max) - d.x;
        return d.wide;
      });

    this.shiftLimiters.selectAll('line.end-stroke')
      .attr('x1', (d) => d.wide)
      .attr('x2', (d) => d.wide);
  }

  //ARATZ: para poder resaltar un appointment desde un filtro externo  
  resaltarOF(OFfiltradas) {
    this.OFfiltradas = OFfiltradas;
    let self = this;
    this.__displayTasksRectsGradient.each(function (taskD) {
      if (self.OFfiltradas.length == 0) this.parentElement.classList.remove('fadeout');
      else if (self.OFfiltradas.filter(element => element == taskD.of).length > 0) this.parentElement.classList.remove('fadeout');
      else this.parentElement.classList.add('fadeout');

      d3.select(this.parentElement).moveToFront();
    })
  }
}
//#endregion

//#region GanttChartHoverLine
class GanttChartHoverLine {
  width = 'fit';
  height = 250;
  machine = '';
  startDate = new Date('2020-1-1');
  endDate = new Date((new Date('2020-1-1')).getTime() + (24 * 60 * 60 * 1000 * 7));
  allowedMachines = [];
  shifts = [];
  tasksPadding = 8;
  enableHoverLines = false;
  addHoverLineMargin = 0; //Para añadirle un factor a la linea, por ejemplo si se le pasa 25 aparecera 25px mas a la derecha (por defecto 0)
  usuario = null;
  restarParaHoverLine = 0; //El grafico si tiene algo a la izquierda no calcula bien la linea del tooltip, entonces le pasamos la anchura de lo que tenga antes para calcularlo correctamente
  tooltipSiempreDebajo = false;//Si este valor es true el tooltip siempre sera mostrado inferiormente
  __taskElements = [];
  __zoomBrushHeight = 20;
  __cachedDomain = [];
  __eventListeners = { 'task_clicked': [], 'task_mouse': [], 'task_hide': [] };
  hoverStrokeStyle = {
    'stroke': 'black',
    'stroke-width': '2px',
    'stroke-dasharray': '5px'
  }
  __margin = {
    top: 15,
    right: 15
  }
  xScale; yScale;
  constructor(elementID, config = {}, translate, height = 0, graficos = []) {
    d3.timeFormatDefaultLocale({
      "decimal": ".",
      "thousands": ",",
      "grouping": [3],
      "currency": ["$", ""],
      "dateTime": "%a %b %e %X %Y",
      "date": "%m/%d/%Y",
      "time": "%H:%M:%S",
      "periods": ["AM", "PM"],
      "days": [translate.instant('domingo'), translate.instant('lunes'), translate.instant('martes'), translate.instant('miercoles'), translate.instant('jueves'), translate.instant('viernes'), translate.instant('sabado')],
      "shortDays": [translate.instant('abrDomingo'), translate.instant('abrLunes'), translate.instant('abrMartes'), translate.instant('abrMiercoles'), translate.instant('abrJueves'), translate.instant('abrViernes'), translate.instant('abrSabado')],
      "months": [translate.instant('enero'), translate.instant('febrero'), translate.instant('marzo'), translate.instant('abril'), translate.instant('mayo'), translate.instant('junio'), translate.instant('julio'), translate.instant('agosto'), translate.instant('septiembre'), translate.instant('octubre'), translate.instant('noviembre'), translate.instant('diciembre')],
      "shortMonths": [translate.instant('abrEnero'), translate.instant('abrFebrero'), translate.instant('abrMarzo'), translate.instant('abrAbril'), translate.instant('abrMayo'), translate.instant('abrJunio'), translate.instant('abrJulio'), translate.instant('abrAgosto'), translate.instant('abrSeptiembre'), translate.instant('abrOctubre'), translate.instant('abrNoviembre'), translate.instant('abrDiciembre')]
    });

    if (height != 0) {
      this.height = height;
      this.tasksPadding = 4;
    }
    this.isZooming = false;

    if (!elementID) return false;
    for (var k in config) {
      if (k == 'data') this.data = clone(config[k]);
      else this[k] = config[k];
    }
    this.__obj_container = document.getElementById(elementID);
    this.__obj_container.innerHTML = "";
    this.__obj_container.style.display = 'flex';

    if (this.width == 'fit') this.width = this.__obj_container.clientWidth;
    this.__realWidth = this.width - (this.__margin.right);
    this.__realHeight = this.height - (this.__margin.top)

    this.__contentHeight = this.__realHeight - (this.__zoomBrushHeight + 20/*bottom axis height*/)

    this.__taskHeight = (this.__contentHeight / this.data.machines.length);

    this.__obj_machione_container = document.createElement('div');
    this.__obj_machione_container.classList.add('machines-container');
    let machinesHTML = '';
    this.data.machines.forEach(value => {
      machinesHTML += `<div class="machine" style="height: ${this.__taskHeight}px;"><div></div><div><span>${value.name}</span></div></div>`;
    });
    this.__obj_machione_container.innerHTML = machinesHTML;
    this.__obj_machione_container.style.marginTop = (this.__margin.top + this.__zoomBrushHeight) + 'px';
    this.__obj_container.append(this.__obj_machione_container);
    this.__realWidth -= this.__obj_machione_container.clientWidth;

    this.__obj = document.createElementNS("http://www.w3.org/2000/svg", "svg")
    this.__obj_container.append(this.__obj);
    this.__obj_d3 = d3.select(this.__obj);
    let self = this;
    let selectRect;
    let hoveringContent = false;
    this.__obj_d3
      .attr('width', this.__realWidth + 'px')
      .attr('height', this.height + 'px')
      .attr('class', "gantt-chart")
      .on("contextmenu", () => d3.event.preventDefault())

      // use Xgrid as mouse events handling over chart
      // .on('mouseenter', () => {
      //   // if ! is top rect
      //   // if (!d3.event.path.includes(this.__zoomBrushNode)) {
      //   //   hoveringContent = true;
      //   //   this.__callEvent('mouseenter', this.xScale.invert(this.getSVGPosFromScreenPos(d3.event.x) - this.__obj_machione_container.clientWidth - 70 - this.addHoverLineMargin));
      //   // } else if (hoveringContent) {
      //   //   hoveringContent = false;
      //   //   this.__callEvent('mouseleave');
      //   // }
      //   console.log("sartu da")
      // })
      // .on('mousemove', () => {
      //   // if ! is top rect
      //   if (!d3.event.path.includes(this.__zoomBrushNode)) {
      //     hoveringContent = true;
      //     this.__callEvent('mousemove', this.xScale.invert(this.getSVGPosFromScreenPos(d3.event.x) - this.__obj_machione_container.clientWidth - 70 - this.addHoverLineMargin));
      //   }
      //   else if (hoveringContent) {
      //     hoveringContent = false;
      //     this.__callEvent('mouseleave');
      //   }
      // })
      // .on('mouseleave', () => {
      //   if (hoveringContent) {
      //     this.__callEvent('mouseleave');
      //     hoveringContent = false;
      //   }
      // })
      .on('mousedown', function () {
        if (d3.event.button == 2) {
          self.isZooming = true;
          var e = this,
            origin = d3.mouse(e);
          selectRect.attr('width', 0).attr('display', '')
          origin = [origin[0], origin[1]]
          d3.select("body").classed("noselect", true);
          origin[0] = Math.max(0, Math.min(self.__realWidth, origin[0]));
          origin[1] = Math.max(0, Math.min(self.__realWidth, origin[1]));
          d3.select(window)
            .on("mousemove.zoomRect", function () {
              var m = d3.mouse(e);
              if (m[0] > self.__realWidth) m[0] = self.__realWidth;
              if (m[1] > self.__realHeight) m[1] = self.__realHeight;
              m = [m[0], m[1]]
              m[0] = Math.max(0, Math.min(self.__realWidth, m[0]));
              m[1] = Math.max(0, Math.min(self.__realWidth, m[1]));
              selectRect.attr("x", Math.min(origin[0], m[0]))
                .attr("y", self.__margin.top + self.__zoomBrushHeight)
                .attr("width", Math.abs(((m[0]) - (origin[0]))))
                .attr("height", self.__contentHeight);
            }, true)
            .on("mouseup.zoomRect", function () {
              self.isZooming = false;
              d3.event.preventDefault();
              d3.select(window).on("mousemove.zoomRect", null).on("mouseup.zoomRect", null);
              d3.select("body").classed("noselect", false);
              var m = d3.mouse(e);
              m[0] = Math.max(0, Math.min(self.__realWidth, m[0]));
              m[1] = Math.max(0, Math.min(self.__contentHeight, m[1]));
              if (m[0] !== origin[0] && m[1] !== origin[1]) {
                let newDomain;
                if (origin[0] < m[0]) {
                  newDomain = [self.xScale.invert(origin[0]), self.xScale.invert(m[0])];
                } else {
                  newDomain = [self.xScale.invert(m[0]), self.xScale.invert(origin[0])];
                }

                self.__zoomBrush.call(self.__zoomBrushController.move, [self.xScaleRef(newDomain[0]), self.xScaleRef(newDomain[1])]);
                for (var i = 0; i < graficos.length; i++) {
                  graficos[i].__zoomBrush.call(graficos[i].__zoomBrushController.move, [graficos[i].xScaleRef(newDomain[0]), graficos[i].xScaleRef(newDomain[1])]);
                }
              }
              selectRect.attr('display', 'none')
            }, true);
        }
        d3.event.stopPropagation();
      })

    this.xScale = d3.scaleTime()
      .domain([this.startDate, this.endDate])
      .range([0, this.__realWidth]);
    this.__cachedRange = this.xScale.range();
    this.__cachedDomain = this.xScale.domain();

    this.yScale = d3.scaleOrdinal()
      .domain(this.data.machines.map((d) => d.id))
      .range(this.data.machines.map((_, k) => k * this.__taskHeight))
    this.__cachedYScaleRange = this.yScale.range();

    this.__taskHeight = this.__taskHeight - (this.tasksPadding * 2); // apply padding after setting yDomain

    this.xScaleRef = d3.scaleTime()
      .domain(this.xScale.domain())
      .range([0, this.__realWidth]);

    this.xGridScale = d3.scaleTime()
      .domain(this.xScale.domain())
      .range(this.xScale.range());

    this.__zoomBrush = this.__obj_d3.append("g")
      .attr('class', "zoom-brush")
      .attr('transform', `translate(0,${this.__margin.top})`);
    this.__zoomBrushNode = this.__zoomBrush.node();

    this.__zoomBrush.append('rect')
      .attr('class', "bg")
      .attr('width', this.__realWidth)
      .attr('height', this.__zoomBrushHeight);

    this.__xScaleAxis = d3.axisBottom(this.xScale)
      .ticks(this.getXTicks(1))
      .tickFormat(d3.timeFormat("%H:%M"));

    let xScalOffsetX = this.__margin.top + this.__zoomBrushHeight + this.__contentHeight;

    this.__bottomAxis = this.__obj_d3.append("g")
      .attr('class', "axis-bottom")
      .attr('transform', `translate(0,${xScalOffsetX})`)
      .call(this.__xScaleAxis);
    this.__bottomAxis.selectAll('.domain, line').remove();

    this.__zoomBrushController = d3.brushX()
      .extent([[1, 1], [this.__realWidth - 2, this.__zoomBrushHeight - 1]])
      .on("start", function () {
        this.classList.add('grabbing');
      })
      .on("end", function () {
        this.classList.remove('grabbing');
      })
      .on("brush end", () => {
        if ((d3.event.selection && d3.event.sourceEvent) || d3.brushEventException)
          this.setDomain(d3.event.selection.map(this.xScaleRef.invert, this.xScale));
        for (var i = 0; i < graficos.length; i++) {
          graficos[i].setDomain(d3.event.selection.map(this.xScaleRef.invert, this.xScale));
        }
        //try {
        //  var a = d3.event.sourceEvent.sourceEvent.path;
        //} catch (error) {
        this.eventoMoverBarraZoom();
        //}
      });

    this.eventoMoverBarraZoom = function func() {
    }


    this.__xGridAxis = d3.axisTop()
      .scale(this.xGridScale)
      .ticks(this.getXTicks(0))
      .tickSize(this.__contentHeight, 0, 0)
      .tickFormat("");

    this.__xGridContainer = this.__obj_d3.append("g")
      .attr('class', "x grid")
      .attr('transform', `translate(0,${xScalOffsetX})`)
      .call(this.__xGridAxis);

    this.__yGridAxis = d3.axisRight()
      .scale(this.yScale)
      .tickSize(this.__realWidth, 0, 0)
      .tickFormat("");

    this.__yGridContainer = this.__obj_d3.append("g")
      .attr('class', "y grid")
      .attr('transform', `translate(0,${this.__margin.top + this.__zoomBrushHeight})`)
      .call(this.__yGridAxis)
      .call((e) => e.selectAll('path, g.tick:first-of-type').remove());

    this.shiftContainers = this.__obj_d3.append('g')
      .attr('transform', `translate(0, ${this.__margin.top + this.__zoomBrushHeight})`)
      .attr('clip-path', "url(#taskClip)");

    this.shiftLimiters = this.shiftContainers.selectAll('.delimiter')
      .data(this.shifts)
      .enter().append('g')
      .attr('class', (d) => {
        return "delimiter " + d.clase;
      })
      .attr('transform', (d) => {
        d.x = this.xScale(d.min);
        return `translate(${d.x}, 0)`;
      });

    this.shiftLimiters.append('rect')
      .attr('width', (d) => {
        d.wide = this.xScale(d.max) - d.x;
        return d.wide;
      })
      .attr('height', this.__contentHeight)

    this.shiftLimiters.append('line')
      .attr('class', 'end-stroke')
      .attr('x1', (d) => d.wide)
      .attr('x2', (d) => d.wide)
      .attr('y2', this.__contentHeight)

    this.shiftLimiters.append('line')
      .attr('y2', this.__contentHeight)

    this.__taskContainer = this.__obj_d3.append('g')
      .attr('transform', `translate(0, ${this.__margin.top + this.__zoomBrushHeight})`)
      .attr('class', "task-container")
      .attr('clip-path', "url(#taskClip)");

    this.__definitions = this.__taskContainer.append('defs')
      .html(`
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style="stop-color:rgb(255,255,255,0.15);stop-opacity:1" />
                    <stop offset="100%" style="stop-color:rgb(255,255,255,0);stop-opacity:1" />
                </linearGradient>`);

    let showAllButtonWidth = 110,
      showAllButtonHeight = 35;
    this.__obj_d3.append('g')
      .attr('class', "show-all-button")
      .attr('transform', `translate(${this.__realWidth - showAllButtonWidth},${this.__margin.top + this.__zoomBrushHeight})`)
      .html(` <rect x="0" y="0" width="${showAllButtonWidth}" height="${showAllButtonHeight}" opacity="0" ></rect>
                    <image x="5" y="${showAllButtonHeight / 2 - 10}" width="20" height="20" xlink:href="assets/img/lens.svg" class="amcharts-zoom-out-image"></image>
                    <text x="30" y="${showAllButtonHeight / 2 + 4}" fill="#000000" font-family="Verdana" font-size="11px" opacity="1" text-anchor="center" class="amcharts-zoom-out-label">` + translate.instant('mostrarTodos') + `</text>`)
      //.on('click', () => this.__zoomBrush.call(this.__zoomBrushController.move, [this.xScaleRef(this.startDate), this.xScaleRef(this.endDate)]));
      .on('click', () => this.updateZoomAllCharts(this, graficos, this.startDate, this.endDate));

    selectRect = self.__obj_d3.append("rect").attr("class", "zoom-rect").attr('display', 'none');


    this.__zoomBrushSidesHandlers = this.__zoomBrush.selectAll('.handle--custom')
      .data([{ type: "w" }, { type: "e" }])
      .enter()
      .append('image')
      .attr('class', (d) => `handle--custom handle--${d}`)
      .attr("width", '30px')
      .attr("height", '30px')
      .attr("x", (d) => this.xScaleRef(d.type == 'w' && this.__cachedDomain[0] || this.__cachedDomain[1]) - 15)
      .attr("y", '-6px')
      .attr("xlink:href", 'assets/img/dragIconRoundBig.svg');

    this.__zoomBrush
      .call(this.__zoomBrushController);

    this.__zoomBrush.call(this.__zoomBrushController.move, this.xScale.range()); //we set the brush with the default domain

    this.__zoomBrushTicks = this.__zoomBrush.append('g')
      .attr('class', "x-top-axis")
      .attr('clip-path', "url(#taskClip)")
      .append('g')
      .attr('transform', `translate(0,${this.__zoomBrushHeight + 3})`)
      .call(d3.axisTop(this.xScaleRef)
        .ticks(Math.floor(this.__realWidth / 70))
        .tickFormat((d) => {
          var dayNames = [translate.instant('abrDomingo'), translate.instant('abrLunes'), translate.instant('abrMartes'), translate.instant('abrMiercoles'), translate.instant('abrJueves'), translate.instant('abrViernes'), translate.instant('abrSabado')];
          let checkDate = new Date(d.getTime());
          checkDate.setHours(0); checkDate.setMinutes(0); checkDate.setSeconds(0);
          return checkDate.getTime() == d.getTime() && dayNames[d.getDay()] + " " + d.getDate() || (d.getHours() < 10 ? '0' : '') + d.getHours() + ":" + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
        })
      ).call((d) => this.tickSizeFix(d));

    this.__zoomBrushTicks.selectAll('.domain, line').remove();

    this.__zoomBrushTicks.selectAll("g.tick text")
      .attr("style", (d) => {
        let checkDate = new Date(d.getTime());
        checkDate.setHours(0); checkDate.setMinutes(0); checkDate.setSeconds(0);
        return checkDate.getTime() == d.getTime() && 'font-weight: bold!important;' || null;
      });

    this.__zoomBrush.moveToFront(); // <=
    this.__zoomBrushSidesHandlers.moveToFront(); // <=

    this.__obj_d3.append('clipPath')
      .attr('id', "taskClip")
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.__realWidth)
      .attr('height', this.__contentHeight);

    // if (this.enableHoverLines) {
    //   console.log("CARGAR HOVER")
    //   this.on('mouseenter', (hoverDate) => this.showHoverLine(hoverDate))
    //     .on('mousemove', (hoverDate) => this.showHoverLine(hoverDate))
    //     .on('mouseleave', () => this.hideHoverLine());
    // }
    this.tooltip.__setup();

    //Base loaded, load tasks
    this.__loadDataTasks()
  }
  setDomain(domain) {
    this.__cachedDomain = domain;
    this.xScale.domain(domain);
    this.__bottomAxis.call(this.__xScaleAxis)
    this.xGridScale.domain(domain);
    this.__xGridContainer.call(this.__xGridAxis)

    this.__zoomBrushSidesHandlers.attr("x", (d) => this.xScaleRef(d.type == 'w' && this.__cachedDomain[0] || this.__cachedDomain[1]) - 15)

    //Checking x grid ticks to be updated or not
    let targetTickIntervals = this.calcXTickInterval();
    if (!this.__xTickIntervals || this.__xTickIntervals[0] != targetTickIntervals[0]) {
      this.__xTickIntervals = targetTickIntervals;
      this.__xScaleAxis.ticks(this.getXTicks(1));
      this.__xGridAxis.ticks(this.getXTicks(0));
    }
    this.__updateTasks();
  }
  calcXTickInterval() {
    let localDomain = this.__cachedDomain,
      timerXpixelGroup = (localDomain[1] - localDomain[0]) / this.__realWidth; //group of 10 pixels
    if (timerXpixelGroup < 4000) {
      return ['2.5m', '5m'];
    } else if (timerXpixelGroup < 9000) {
      return ['5m', '10m'];
    } else if (timerXpixelGroup < 35192) {
      return ['15m', '30m'];
    } else if (timerXpixelGroup < 61314) {
      return ['30m', '1h'];
    } else if (timerXpixelGroup < 132061) {
      return ['1h', '2h'];
    } else if (timerXpixelGroup < 207524) {
      return ['2h', '4h'];
    } else if (timerXpixelGroup < 731053) {
      return ['4h', '8h'];
    } else if (timerXpixelGroup < 339586) {
      return ['8h', '12h'];
    } else if (timerXpixelGroup < 1419658) {
      return ['12h', '1d'];
    } else {
      return ['1d', '2d'];
    }// no soporta más de 2 meses, pero se puede adaptar
  }
  __d3Times = {
    '2.5m': d3.timeMinute.every(2.5),
    '5m': d3.timeMinute.every(5),
    '10m': d3.timeMinute.every(10),
    '15m': d3.timeMinute.every(15),
    '30m': d3.timeMinute.every(30),
    '1h': d3.timeHour.every(1),
    '2h': d3.timeHour.every(2),
    '4h': d3.timeHour.every(4),
    '8h': d3.timeHour.every(8),
    '1d': d3.timeDay.every(1),
    '2d': d3.timeDay.every(2)
  }
  getXTicks(type) {
    if (!this.__xTickIntervals) this.__xTickIntervals = this.calcXTickInterval();
    return this.__d3Times[this.__xTickIntervals[type]];
  }
  tickSizeFix(d, checkStart = false) {
    let self = this;
    d.selectAll('g').each(function () {
      let x = parseFloat(this.getAttribute('transform').replace('translate(', '').replace(')', '').split(',')[0]);
      if ((checkStart && x < 0) || self.__realWidth < x + this.getBBox().width) this.remove();
    });
  }
  on(event, callback) {
    //this.__eventListeners[event].push(callback);
    //return this;
    if (!this.__eventListeners[event]) this.__eventListeners[event] = [];
    this.__eventListeners[event].push(callback);
    return this;
  }
  __callEvent(event, ...args) {
    this.__eventListeners[event].forEach((func) => func.apply(this, args));
  }
  __imageFiltersCache = { length: 0 };
  __getFillFilter(d) {
    if (d.filter) {
      if (!this.__imageFiltersCache[d.filter.src + (d.filter.animated && 'animated' || '')]) {
        let uid = 'task-filter-' + this.__imageFiltersCache.length;
        this.__imageFiltersCache.length++;
        this.__imageFiltersCache[d.filter.src] = {
          uid: '#' + uid,
          element: this.__definitions.append('pattern')
            .attr('id', uid)
            .attr('x', 0)
            .attr('y', 0)
            .attr('class', d.filter.animated && 'filter-animated' || undefined)
            .attr('width', this.__taskHeight / d.filter.size[1] * d.filter.size[0] + 'px')
            .attr('height', this.__taskHeight + 'px')
            .attr('patternUnits', 'userSpaceOnUse')
            .html(`<image xlink:href="${d.filter.src}" x="0" y="0" height="${this.__taskHeight}"></image>`)
        };
      }
      return this.__imageFiltersCache[d.filter.src].uid;
    }
    return '#gradient';
  }
  updateTasks(newData) {
    this.data.tasks = clone(newData);
    this.__displayTasks.remove();
    this.__loadDataTasks();
  }
  updateTask(taskId, newData) {
    for (var taskKey in this.data.tasks) if (taskId == this.data.tasks[taskKey].id) this.data.tasks[taskKey] = clone(newData);
    this.__displayTasks.remove();
    this.__loadDataTasks();
  }
  tooltip = {
    __obj: document.createElement('div'),
    __textContainer: document.createElement('p'),
    __arrow: document.createElement('div'),
    showingTask: false,
    __setup() {
      this.__arrow.classList.add('arrow');
      this.__obj.classList.add('tooltip-gantt');
      this.__obj.classList.add('hover-line');
      this.__obj.style.display = 'none';
      this.__obj.style.position = 'fixed';
      this.__obj.style.pointerEvents = 'none';

      this.__obj.append(this.__textContainer);
      this.__obj.append(this.__arrow);

      document.body.append(this.__obj);
    },
    show(machine, text, color, x = 0, y = { top: 0, bottom: 0 }, tooltipSiempreDebajo = false, task = false) { // need bottomOffset if topOffset is too hight
      console.log("TOOLTIP")
      this.__textContainer.innerHTML = text;
      this.__obj.style.borderColor = color;
      this.__arrow.style.borderTopColor = color;
      this.__obj.style.display = '';
      this.showingTask = task;

      this.__obj.style.left = (x - (this.__obj.clientWidth / 2)) + 'px';
      let offset = y.top - this.__obj.clientHeight - 25 /* arrow */;

      if (tooltipSiempreDebajo) {//AJUSTAR TOOLTIP EN FUNCION DEL TIPO DE EJECUCION
        if (machine == 1 || machine == 2 || machine == 3) {//ENSEÑAR DEBAJO EL TOOLTIP
          this.__obj.style.top = y.bottom + 25 /* arrow */ + 'px';
          this.__obj.classList.add('bottom-reverse');
        } else {//ENSEÑAR ENCIMA EL TOOLTIP
          this.__obj.style.top = offset + 25 + 'px';
          this.__obj.classList.remove('bottom-reverse');
        }
      } else {//CALCULAR DONDE SE ENSEÑARA EL TOOLTIP
        if (offset > 0) {//ENSEÑAR ENCIMA EL TOOLTIP
          this.__obj.style.top = offset + 25 + 'px';
          this.__obj.classList.remove('bottom-reverse');
        } else {//ENSEÑAR DEBAJO EL TOOLTIP
          this.__obj.style.top = y.bottom + 25 /* arrow */ + 'px';
          this.__obj.classList.add('bottom-reverse');
        }
      }
    },
    hide() {
      this.showingTask = false;
      this.__obj.style.display = 'none';
    }
  }
  async __loadDataTasks() {
    for (var k in this.data.tasks) {
      this.data.tasks[k].startDate = new Date(this.data.tasks[k].startDate);
      this.data.tasks[k].endDate = new Date(this.data.tasks[k].endDate);
    }

    this.__displayTasks = this.__taskContainer.selectAll('rect')
      .data(this.data.tasks)
      .enter().append('g')
      .attr('transform', (d) => {
        d.y = this.yScale(d.machine) + this.tasksPadding;
        return this.__getTaskTransform(d);
      });
    let self = this;
    this.__displayTasksRects = this.__displayTasks.append('rect')
      .attr('fill', (d) => d.backgroundColor)
      // .attr('rx', (d) => 5)
      // .attr('ry', (d) => 5)
      .attr('width', function (d) { return self.__getTaskWide(d, this.parentElement); })
      .attr('height', this.__taskHeight);

    let automaticScrollMargin = this.__realWidth * .1,
      [tooltip, tooltipText, tooltipArrow] = (() => {
        let arrow = document.createElement('div');
        arrow.classList.add('arrow');
        let p = document.createElement('p');
        let ttip = document.createElement('div');
        ttip.classList.add('tooltip-gantt');
        ttip.style.display = 'none';
        ttip.style.position = 'fixed';
        ttip.append(p);
        ttip.append(arrow);
        //this.__obj_container.append(ttip);
        document.body.append(ttip);
        return [ttip, p, arrow];
      })();
    let mousePressed;
    this.__displayTasksRectsGradient = this.__displayTasks.append('rect')
      .attr('fill', (d) => `url(${this.__getFillFilter(d)})`)
      .attr('x', (d) => ((d.wide > .3 && .4) || 0))
      .attr('y', '.2')
      // .attr('rx', (d) => 5)
      // .attr('ry', (d) => 5)
      .attr('width', (d) => ((d.wide > .5 && d.wide - .4) || d.wide))
      .attr('height', this.__taskHeight - .4)
      .on('click', (d) => { this.__callEvent('task_clicked', d.id) })
      .on('mouseenter, mousemove', (d) => {
        if (this.enableHoverLines) {
          this.showHoverLine(d)
        } else {
          tooltipText.innerHTML = d.detail(d);
          tooltip.style.borderColor = d.backgroundColor;
          tooltipArrow.style.borderTopColor = d.backgroundColor;
          // if (this.enableHoverLines)
          //   tooltip.style.display = 'none'; //SI ESTA ACTIVADA LA LINEA VERTICAL ESCONDER EL TOOLTIP PARA QUE NO SALGA EL TOOLTIP DUPLICADO
          // else
          tooltip.style.display = '';
          let offsetTop = (d.y + this.getChartSVGOffset().top + self.__zoomBrushHeight - tooltip.clientHeight - 20);
          if (offsetTop < 0) {
            tooltip.style.top = (d.y + this.getChartSVGOffset().top + self.__zoomBrushHeight + self.__taskHeight + 40) + 'px';
            if (!tooltip.classList.contains('bottom-reverse')) tooltip.classList.add('bottom-reverse');
          } else {
            tooltip.style.top = offsetTop + 'px';
            if (tooltip.classList.contains('bottom-reverse')) tooltip.classList.remove('bottom-reverse');
          }
          tooltip.style.left = (this.getSVGPosFromScreenPos(d3.event.pageX) - (tooltip.clientWidth / 2)) + 'px';

          this.__callEvent('task_mouse', d.fechaini)
        }
      })
      .on('mouseleave', () => { tooltip.style.display = 'none'; this.__callEvent('task_hide') })
    this.__displayTasks.each(function (d) {
      let textWide = d.of.length * 10;
      if (d.wide > textWide && d.isOfVisible != true) self.__showTaskOf(this, d);
      if ((!d.isVisible || d.wide <= textWide) && d.isOfVisible == true) self.__hideTaskOp(this, d);
    })
    let filterAnimated = d3.selectAll('pattern.filter-animated'),
      x = 0;
    setInterval(() => {
      x += .5;
      filterAnimated.each(function () {
        this.setAttribute('x', x);
      });
    }, 50)
  }
  getChartSVGOffset() {
    return this.__obj.getBoundingClientRect();
  }
  getSVGPosFromScreenPos(x, y) {
    var r = 0
    if (this.usuario.menuExpandido) r = 167.5;
    let svgBCR = this.getChartSVGOffset();
    return !isNaN(x) ? (
      !isNaN(y) ? {
        top: x - svgBCR.top,
        left: y - svgBCR.left
      } : x - r - this.restarParaHoverLine
    ) : !isNaN(y) ? y : false;
  }
  getPriorizedTasks(taskList) {
    let targetTask, targetTaskMachiIndex = -1;
    for (var task of taskList) {
      let i = this.data.machines.findIndex((m) => m.id == task.machine);
      if (!targetTask || i < targetTaskMachiIndex) {
        targetTaskMachiIndex = i;
        targetTask = task;
      }
    }
    return targetTask;
  }
  __getTaskTransform(d) {
    d.x = this.xScale(d.startDate);
    d.isVisible = this.__isVisible(d);
    return this.__getTranslation(d);
  }
  __getTranslation = (d) => 'translate(' + d.x + ', ' + d.y + ')';
  __getTaskWide(d) {
    if (d.isVisible) {
      d.wide = this.xScale(d.endDate) - d.x;
    } else {
      d.wide = 0;
    }
    return d.wide;
  }
  __isVisible(d) {
    return (d.startDate.getTime() < this.__cachedDomain[1].getTime() && d.endDate.getTime() > this.__cachedDomain[0].getTime());
  }
  __showTaskOf(taskElement, d) {
    d.isOfVisible = true;
    d3.select(taskElement).append('text')
      .attr('x', 5)
      .attr('y', 15)
      .text((d) => d.of)
  }
  __hideTaskOp(taskElement, d) {
    delete d.isOfVisible;
    taskElement.children[taskElement.children.length - 1].remove()
  }
  __hoverLine;
  __setupHoverLine() {
    this.__hoverLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');
    this.__hoverLine.classList.add('hover-line');
    this.__hoverLine.setAttribute('y0', '0');
    this.__hoverLine.setAttribute('y1', this.__obj_machione_container.clientHeight);
    for (var k in this.hoverStrokeStyle) this.__hoverLine.style[k] = this.hoverStrokeStyle[k];
    this.__hoverLine.style.pointerEvents = 'none';
    this.__yGridContainer.node().appendChild(this.__hoverLine);
  }
  lockHoverLine = false;
  tareaActual = [];
  showHoverLine(dateTime, showTooltip = true) {

    if (this.lockHoverLine) return;
    if (!this.__hoverLine) this.__setupHoverLine();
    if (this.__hoverLine.style.display == 'none') this.__hoverLine.style.display = '';
    let x = this.xScale(dateTime);
    this.__hoverLine.setAttribute('x1', x);
    this.__hoverLine.setAttribute('x2', x);
    if (showTooltip && !this.tooltip.showingTask) {
      let goodOnes = [];
      this.__displayTasks
        .each((task) => {
          if (task.startDate < dateTime && dateTime < task.endDate) {
            goodOnes.push(task);
            return false;
          }
        });
      console.log(dateTime)
      if (goodOnes.length > 0) {
        /////////////////////
        let d = this.getPriorizedTasks(goodOnes);
        let offset = this.getChartSVGOffset();
        let topY = d.y + (this.__zoomBrushHeight + offset.top);
        let bottomY = d.y + (this.__zoomBrushHeight + offset.top) + this.__taskHeight;
        this.tooltip.show(d.machine, d.detail(d), d.backgroundColor, offset.x + x, { top: topY, bottom: bottomY }, this.tooltipSiempreDebajo, false);

        console.log(d.detail(d))

        this.tareaActual = [d];
      } else {
        this.tooltip.hide();
        this.tareaActual = [];
      }
    }
  }
  hideHoverLine() {
    if (this.__hoverLine && this.__hoverLine.style.display != 'none') {
      this.__hoverLine.style.display = 'none';
      this.tooltip.hide();
    }
  }
  async __updateTasks() {
    let self = this;
    //check if scale was modifyed or not (to cahnge only the transforms)        
    this.__displayTasks
      .each(function (d) {
        if (!d.dragging) {
          this.setAttribute('transform', self.__getTaskTransform(d));
          this.children[0].setAttribute('width', self.__getTaskWide(d))
          this.children[1].setAttribute('width', (d.wide > .5 && d.wide - .4) || d.wide)
          let textWide = d.of.length * 10;
          if (d.wide > textWide && d.isOfVisible != true) self.__showTaskOf(this, d);
          if ((!d.isVisible || d.wide <= textWide) && d.isOfVisible == true) self.__hideTaskOp(this, d);
        }
      });


    this.shiftLimiters
      .attr('transform', (d) => {
        d.x = this.xScale(d.min);
        return `translate(${d.x}, 0)`;
      });

    this.shiftLimiters.selectAll('rect')
      .attr('width', (d) => {
        d.wide = this.xScale(d.max) - d.x;
        return d.wide;
      });

    this.shiftLimiters.selectAll('line.end-stroke')
      .attr('x1', (d) => d.wide)
      .attr('x2', (d) => d.wide);

  }
  updateZoomAllCharts(self, graficos, startDate, endDate) {
    self.__zoomBrush.call(self.__zoomBrushController.move, [self.xScaleRef(startDate), self.xScaleRef(endDate)]);
    for (var i = 0; i < graficos.length; i++) {
      graficos[i].__zoomBrush.call(graficos[i].__zoomBrushController.move, [graficos[i].xScaleRef(startDate), graficos[i].xScaleRef(endDate)]);
    }
  }
}
//#endregion

//GanttChartPlanificadorTemporal ES COMO EL GANTT CHART DRAG PERO SIN EL DRAG & DROP
//__CONSEJO__
// sale mejor borrar este grafico, copiar y pegar el "GanttChartDrag" y borrar todo lo que afecte a DRAG&DROP
class GanttChartPlanificadorTemporal {
  width = 'fit';
  height = 500;
  machine = '';
  startDate = new Date('2020-1-1');
  endDate = new Date((new Date('2020-1-1')).getTime() + (24 * 60 * 60 * 1000 * 7));
  allowedMachines = [];
  shifts = [];
  tasksPadding = 8;
  enableHoverLines = false;
  filtrarPorMaquina = false;
  __taskElements = [];
  __zoomBrushHeight = 20;
  __cachedDomain = [];
  __eventListeners = { 'task-dropped': [], 'task-ctrl-clicked': [] };
  __margin = {
    top: 5,
    right: 15
  }
  constructor(elementID, config = {}, translate) {
    d3.timeFormatDefaultLocale({
      "decimal": ".",
      "thousands": ",",
      "grouping": [3],
      "currency": ["$", ""],
      "dateTime": "%a %b %e %X %Y",
      "date": "%m/%d/%Y",
      "time": "%H:%M:%S",
      "periods": ["AM", "PM"],
      "days": [translate.instant('domingo'), translate.instant('lunes'), translate.instant('martes'), translate.instant('miercoles'), translate.instant('jueves'), translate.instant('viernes'), translate.instant('sabado')],
      "shortDays": [translate.instant('abrDomingo'), translate.instant('abrLunes'), translate.instant('abrMartes'), translate.instant('abrMiercoles'), translate.instant('abrJueves'), translate.instant('abrViernes'), translate.instant('abrSabado')],
      "months": [translate.instant('enero'), translate.instant('febrero'), translate.instant('marzo'), translate.instant('abril'), translate.instant('mayo'), translate.instant('junio'), translate.instant('julio'), translate.instant('agosto'), translate.instant('septiembre'), translate.instant('octubre'), translate.instant('noviembre'), translate.instant('diciembre')],
      "shortMonths": [translate.instant('abrEnero'), translate.instant('abrFebrero'), translate.instant('abrMarzo'), translate.instant('abrAbril'), translate.instant('abrMayo'), translate.instant('abrJunio'), translate.instant('abrJulio'), translate.instant('abrAgosto'), translate.instant('abrSeptiembre'), translate.instant('abrOctubre'), translate.instant('abrNoviembre'), translate.instant('abrDiciembre')]
    });

    if (!elementID) return false;
    for (var k in config) {
      if (k == 'data') this.data = clone(config[k]);
      else this[k] = config[k];
    }
    this.__obj_container = document.getElementById(elementID);
    this.__obj_container.innerHTML = "";
    this.__obj_container.style.display = 'flex';
    this.__obj_container.style.overflow = 'hidden';
    this.__obj_container.style.paddingRight = '20px';

    if (this.width == 'fit') this.width = this.__obj_container.clientWidth;
    this.__realWidth = this.width - (this.__margin.right);
    this.__realHeight = this.height - (this.__margin.top);

    this.__contentHeight = this.__realHeight - (this.__zoomBrushHeight + 20/*bottom axis height*/)

    this.__taskHeight = (this.__contentHeight / this.data.machines.length);

    this.bottomScaleHeight = 20;

    this.__obj_machione_container = document.createElement('div');
    this.__obj_machione_container.style.overflow = 'hidden';
    this.__obj_machione_container.classList.add('machines-container');
    let machinesHTML = '';
    this.data.machines.forEach(value => {
      machinesHTML += `<div class="machine" style="height: ${this.__taskHeight}px;"><div><img src="${value.imagenBase64}"></div><div><span>${value.name}</span></div></div>`;
    });
    this.__obj_machione_container.innerHTML = machinesHTML;
    this.__obj_machione_container.style.marginTop = (this.__margin.top + this.__zoomBrushHeight) + 'px';
    this.__obj_machione_container.style.height = 'calc( 100% - ' + (this.__margin.top + this.__zoomBrushHeight + this.bottomScaleHeight) + 'px )';
    this.__obj_container.append(this.__obj_machione_container);
    this.__realWidth -= this.__obj_machione_container.clientWidth;

    this.__obj = document.createElementNS("http://www.w3.org/2000/svg", "svg")
    this.__obj_container.append(this.__obj);
    this.__obj_d3 = d3.select(this.__obj);
    let self = this;
    let selectRect;
    this.__obj_d3
      .attr('width', this.__realWidth + 'px')
      .attr('height', this.__obj_container + 'px')
      .attr('class', "gantt-chart")
      .style('margin-top', this.__margin.top + 'px')
      .style('overflow', 'hidden')
      .on("contextmenu", () => d3.event.preventDefault())
      .on('mousedown', function () {
        if (d3.event.button == 2) {
          var e = this,
            origin = d3.mouse(e);
          selectRect.attr('width', 0).attr('display', '')
          origin = [origin[0], origin[1]]
          d3.select("body").classed("noselect", true);
          origin[0] = Math.max(0, Math.min(self.__realWidth, origin[0]));
          origin[1] = Math.max(0, Math.min(self.__realWidth, origin[1]));
          d3.select(window)
            .on("mousemove.zoomRect", function () {
              var m = d3.mouse(e);
              if (m[0] > self.__realWidth) m[0] = self.__realWidth;
              if (m[1] > self.__realHeight) m[1] = self.__realHeight;
              m = [m[0], m[1]]
              m[0] = Math.max(0, Math.min(self.__realWidth, m[0]));
              m[1] = Math.max(0, Math.min(self.__realWidth, m[1]));
              selectRect.attr("x", Math.min(origin[0], m[0]))
                .attr("y", self.__margin.top + self.__zoomBrushHeight)
                .attr("width", Math.abs(((m[0]) - (origin[0]))))
                .attr("height", self.__contentHeight);
            }, true)
            .on("mouseup.zoomRect", function () {
              d3.event.preventDefault();
              d3.select(window).on("mousemove.zoomRect", null).on("mouseup.zoomRect", null);
              d3.select("body").classed("noselect", false);
              var m = d3.mouse(e);
              m[0] = Math.max(0, Math.min(self.__realWidth, m[0]));
              m[1] = Math.max(0, Math.min(self.__contentHeight, m[1]));
              if (m[0] !== origin[0] && m[1] !== origin[1]) {
                let newDomain;
                if (origin[0] < m[0]) {
                  newDomain = [self.xScale.invert(origin[0]), self.xScale.invert(m[0])];
                } else {
                  newDomain = [self.xScale.invert(m[0]), self.xScale.invert(origin[0])];
                }
                self.__zoomBrush.call(self.__zoomBrushController.move, [self.xScaleRef(newDomain[0]), self.xScaleRef(newDomain[1])]);
              }
              selectRect.attr('display', 'none')
            }, true);
        }
        d3.event.stopPropagation();
      })
      .on('wheel', () => this.onWheel(d3.event));

    d3.select(this.__obj_machione_container)
      .on('wheel', () => this.onWheel(d3.event));

    this.xScale = d3.scaleTime()
      .domain([this.startDate, this.endDate])
      .range([0, this.__realWidth]);
    this.__cachedRange = this.xScale.range();
    this.__cachedDomain = this.xScale.domain();

    this.yScale = d3.scaleOrdinal()
      .domain(this.data.machines.map((d) => d.id))
      .range(this.data.machines.map((_, k) => k * this.__taskHeight))
    this.__cachedYScaleRange = this.yScale.range();

    this.__taskHeight = this.__taskHeight - (this.tasksPadding * 2); // apply padding after setting yDomain

    this.xScaleRef = d3.scaleTime()
      .domain(this.xScale.domain())
      .range([0, this.__realWidth]);

    this.xGridScale = d3.scaleTime()
      .domain(this.xScale.domain())
      .range(this.xScale.range());

    this.__zoomBrush = this.__obj_d3.append("g")
      .attr('class', "zoom-brush")
      .attr('transform', `translate(0,${this.__margin.top})`);

    this.__zoomBrush.append('rect')
      .attr('class', "bg")
      .attr('width', this.__realWidth)
      .attr('height', this.__zoomBrushHeight);

    this.__xScaleAxis = d3.axisBottom(this.xScale)
      .ticks(this.getXTicks(1))
      .tickFormat(d3.timeFormat("%H:%M"));

    let xScalOffsetX = this.__margin.top + this.__zoomBrushHeight + this.__contentHeight;

    this.__zoomBrushController = d3.brushX()
      .extent([[1, 1], [this.__realWidth - 2, this.__zoomBrushHeight - 1]])
      .on("start", function () { this.classList.add('grabbing'); })
      .on("end", function () { this.classList.remove('grabbing'); })
      .on("brush end", () => {
        if ((d3.event.selection && d3.event.sourceEvent) || d3.brushEventException) this.setDomain(d3.event.selection.map(this.xScaleRef.invert, this.xScale));
      });

    this.__xGridAxis = d3.axisTop()
      .scale(this.xGridScale)
      .ticks(this.getXTicks(0))
      .tickSize(this.__contentHeight, 0, 0)
      .tickFormat("");

    this.__xGridContainer = this.__obj_d3.append("g")
      .attr('class', "x grid")
      .attr('transform', `translate(0,${xScalOffsetX})`)
      .call(this.__xGridAxis);

    this.__yGridAxis = d3.axisRight()
      .scale(this.yScale)
      .tickSize(this.__realWidth, 0, 0)
      .tickFormat("");

    this.__yGridContainer = this.__obj_d3.append("g")
      .attr('class', "y grid")
      .attr('transform', `translate(0,${this.__margin.top + this.__zoomBrushHeight})`)
      .call(this.__yGridAxis)
      .call((e) => e.selectAll('path, g.tick:first-of-type').remove());

    this.shiftContainers = this.__obj_d3.append('g')
      .attr('transform', `translate(0, ${this.__margin.top + this.__zoomBrushHeight})`)
      .attr('clip-path', "url(#taskClip)");

    this.shiftLimiters = this.shiftContainers.selectAll('.delimiter')
      .data(this.shifts)
      .enter().append('g')
      .attr('class', "delimiter")
      .attr('transform', (d) => {
        d.x = this.xScale(d.min);
        return `translate(${d.x}, 0)`;
      });

    this.shiftLimiters.append('rect')
      .attr('width', (d) => {
        d.wide = this.xScale(d.max) - d.x;
        return d.wide;
      })
      .attr('height', this.__contentHeight)

    this.shiftLimiters.append('line')
      .attr('class', 'end-stroke')
      .attr('x1', (d) => d.wide)
      .attr('x2', (d) => d.wide)
      .attr('y2', this.__contentHeight)

    this.shiftLimiters.append('line')
      .attr('y2', this.__contentHeight)

    this.__taskContainer = this.__obj_d3.append('g')
      .attr('transform', `translate(0, ${this.__margin.top + this.__zoomBrushHeight})`)
      .attr('class', "task-container")
      .attr('clip-path', "url(#taskClip)");

    this.__bottomAxis = this.__obj_d3.append("g")
      .attr('class', "axis-bottom")
      .attr('transform', 'translate(0, ' + (xScalOffsetX - (this.__obj_machione_container.scrollHeight - this.__obj_machione_container.clientHeight)) + ')');

    this.__bottomAxis.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '101%')
      .attr('height', this.bottomScaleHeight + 1)
      .attr('fill', 'white');

    this.__bottomAxis.call(this.__xScaleAxis);
    //this.__bottomAxis.selectAll('.domain, line').remove(); descomentar para hacer aparcer líneas de dominio

    this.__definitions = this.__taskContainer.append('defs')
      .html(`
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style="stop-color:rgb(255,255,255,0.15);stop-opacity:1" />
                    <stop offset="100%" style="stop-color:rgb(255,255,255,0);stop-opacity:1" />
                </linearGradient>`);

    let showAllButtonWidth = 110,
      showAllButtonHeight = 35;
    this.__obj_d3.append('g')
      .attr('class', "show-all-button")
      .attr('transform', `translate(${this.__realWidth - showAllButtonWidth},${this.__margin.top + this.__zoomBrushHeight})`)
      .html(` <rect x="0" y="0" width="${showAllButtonWidth}" height="${showAllButtonHeight}" opacity="0" ></rect>
                    <image x="5" y="${showAllButtonHeight / 2 - 10}" width="20" height="20" xlink:href="assets/img/lens.svg" class="amcharts-zoom-out-image"></image>
                    <text x="30" y="${showAllButtonHeight / 2 + 4}" fill="#000000" font-family="Verdana" font-size="11px" opacity="1" text-anchor="center" class="amcharts-zoom-out-label">` + translate.instant('mostrarTodos') + `</text>`)
      .on('click', () => this.__zoomBrush.call(this.__zoomBrushController.move, [this.xScaleRef(this.startDate), this.xScaleRef(this.endDate)]));

    selectRect = self.__obj_d3.append("rect").attr("class", "zoom-rect").attr('display', 'none');

    this.__zoomBrushSidesHandlers = this.__zoomBrush.selectAll('.handle--custom')
      .data([{ type: "w" }, { type: "e" }])
      .enter()
      .append('image')
      .attr('class', (d) => `handle--custom handle--${d.id}`)
      .attr("width", '30px')
      .attr("height", '30px')
      .attr("x", (d) => this.xScaleRef(d.type == 'w' && this.__cachedDomain[0] || this.__cachedDomain[1]) - 15)
      .attr("y", '-6px')
      .attr("xlink:href", 'assets/img/dragIconRoundBig.svg');

    this.__zoomBrush
      .call(this.__zoomBrushController);

    this.__zoomBrush.call(this.__zoomBrushController.move, this.xScale.range()); //we set the brush with the default domain

    this.__zoomBrushTicks = this.__zoomBrush.append('g')
      .attr('class', "x-top-axis")
      .attr('clip-path', "url(#taskClip)")
      .append('g')
      .attr('transform', `translate(0,${this.__zoomBrushHeight + 3})`)
      .call(d3.axisTop(this.xScaleRef)
        .ticks(Math.floor(this.__realWidth / 70))
        .tickFormat((d) => {
          var dayNames = [translate.instant('abrDomingo'), translate.instant('abrLunes'), translate.instant('abrMartes'), translate.instant('abrMiercoles'), translate.instant('abrJueves'), translate.instant('abrViernes'), translate.instant('abrSabado')];
          let checkDate = new Date(d.getTime());
          checkDate.setHours(0); checkDate.setMinutes(0); checkDate.setSeconds(0);
          return checkDate.getTime() == d.getTime() && dayNames[d.getDay()] + " " + d.getDate() || (d.getHours() < 10 ? '0' : '') + d.getHours() + ":" + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
        })
      ).call((d) => this.tickSizeFix(d));

    this.__zoomBrushTicks.selectAll('.domain, line').remove();

    this.__zoomBrushTicks.selectAll("g.tick text")
      .attr("style", (d) => {
        let checkDate = new Date(d.getTime());
        checkDate.setHours(0); checkDate.setMinutes(0); checkDate.setSeconds(0);
        return checkDate.getTime() == d.getTime() && 'font-weight: bold!important;' || null;
      });

    this.__zoomBrush.moveToFront(); // <=
    this.__zoomBrushSidesHandlers.moveToFront(); // <=

    this.__obj_d3.append('clipPath')
      .attr('id', "taskClip")
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.__realWidth)
      .attr('height', this.__contentHeight);

    //Base loaded, load tasks
    this.__loadDataTasks()
  }
  setDateRange(startDate, endDate) {
    this.startDate = startDate;
    this.endDate = endDate;
    let oldDom = this.xScale.domain();
    oldDom[0] = (startDate < oldDom[0]) ? oldDom[0] : startDate;
    oldDom[1] = (endDate > oldDom[1]) ? oldDom[1] : endDate;
    this.xScaleRef.domain([startDate, endDate]);
    this.__updateTopDomain();
    this.__zoomBrush.call(this.__zoomBrushController.move, [this.xScaleRef(oldDom[0]), this.xScaleRef(oldDom[1])]);
    this.setDomain(oldDom);

  }
  __updateTopDomain() {
    this.__zoomBrushTicks
      .call(d3.axisTop(this.xScaleRef)
        .ticks(Math.floor(this.__realWidth / 70))
      ).call((d) => this.tickSizeFix(d));

    this.__zoomBrushTicks.selectAll('.domain, line').remove();
  }
  setDomain(domain) {
    this.__cachedDomain = domain;
    this.xScale.domain(domain);
    this.xGridScale.domain(domain);

    this.__zoomBrushSidesHandlers.attr("x", (d) => this.xScaleRef(d.type == 'w' && this.__cachedDomain[0] || this.__cachedDomain[1]) - 15)

    //Checking x grid ticks to be updated or not
    let targetTickIntervals = this.calcXTickInterval();
    if (!this.__xTickIntervals || this.__xTickIntervals[0] != targetTickIntervals[0]) {
      this.__xTickIntervals = targetTickIntervals;
      this.__xScaleAxis.ticks(this.getXTicks(1));
      this.__xGridAxis.ticks(this.getXTicks(0));
    }
    this.__bottomAxis.call(this.__xScaleAxis)
    this.__xGridContainer.call(this.__xGridAxis)
    this.__updateTasks();
  }
  calcXTickInterval() {
    let localDomain = this.__cachedDomain,
      timerXpixelGroup = (localDomain[1] - localDomain[0]) / this.__realWidth; //group of 10 pixels
    if (timerXpixelGroup < 4000) {
      return ['2.5m', '5m'];
    } else if (timerXpixelGroup < 9000) {
      return ['5m', '10m'];
    } else if (timerXpixelGroup < 35192) {
      return ['15m', '30m'];
    } else if (timerXpixelGroup < 61314) {
      return ['30m', '1h'];
    } else if (timerXpixelGroup < 132061) {
      return ['1h', '2h'];
    } else if (timerXpixelGroup < 207524) {
      return ['2h', '4h'];
    } else if (timerXpixelGroup < 731053) {
      return ['4h', '8h'];
    } else if (timerXpixelGroup < 339586) {
      return ['8h', '12h'];
    } else if (timerXpixelGroup < 1419658) {
      return ['12h', '1d'];
    } else {
      return ['1d', '2d'];
    }// no soporta más de 2 meses, pero se puede adaptar
  }
  __d3Times = {
    '2.5m': d3.timeMinute.every(2.5),
    '5m': d3.timeMinute.every(5),
    '10m': d3.timeMinute.every(10),
    '15m': d3.timeMinute.every(15),
    '30m': d3.timeMinute.every(30),
    '1h': d3.timeHour.every(1),
    '2h': d3.timeHour.every(2),
    '4h': d3.timeHour.every(4),
    '8h': d3.timeHour.every(8),
    '1d': d3.timeDay.every(1),
    '2d': d3.timeDay.every(2)
  }
  getXTicks(type) {
    if (!this.__xTickIntervals) this.__xTickIntervals = this.calcXTickInterval();
    return this.__d3Times[this.__xTickIntervals[type]];
  }
  tickSizeFix(d, checkStart = false) {
    let self = this;
    d.selectAll('g').each(function () {
      let x = parseFloat(this.getAttribute('transform').replace('translate(', '').replace(')', '').split(',')[0]);
      if ((checkStart && x < 0) || self.__realWidth < x + this.getBBox().width) this.remove();
    });
  }
  on(event, callback) {
    this.__eventListeners[event].push(callback);
    return this;
  }
  __callEvent(event, ...args) {
    //this.__eventListeners[event].forEach((func) => func.apply(this, args));
    if (this.__eventListeners[event]) this.__eventListeners[event].forEach((func) => func.apply(this, args));
  }
  scrollTop = 0;
  async onWheel(e) {
    if (this.tooltip) this.tooltip.hide();
    e.preventDefault();
    this.__obj_machione_container.scrollTop += e.deltaY;
    this.scrollTop = this.__obj_machione_container.scrollTop;
    let xScalOffsetX = this.__zoomBrushHeight + this.__contentHeight;
    this.__taskContainer.attr('transform', 'translate(0, ' + (this.__zoomBrushHeight - this.scrollTop) + ')');
    this.__yGridContainer.attr('transform', 'translate(0, ' + (this.__zoomBrushHeight - this.scrollTop) + ')');
    this.__bottomAxis.attr('transform', 'translate(0, ' + (xScalOffsetX - (this.__obj_machione_container.scrollHeight - this.__obj_machione_container.clientHeight)) + ')');
  }
  __imageFiltersCache = { length: 0 };
  __getFillFilter(d) {
    if (d.filter) {
      if (!this.__imageFiltersCache[d.filter.src + (d.filter.animated && 'animated' || '')]) {
        let uid = 'task-filter-' + this.__imageFiltersCache.length;
        this.__imageFiltersCache.length++;
        this.__imageFiltersCache[d.filter.src] = {
          uid: '#' + uid,
          element: this.__definitions.append('pattern')
            .attr('id', uid)
            .attr('x', 0)
            .attr('y', 0)
            .attr('class', d.filter.animated && 'filter-animated' || undefined)
            .attr('width', this.__taskHeight / d.filter.size[1] * d.filter.size[0] + 'px')
            .attr('height', this.__taskHeight + 'px')
            .attr('patternUnits', 'userSpaceOnUse')
            .html(`<image xlink:href="${d.filter.src}" x="0" y="0" height="${this.__taskHeight}"></image>`)
        };
      }
      return this.__imageFiltersCache[d.filter.src].uid;
    }
    return '#gradient';
  }
  updateTasks(newData, referenciaof) {
    this.data.tasks = clone(newData);
    this.__displayTasks.remove();
    this.__loadDataTasks(referenciaof);
  }
  updateTask(taskId, newData) {
    for (var taskKey in this.data.tasks) if (taskId == this.data.tasks[taskKey].id) this.data.tasks[taskKey] = clone(newData);
    this.__displayTasks.remove();
    this.__loadDataTasks();
  }
  async __loadDataTasks(referenciaof) {

    console.log("holii")

    var filtrarPorMaquina = this.filtrarPorMaquina;

    for (var k in this.data.tasks) {
      this.data.tasks[k].startDate = new Date(this.data.tasks[k].startDate);
      this.data.tasks[k].endDate = new Date(this.data.tasks[k].endDate);
    }

    this.__displayTasks = this.__taskContainer.selectAll('rect')
      .data(this.data.tasks)
      .enter().append('g')
      .attr('transform', (d) => {
        d.y = this.yScale(d.machine) + this.tasksPadding;
        return this.__getTaskTransform(d);
      })
      .each(function (d) {
        d.element = this;
      });
    let self = this;
    console.log("taska sortzen yuju")
    this.__displayTasksRects = this.__displayTasks.append('rect')
      .attr('fill', (d) => d.backgroundColor)
      // .attr('rx', (d) => 5)
      // .attr('ry', (d) => 5)
      .attr('width', function (d) { return self.__getTaskWide(d, this.parentElement); })
      .attr('height', this.__taskHeight);


    let automaticScrollMargin = this.__realWidth * .1,
      [tooltip, tooltipText, tooltipArrow] = (() => {
        let arrow = document.createElement('div');
        arrow.classList.add('arrow');
        let p = document.createElement('p');
        let ttip = document.createElement('div');
        ttip.classList.add('tooltip-gantt');
        ttip.style.display = 'none';
        ttip.style.position = 'fixed';
        ttip.append(p);
        ttip.append(arrow);
        document.body.append(ttip);
        return [ttip, p, arrow];
      })();
    let mousePressed, draggingElements;
    this.__dragInterval;
    this.__displayTasksRectsGradient = this.__displayTasks.append('rect')
      .attr('fill', (d) => `url(${this.__getFillFilter(d)})`)
      .attr('x', (d) => ((d.wide > .3 && .4) || 0))
      .attr('y', '.2')
      // .attr('rx', (d) => 5)
      // .attr('ry', (d) => 5)
      .attr('width', (d) => ((d.wide > .5 && d.wide - .4) || d.wide))
      .attr('height', this.__taskHeight - .4)
      .on('click', (d) => {
        if (d3.event.ctrlKey) this.__callEvent('task-ctrl-clicked', d.id);
      })
      .on('mouseenter, mousemove', (d) => {
        tooltipText.innerHTML = d.detail(d);
        tooltip.style.borderColor = d.backgroundColor;
        tooltipArrow.style.borderTopColor = d.backgroundColor;
        tooltip.style.display = '';

        let offsetTop = (d.y + this.getChartSVGOffset().top + self.__zoomBrushHeight - tooltip.clientHeight - 20 - this.scrollTop);
        if (offsetTop < 0) {
          tooltip.style.top = (d.y + this.getChartSVGOffset().top + self.__zoomBrushHeight + self.__taskHeight + 20 - this.scrollTop) + 'px';
          if (!tooltip.classList.contains('bottom-reverse')) tooltip.classList.add('bottom-reverse');
        } else {
          tooltip.style.top = offsetTop + 'px';
          if (tooltip.classList.contains('bottom-reverse')) tooltip.classList.remove('bottom-reverse');
        }
        tooltip.style.left = (this.getSVGPosFromScreenPos(d3.event.pageX) - (tooltip.clientWidth / 2)) + 'px';

        //MARKEL v
        //let x = d3.event.x;
        //let topY = d.y + (this.__zoomBrushHeight + this.getChartSVGOffset().top - this.scrollTop);
        //let bottomY = topY + this.__taskHeight;
        //this.tooltip.show(d.detail(d), d.backgroundColor, x, { top: topY, bottom: bottomY }, true);
        //MARKEL ^
      })
      .on('mouseleave', () => tooltip.style.display = 'none')
      .call(d3.drag()
        .on("start", function (d) {
          draggingElements = ('linking' in d) ? self.data.tasks.filter(t => t.linking == d.linking) : [d];
          draggingElements.forEach(dl => {
            dl.startY = dl.y;
            dl.startX = dl.x;
            dl.startMachine = dl.machine;
            dl.dragStartOffsetX = d3.event.x;
            dl.dragging = true;
            d3.select(dl.element).moveToFront();
          });

          mousePressed = true;
          setTimeout(() => {
            if (mousePressed) {
              if (tooltip.style.display != 'none') tooltip.style.display = 'none';
              return;
            };
            let shouldAdd = this.parentElement.classList.contains('fadeout') ? true : -1;
            self.__displayTasksRectsGradient.each(function (taskD) {
              //Se resaltan los de la misma maquina
              if (filtrarPorMaquina) {
                if (taskD.machine != d.machine) {
                  if (shouldAdd == -1) shouldAdd = !this.parentElement.classList.contains('fadeout');
                  if (shouldAdd) this.parentElement.classList.add('fadeout');
                  else this.parentElement.classList.remove('fadeout');
                } else {
                  if (this.parentElement.classList.contains('fadeout')) this.parentElement.classList.remove('fadeout');
                  d3.select(this.parentElement).moveToFront();
                }
              }
              else
              //Se resaltan los del mismo OF
              {
                if (taskD.of != d.of) {
                  if (shouldAdd == -1) shouldAdd = !this.parentElement.classList.contains('fadeout');
                  if (shouldAdd) this.parentElement.classList.add('fadeout');
                  else this.parentElement.classList.remove('fadeout');
                } else {
                  if (this.parentElement.classList.contains('fadeout')) this.parentElement.classList.remove('fadeout');
                  d3.select(this.parentElement).moveToFront();
                }
              }

            })
          }, 150);
        })
        .on('end', function (d) {
          mousePressed = false;
          clearInterval(self.__dragInterval);

          delete d.dragging;
          delete d.dragStartOffsetX;
          if (this.parentElement.classList.contains('not-allowed')) this.parentElement.classList.remove('not-allowed');
          let taskSize = d.endDate.getTime() - d.startDate.getTime();
          d.startDate = self.xScale.invert(d.x);
          d.endDate = new Date(d.startDate.getTime() + taskSize);

          /*ESTO ESTA EN EL CODIGO DE MARKEL PERO NO ES NECESARIO START */
          //draggingElements.forEach(dl => {
          //  delete dl.dragging;
          //  delete dl.dragStartOffsetX;
          //  if (dl.element.classList.contains('not-allowed')) dl.element.classList.remove('not-allowed');
          //  if (!dl.allowedMachines.includes(dl.machine)) {
          //    dl.x = dl.startX;
          //    dl.y = dl.startY;
          //    dl.machine = dl.startMachine;
          //    delete dl.startX;
          //    delete dl.startY;
          //    dl.element.setAttribute('transform', self.__getTranslation(d));
          //  } else {
          //    self.__callEvent('task-dropped', dl.id, dl.startDate, dl.machine != dl.startMachine && dl.machine || false);
          //  }
          //  let taskSize = dl.endDate.getTime() - dl.startDate.getTime();
          //  dl.startDate = self.xScale.invert(dl.x);
          //  dl.endDate = new Date(dl.startDate.getTime() + taskSize);
          //});
          /*ESTO ESTA EN EL CODIGO DE MARKEL PERO NO ES NECESARIO END */

          tooltip.style.display = 'none'; //si no oculto el tooltip se queda permanente  

          //if (!d.allowedMachines.includes(d.machine)) {
          //  d.x = d.startX;
          //  d.y = d.startY;
          //  d.machine = d.startMachine;
          //  delete d.startX;
          //  delete d.startY;
          //  this.parentElement.setAttribute('transform', self.__getTranslation(d));
          //} else {
          var dif = Date.now() - this.dragIni;
          if (!Number.isNaN(dif) && dif > 15) {
            this.dragIni = 1 / 0; // a veces no actualiza el drag la fecha. y para esos casos hago que salga directamente
            self.__callEvent('task-dropped', d.id, d.startDate, d.machine/* != d.startMachine && d.machine || false *//* aunque sea la misma maquina puede cambiar de fecha y me viene bien que me de el ID */);
          } else {
            this.dragIni = 1 / 0;// a veces no actualiza el drag la fecha. y para esos casos hago que salga directamente
            //si el peridodo es tan corto lo cuento como click, y deshago lo hecho          
            d.x = d.startX;
            d.y = d.startY;
            d.machine = d.startMachine;
            delete d.startX;
            delete d.startY;

            self.__callEvent('task-ctrl-clicked', d.id);
            this.parentElement.setAttribute('transform', self.__getTranslation(d));
          }
          //}
        }));
    this.__displayTasks.each(function (d) {
      let textWide = d.of.length * 10;
      if (d.wide > textWide && d.isOfVisible != true) self.__showTaskOf(this, d);
      if ((!d.isVisible || d.wide <= textWide) && d.isOfVisible == true) self.__hideTaskOp(this, d);
    })
    let filterAnimated = d3.selectAll('pattern.filter-animated'),
      x = 0;
    setInterval(() => {
      x += .5;
      filterAnimated.each(function () {
        this.setAttribute('x', x);
      });
    }, 50)
  }
  unsellectAllTasks() {
    //Deselecciona todos los tasks del gant
    this.__displayTasksRectsGradient.each(function (taskD) {
      this.parentElement.classList.remove('fadeout');
    })
  }
  getAnyUnsellected() {
    //Devuelve true si no hay ninguna maquina seleccionada y false si hay alguna maquina seleccionada
    var result = true;
    this.__displayTasksRectsGradient.each(function (taskD) {
      if (this.parentElement.classList.contains('fadeout') == true) {
        result = false;
      }
    })
    return result;
  }
  getChartSVGOffset() {
    return this.__obj.getBoundingClientRect();
  }
  getSVGPosFromScreenPos(x, y) {
    let svgBCR = this.getChartSVGOffset();
    return !isNaN(x) ? (
      !isNaN(y) ? {
        top: x - svgBCR.top,
        left: y - svgBCR.left
      } : x
    ) : !isNaN(y) ? y : false;
  }
  __getTaskTransform(d) {
    d.x = this.xScale(d.startDate);
    d.isVisible = this.__isVisible(d);
    return this.__getTranslation(d);
  }
  __getTranslation = (d) => 'translate(' + d.x + ', ' + d.y + ')';
  __getTaskWide(d) {
    if (d.isVisible) {
      d.wide = this.xScale(d.endDate) - d.x;
    } else {
      d.wide = 0;
    }
    return d.wide;
  }
  __isVisible(d) {
    return (d.startDate.getTime() < this.__cachedDomain[1].getTime() && d.endDate.getTime() > this.__cachedDomain[0].getTime());
  }
  __showTaskOf(taskElement, d) {
    d.isOfVisible = true;
    d3.select(taskElement).append('text')
      .attr('x', 5)
      .attr('y', 15)
      .text((d) => d.of)
  }
  __hideTaskOp(taskElement, d) {
    delete d.isOfVisible;
    taskElement.children[taskElement.children.length - 1].remove()
  }
  async __updateTasks() {
    let self = this;
    //check if scale was modifyed or not (to cahnge only the transforms)
    this.__displayTasks
      .each(function (d) {
        if (!d.dragging) {
          this.setAttribute('transform', self.__getTaskTransform(d));
          this.children[0].setAttribute('width', self.__getTaskWide(d))
          this.children[1].setAttribute('width', (d.wide > .5 && d.wide - .4) || d.wide)
          let textWide = d.of.length * 10;
          if (d.wide > textWide && d.isOfVisible != true) self.__showTaskOf(this, d);
          if ((!d.isVisible || d.wide <= textWide) && d.isOfVisible == true) self.__hideTaskOp(this, d);
        }
      });


    this.shiftLimiters
      .attr('transform', (d) => {
        d.x = this.xScale(d.min);
        return `translate(${d.x}, 0)`;
      });

    this.shiftLimiters.selectAll('rect')
      .attr('width', (d) => {
        d.wide = this.xScale(d.max) - d.x;
        return d.wide;
      });

    this.shiftLimiters.selectAll('line.end-stroke')
      .attr('x1', (d) => d.wide)
      .attr('x2', (d) => d.wide);
  }
}


// ERIS Eskola horarios_secciones gantt, las tareas no se solapan
class GanttChartMultiRow {

  width = 'fit';
  height = 350;
  machine = '';
  startDate = new Date('2020-1-1');
  endDate = new Date((new Date('2020-1-1')).getTime() + (24 * 60 * 60 * 1000 * 7));
  allowedMachines = [];
  shifts = [];
  allowCheckboxex = false;
  __overlappingLineHeight = 20;
  tasksPadding = 8;
  __taskElements = [];
  __zoomBrushHeight = 20;
  __fechaHeight = 40;
  __cachedDomain = [];
  __eventListeners = { 'task_clicked': [], 'task-ctrl-clicked': [] };
  __margin = {
    top: 15,
    right: 15
  }

  constructor(elementID, config = {}, translate, height = 0, graficos = []) {
    d3.timeFormatDefaultLocale({
      "decimal": ".",
      "thousands": ",",
      "grouping": [3],
      "currency": ["$", ""],
      "dateTime": "%a %b %e %X %Y",
      "date": "%m/%d/%Y",
      "time": "%H:%M:%S",
      "periods": ["AM", "PM"],
      "days": [translate.instant('domingo'), translate.instant('lunes'), translate.instant('martes'), translate.instant('miercoles'), translate.instant('jueves'), translate.instant('viernes'), translate.instant('sabado')],
      "shortDays": [translate.instant('abrDomingo'), translate.instant('abrLunes'), translate.instant('abrMartes'), translate.instant('abrMiercoles'), translate.instant('abrJueves'), translate.instant('abrViernes'), translate.instant('abrSabado')],
      "months": [translate.instant('enero'), translate.instant('febrero'), translate.instant('marzo'), translate.instant('abril'), translate.instant('mayo'), translate.instant('junio'), translate.instant('julio'), translate.instant('agosto'), translate.instant('septiembre'), translate.instant('octubre'), translate.instant('noviembre'), translate.instant('diciembre')],
      "shortMonths": [translate.instant('abrEnero'), translate.instant('abrFebrero'), translate.instant('abrMarzo'), translate.instant('abrAbril'), translate.instant('abrMayo'), translate.instant('abrJunio'), translate.instant('abrJulio'), translate.instant('abrAgosto'), translate.instant('abrSeptiembre'), translate.instant('abrOctubre'), translate.instant('abrNoviembre'), translate.instant('abrDiciembre')]
    });

    if (height != 0) {
      this.height = height;
      this.tasksPadding = 4;
    }

    if (!elementID) return false;
    for (var k in config) {
      if (k == 'data') this.data = clone(config[k]);
      else this[k] = config[k];
    }

    this.__obj_container = document.getElementById(elementID);
    this.__obj_container.innerHTML = "";
    this.__obj_container.style.display = 'flex';

    if (this.width == 'fit') this.width = this.__obj_container.clientWidth;
    this.__realWidth = this.width - (this.__margin.right);


    this.__realHeight = this.height - (this.__margin.top)

    this.__contentHeight = this.__realHeight - (this.__zoomBrushHeight /*+ 20/*bottom axis height*/) - this.__fechaHeight;

    this.__taskHeight = (this.__contentHeight / this.data.machines.length);

    this.overlappingLinePadding = this.__taskHeight - this.__overlappingLineHeight;



    this.__obj_machione_container = document.createElement('div');

    this.__obj_machione_container.classList.add('machines-container');
    if (this.data.visible) {
      this.__obj_machione_container.setAttribute('hidden', true);
    }



    let machinesHTML = '';
    this.data.machines.forEach(value => {
      if (!this.allowCheckboxex) {
        machinesHTML += `<div class="machine" style="height: ${this.__taskHeight}px;"><div></div><div><span>${value.name}</span></div></div>`;
      } else {
        machinesHTML += `<div class="machine" style="height: ${this.__taskHeight}px;"><div></div><div><input type="checkbox" id="gantt-select-checkbox-${value.id}" class="k-checkbox gantt-select-checkbox" style="margin-top: -2px;" value="${value.id}" checked><span style="padding: 0 5px 0 5px;">${value.name}</span></div></div>`;
      }
    });
    this.__obj_machione_container.innerHTML = machinesHTML;
    this.__obj_machione_container.style.marginTop = (this.__margin.top + this.__zoomBrushHeight + this.__fechaHeight) + 'px';
    this.__obj_container.append(this.__obj_machione_container);
    this.__realWidth -= this.__obj_machione_container.clientWidth;
    //this.__realWidth /= 7;
    if (this.data.visible) {


      this.__obj = document.createElementNS("http://www.w3.org/2000/svg", "svg")
      this.__obj_container.append(this.__obj);
      this.__obj_d3 = d3.select(this.__obj);
      let self = this;
      let selectRect;
      this.__obj_d3
        .attr('visible', this.data.visible)
        .attr('width', this.__realWidth + 'px')
        .attr('height', this.height + 'px')
        .attr('class', "gantt-chart")
        .on("contextmenu", () => d3.event.preventDefault())
        .on('mousedown', function () {
          if (d3.event.button == 2) {
            var e = this,
              origin = d3.mouse(e);
            selectRect.attr('width', 0).attr('display', '')
            origin = [origin[0], origin[1]]
            d3.select("body").classed("noselect", true);
            origin[0] = Math.max(0, Math.min(self.__realWidth, origin[0]));
            origin[1] = Math.max(0, Math.min(self.__realWidth, origin[1]));
            d3.select(window)
              .on("mousemove.zoomRect", function () {
                var m = d3.mouse(e);
                if (m[0] > self.__realWidth) m[0] = self.__realWidth;
                if (m[1] > self.__realHeight) m[1] = self.__realHeight;
                m = [m[0], m[1]]
                m[0] = Math.max(0, Math.min(self.__realWidth, m[0]));
                m[1] = Math.max(0, Math.min(self.__realWidth, m[1]));
                selectRect.attr("x", Math.min(origin[0], m[0]))
                  .attr("y", self.__margin.top + self.__zoomBrushHeight)
                  .attr("width", Math.abs(((m[0]) - (origin[0]))))
                  .attr("height", self.__contentHeight);
              }, true)
              .on("mouseup.zoomRect", function () {
                d3.event.preventDefault();
                d3.select(window).on("mousemove.zoomRect", null).on("mouseup.zoomRect", null);
                d3.select("body").classed("noselect", false);
                var m = d3.mouse(e);
                m[0] = Math.max(0, Math.min(self.__realWidth, m[0]));
                m[1] = Math.max(0, Math.min(self.__contentHeight, m[1]));
                if (m[0] !== origin[0] && m[1] !== origin[1]) {
                  let newDomain;
                  if (origin[0] < m[0]) {
                    newDomain = [self.xScale.invert(origin[0]), self.xScale.invert(m[0])];
                  } else {
                    newDomain = [self.xScale.invert(m[0]), self.xScale.invert(origin[0])];
                  }

                  self.__zoomBrush.call(self.__zoomBrushController.move, [self.xScaleRef(newDomain[0]), self.xScaleRef(newDomain[1])]);
                  for (var i = 0; i < graficos.length; i++) {
                    graficos[i].__zoomBrush.call(graficos[i].__zoomBrushController.move, [graficos[i].xScaleRef(newDomain[0]), graficos[i].xScaleRef(newDomain[1])]);
                  }
                }
                selectRect.attr('display', 'none')
              }, true);
          }
          d3.event.stopPropagation();
        })

      // le ponemos de que hora a que hora son los dias, por defecto son 24h
      this.xScale = d3.scaleTime()
        .domain([this.startDate, this.endDate])
        .range([10, this.__realWidth - 10]); // 10 para dejar espacio y que no empieze pegado al eje y

      this.__cachedRange = this.xScale.range();
      this.__cachedDomain = this.xScale.domain();

      this.yScale = d3.scaleOrdinal()
        .domain(this.data.machines.map((d) => d.id))
        .range(this.data.machines.map((_, k) => k * this.__taskHeight))
      this.__cachedYScaleRange = this.yScale.range();


      this.__taskHeight = this.__taskHeight - (this.tasksPadding * 2); // apply padding after setting yDomain
      this.___overlappingLineHeight = this.___overlappingLineHeight - (this.overlappingLinePadding * 2);
      this.xScaleRef = d3.scaleTime()
        .domain(this.xScale.domain())
        .range([10, this.__realWidth - 10]); // 10 para dejar espacio y que no empieze pegado al eje y

      this.xGridScale = d3.scaleTime()
        .domain(this.xScale.domain())
        .range(this.xScale.range());

      this.__dayArray = this.__obj_d3.append("g");

      this.__zoomBrush = this.__obj_d3.append("g")
        .attr('class', "zoom-brush")
        .attr('transform', `translate(0,${this.__margin.top + this.__fechaHeight})`);

      this.__zoomBrush.append('rect')
        .attr('class', "bg")
        .attr('width', this.__realWidth)
        .attr('height', this.__zoomBrushHeight);


      this.__xScaleAxis = d3.axisBottom(this.xScale)
        .ticks(this.getXTicks(1))
        .tickFormat(d3.timeFormat("%H:%M"));

      let xScalOffsetX = this.__margin.top + this.__fechaHeight + this.__zoomBrushHeight + this.__contentHeight;

      this.__bottomAxis = this.__obj_d3.append("g")
        .attr('class', "axis-bottom")
        .attr('transform', `translate(0,${xScalOffsetX})`)
        .call(this.__xScaleAxis);
      this.__bottomAxis.selectAll('.domain, line').remove();




      this.__zoomBrushController = d3.brushX()
        .extent([[1, 1], [this.__realWidth - 2, this.__zoomBrushHeight - 1]])
        .on("start", function () {
          this.classList.add('grabbing');
        })
        .on("end", function () {
          this.classList.remove('grabbing');
        })
        .on("brush end", () => {
          if ((d3.event.selection && d3.event.sourceEvent) || d3.brushEventException)
            this.setDomain(d3.event.selection.map(this.xScaleRef.invert, this.xScale));
          for (var i = 0; i < graficos.length; i++) {
            graficos[i].setDomain(d3.event.selection.map(this.xScaleRef.invert, this.xScale));
          }
          try {
            var a = d3.event.sourceEvent.sourceEvent.path;
          } catch (error) {
            this.eventoMoverBarraZoom();
          }
        });

      this.eventoMoverBarraZoom = function func() {
      }


      this.__xGridAxis = d3.axisTop()
        .scale(this.xGridScale)
        .ticks(this.getXTicks(0))
        .tickSize(this.__contentHeight, 0, 0)// no queremos que se vean los ticks así que lo comentamos
        .tickFormat("");
      /*
          this.__xGridContainer = this.__obj_d3.append("g")
            .attr('class', "x grid")
            .attr('transform', `translate(0,${xScalOffsetX})`)
            .call(this.__xGridAxis);*/

      this.__yGridAxis = d3.axisRight()
        .scale(this.yScale)
        .tickSize(this.__realWidth, 0, 0)
        .tickFormat("");

      this.__yGridContainer = this.__obj_d3.append("g")
        .attr('class', "y grid")
        .attr('transform', `translate(0,${this.__margin.top + this.__zoomBrushHeight + this.__fechaHeight})`)
        .call(this.__yGridAxis)
        .call((e) => e.selectAll('path, g.tick:first-of-type').remove());

      /* this.shiftContainers = this.__obj_d3.append('g')
         .attr('transform', `translate(0, ${this.__margin.top + this.__zoomBrushHeigh})`)
         .attr('clip-path', "url(#taskClip)");
   
       this.shiftLimiters = this.shiftContainers.selectAll('.delimiter')
         .data(this.shifts)
         .enter().append('g')
         .attr('class', (d) => {
           return "delimiter " + d.clase;
         })
         .attr('transform', (d) => {
           d.x = this.xScale(d.min);
           return `translate(${d.x}, 0)`;
         });
   
       this.shiftLimiters.append('rect')
         .attr('width', (d) => {
           d.wide = this.xScale(d.max) - d.x;
           return d.wide;
         })
         .attr('height', this.__contentHeight)
   
       this.shiftLimiters.append('line')
         .attr('class', 'end-stroke')
         .attr('x1', (d) => d.wide)
         .attr('x2', (d) => d.wide)
         .attr('y2', this.__contentHeight)
   
       this.shiftLimiters.append('line')
         .attr('y2', this.__contentHeight)*/

      this.__taskContainer = this.__obj_d3.append('g')
        //.attr('height', this.__taskHeight)
        .attr('transform', `translate(0, ${this.__margin.top + this.__zoomBrushHeight + this.__fechaHeight})`)
        .attr('class', "task-container")
        .attr('clip-path', "url(#taskClip)");



      this.__definitions = this.__taskContainer.append('defs')
        .html(`
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style="stop-color:rgb(255,255,255,0.15);stop-opacity:1" />
                    <stop offset="100%" style="stop-color:rgb(255,255,255,0);stop-opacity:1" />
                </linearGradient>`);

      this.__overlappingLineContainer = this.__obj_d3.append('g')
        .attr('transform', `translate(0, ${this.__margin.top + this.__zoomBrushHeight + this.__fechaHeight})`)
        .attr('class', "overlappingLines-container")
      //.attr('clip-path', "url(#taskClip)");

      this.__fecha = this.__obj_d3.append('g')
        .attr('class', "fecha_dia")
        .attr('transform', `translate(${this.__realWidth / 4}, ${this.__margin.top})`)



      this.__fechaContainer = this.__fecha.append('rect')
        .attr('height', this.__fechaHeight)
        .attr('width', this.__realWidth / 2)
        .attr('opacity', "0")

      this.__fecha.append('text')
        .attr('fill', "#000000")
        .attr('class', "data")
        .attr('opacity', "1")
        .attr('x', this.__realWidth / 4)
        .attr('y', this.__fechaHeight / 2 - 10)
        .attr('text-anchor', "middle")
        .text(this.dateToYYYY_MM_DD_guion(this.startDate))
      this.__fecha.append('text')
        .attr('fill', "#000000")
        .attr('class', "orduak")
        .attr('opacity', "1")
        .attr('x', this.__realWidth / 4)
        .attr('y', this.__fechaHeight - 10)
        .attr('text-anchor', "middle")
        .text(this.dateToHHMM(this.startDate) + " - " + this.dateToHHMM(this.endDate))




      let showAllButtonWidth = 110,
        showAllButtonHeight = 35;







      /*
          this.__obj_d3.append('g')
            .attr('class', "show-all-button")
            .attr('transform', `translate(${this.__realWidth - showAllButtonWidth},${this.__margin.top + this.__zoomBrushHeight})`)
            .html(` <rect x="0" y="0" width="${showAllButtonWidth}" height="${showAllButtonHeight}" opacity="0" ></rect>
                          <image x="5" y="${showAllButtonHeight / 2 - 10}" width="20" height="20" xlink:href="assets/img/lens.svg" class="amcharts-zoom-out-image"></image>
                          <text x="30" y="${showAllButtonHeight / 2 + 4}" fill="#000000" font-family="Verdana" font-size="11px" opacity="1" text-anchor="center" class="amcharts-zoom-out-label">` + translate.instant('mostrarTodos') + `</text>`)
            //.on('click', () => this.__zoomBrush.call(this.__zoomBrushController.move, [this.xScaleRef(this.startDate), this.xScaleRef(this.endDate)]));
            .on('click', () => this.updateZoomAllCharts(this, graficos, this.startDate, this.endDate)); */

      selectRect = self.__obj_d3.append("rect").attr("class", "zoom-rect").attr('display', 'none');


      // this.__zoomBrushSidesHandlers = this.__zoomBrush.selectAll('.handle--custom')
      //   .data([{ type: "w" }, { type: "e" }])
      //   .enter()
      //   .append('image')
      //   .attr('class', (d) => `handle--custom handle--${d}`)
      //   .attr("width", '30px')
      //   .attr("height", '30px')
      //   .attr("x", (d) => this.xScaleRef(d.type == 'w' && this.__cachedDomain[0] || this.__cachedDomain[1]) - 15)
      //   .attr("y", '-6px')
      //   .attr("xlink:href", 'assets/img/dragIconRoundBig.svg'); 

      // this.__zoomBrush
      //   .call(this.__zoomBrushController);

      // this.__zoomBrush.call(this.__zoomBrushController.move, this.xScale.range()); //we set the brush with the default domain

      this.__zoomBrushTicks = this.__zoomBrush.append('g')
        .attr('class', "x-top-axis")
        .attr('clip-path', "url(#taskClip)")
        .append('g')
        .attr('transform', `translate(10,${this.__zoomBrushHeight + 3})`)
        .call(d3.axisTop(this.xScaleRef)
          .ticks(Math.floor(this.__realWidth / 70))
          .tickFormat((d) => {
            //var dayNames = [translate.instant('abrDomingo'), translate.instant('abrLunes'), translate.instant('abrMartes'), translate.instant('abrMiercoles'), translate.instant('abrJueves'), translate.instant('abrViernes'), translate.instant('abrSabado')];
            let checkDate = new Date(d.getTime());
            checkDate.setHours(0); checkDate.setMinutes(0); checkDate.setSeconds(0);
            return checkDate.getTime() == d.getTime()/* && dayNames[d.getDay()]*/ + " " + d.getDate() || (d.getHours() < 10 ? '0' : '') + d.getHours() + ":" + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
          })
        ).call((d) => this.tickSizeFix(d));

      this.__zoomBrushTicks.selectAll('.domain, line').remove();

      this.__zoomBrushTicks.selectAll("g.tick text")
        .attr("style", (d) => {
          let checkDate = new Date(d.getTime());
          checkDate.setHours(0); checkDate.setMinutes(0); checkDate.setSeconds(0);
          return checkDate.getTime() == d.getTime() && 'font-weight: bold!important;' || null;
        });

      // this.__zoomBrush.moveToFront(); // <=
      //this.__zoomBrushSidesHandlers.moveToFront(); // <=

      this.__obj_d3.append('clipPath')
        .attr('id', "taskClip")
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', this.__realWidth)
        .attr('height', this.__contentHeight);


      //Base loaded, load tasks and left machines
      this.__loadDataTasks()
      this.__loadOverlaps();
    }
  }
  setDomain(domain) {
    this.__cachedDomain = domain;
    this.xScale.domain(domain);
    this.__bottomAxis.call(this.__xScaleAxis)
    this.xGridScale.domain(domain);
    // this.__xGridContainer.call(this.__xGridAxis)

    // this.__zoomBrushSidesHandlers.attr("x", (d) => this.xScaleRef(d.type == 'w' && this.__cachedDomain[0] || this.__cachedDomain[1]) - 15)

    //Checking x grid ticks to be updated or not
    let targetTickIntervals = this.calcXTickInterval();
    if (!this.__xTickIntervals || this.__xTickIntervals[0] != targetTickIntervals[0]) {
      this.__xTickIntervals = targetTickIntervals;
      this.__xScaleAxis.ticks(this.getXTicks(1));
      this.__xGridAxis.ticks(this.getXTicks(0));
    }
    this.__updateTasks();
    this.__updateOverlaps();
  }
  calcXTickInterval() {

    let localDomain = this.__cachedDomain, timerXpixelGroup = (localDomain[1] - localDomain[0]) / this.__realWidth; //group of 10 pixels

    /*
        for(var i = 1; i<localDomain.length-1;i++){
          timerXpixelGroup += localDomain[i+1]-localDomain[i];
        }
        timerXpixelGroup /= this.__realWidth;*/
    // timerXpixelGroup = timerXpixelGroup / this.__realWidth; //group of 10 pixels

    if (timerXpixelGroup < 4000) {
      return ['2.5m', '5m'];
    } else if (timerXpixelGroup < 9000) {
      return ['5m', '10m'];
    } else if (timerXpixelGroup < 35192) {
      return ['15m', '30m'];
    } else if (timerXpixelGroup < 61314) {
      return ['30m', '1h'];
    } else if (timerXpixelGroup < 132061) {
      return ['1h', '2h'];
    } else if (timerXpixelGroup < 207524) {
      return ['2h', '4h'];
    } else if (timerXpixelGroup < 731053) {
      return ['4h', '8h'];
    } else if (timerXpixelGroup < 339586) {
      return ['8h', '12h'];
    } else if (timerXpixelGroup < 1419658) {
      return ['12h', '1d'];
    } else {
      return ['1d', '2d'];
    }// no soporta más de 2 meses, pero se puede adaptar
  }
  __d3Times = {
    '2.5m': d3.timeMinute.every(2.5),
    '5m': d3.timeMinute.every(5),
    '10m': d3.timeMinute.every(10),
    '15m': d3.timeMinute.every(15),
    '30m': d3.timeMinute.every(30),
    '1h': d3.timeHour.every(1),
    '2h': d3.timeHour.every(2),
    '4h': d3.timeHour.every(4),
    '8h': d3.timeHour.every(8),
    '1d': d3.timeDay.every(1),
    '2d': d3.timeDay.every(2)
  }
  getXticks2() {

  }
  getXTicks(type) {
    if (!this.__xTickIntervals) this.__xTickIntervals = this.calcXTickInterval();
    return this.__d3Times[this.__xTickIntervals[type]];
  }
  tickSizeFix(d, checkStart = false) {
    let self = this;
    d.selectAll('g').each(function () {

      let x = parseFloat(this.getAttribute('transform').replace('translate(', '').replace(')', '').split(',')[0]);
      if ((checkStart && x < 0) || self.__realWidth < x + this.getBBox().width) this.remove();

    })

  }
  on(event, callback) {
    this.__eventListeners[event].push(callback);
    return this;
  }
  __callEvent(event, ...args) {
    this.__eventListeners[event].forEach((func) => func.apply(this, args));
  }
  __imageFiltersCache = { length: 0 };
  __getFillFilter(d) {
    if (d.filter) {
      if (!this.__imageFiltersCache[d.filter.src + (d.filter.animated && 'animated' || '')]) {
        let uid = 'task-filter-' + this.__imageFiltersCache.length;
        this.__imageFiltersCache.length++;
        this.__imageFiltersCache[d.filter.src] = {
          uid: '#' + uid,
          element: this.__definitions.append('pattern')
            .attr('id', uid)
            .attr('x', 0)
            .attr('y', 0)
            .attr('class', d.filter.animated && 'filter-animated' || undefined)
            .attr('width', this.__taskHeight / d.filter.size[1] * d.filter.size[0] + 'px')
            .attr('height', this.__taskHeight + 'px')
            .attr('patternUnits', 'userSpaceOnUse')
            .html(`<image xlink:href="${d.filter.src}" x="0" y="0" height="${this.__taskHeight}"></image>`)
        };
      }
      return this.__imageFiltersCache[d.filter.src].uid;
    }
    return '#gradient';
  }
  updateTasks(newData) {
    this.data.tasks = clone(newData);
    this.__displayTasks.remove();
    this.__loadDataTasks();
  }
  updateTask(taskId, newData) {
    for (var taskKey in this.data.tasks) if (taskId == this.data.tasks[taskKey].id) this.data.tasks[taskKey] = clone(newData);
    this.__displayTasks.remove();
    this.__loadDataTasks();
  }
  updateOverlaps(newData) {
    this.data.overlappingPeriods = clone(newData);
    this.__displayOverlaps.remove();
    //this.__loadOverlaps();
  }
  async __loadDataTasks(newData) {
    for (var k in this.data.tasks) {
      this.data.tasks[k].startDate = new Date(this.data.tasks[k].startDate);
      this.data.tasks[k].endDate = new Date(this.data.tasks[k].endDate);
    }

    this.__displayTasks = this.__taskContainer.selectAll('rect')
      .data(this.data.tasks)
      .enter().append('g')
      .attr('transform', (d) => {

        return this.__getTaskTransform2(d);
      });
    let self = this;

    this.__displayTasksRects = this.__displayTasks.append('rect')
      .attr('fill', (d) => d.backgroundColor)
      // .attr('rx', (d) => 5)
      // .attr('ry', (d) => 5)
      .attr('width', function (d) { return self.__getTaskWide(d, this.parentElement); })
      .attr('height', function (d) { return self.__getTaskHeight(d, this.parentElement); })

    let automaticScrollMargin = this.__realWidth * .1,
      [tooltip, tooltipText, tooltipArrow] = (() => {
        let arrow = document.createElement('div');
        arrow.classList.add('arrow');
        let p = document.createElement('p');
        let ttip = document.createElement('div');
        ttip.classList.add('tooltip-gantt');
        ttip.style.display = 'none';
        ttip.style.position = 'fixed';
        ttip.append(p);
        ttip.append(arrow);
        //this.__obj_container.append(ttip);
        document.body.append(ttip);
        return [ttip, p, arrow];
      })();
    let mousePressed;

    this.__displayTasksRectsGradient = this.__displayTasks.append('rect')
      .attr('fill', (d) => `url(${this.__getFillFilter(d)})`)
      /* .attr('x', (d) => ((d.wide > .3 && .4) || 0))
       .attr('y', .2)      */
      .attr('width', (d) => ((d.wide > .5 && d.wide - .4) || d.wide))
      .attr('height', (d) => (d.height - .4))
      .on('click', (d) => { this.__callEvent('task-ctrl-clicked', d.id_grupo) })
      .on('mouseenter, mousemove', (d) => {
        tooltipText.innerHTML = d.detail(d);
        tooltip.style.borderColor = d.backgroundColor;
        tooltipArrow.style.borderTopColor = d.backgroundColor;
        tooltip.style.display = '';
        let offsetTop = (d.y + this.getChartSVGOffset().top + self.__zoomBrushHeight - tooltip.clientHeight - 20);
        if (offsetTop < 0) {
          tooltip.style.top = (d.y + this.getChartSVGOffset().top + self.__zoomBrushHeight + self.__taskHeight + 20) + 'px';
          if (!tooltip.classList.contains('bottom-reverse')) tooltip.classList.add('bottom-reverse');
        } else {
          tooltip.style.top = offsetTop + 20 + 'px';
          if (tooltip.classList.contains('bottom-reverse')) tooltip.classList.remove('bottom-reverse');
        }
        tooltip.style.left = (this.getSVGPosFromScreenPos(d3.event.pageX) - (tooltip.clientWidth / 2)) + 'px';
      })
      .on('mouseleave', () => tooltip.style.display = 'none')
      .call(d3.drag()
     /* .on("start", function (d) {
        setTimeout(() => {
          if (tooltip.style.display != 'none')
            tooltip.style.display = 'none';
          let shouldAdd = this.parentElement.classList.contains('fadeout') ? true : -1;
          self.__displayTasksRectsGradient.each(function (taskD) {
            if (taskD.of != d.of) {
              if (shouldAdd == -1) 
                shouldAdd = !this.parentElement.classList.contains('fadeout');
              if (shouldAdd) 
                this.parentElement.classList.add('fadeout');
              else 
                this.parentElement.classList.remove('fadeout');
            } else {
              if (this.parentElement.classList.contains('fadeout')) 
                this.parentElement.classList.remove('fadeout');
              d3.select(this.parentElement).moveToFront();
            }
          })
        }, 150);
      })*/);
    this.__displayTasks.each(function (d) {
      let textWide = d.of.length * 11;
      d.ofToShow = d.of;

      var i = d.of.length - 2;
      while (textWide >= d.wide && i > 0) {
        d.ofToShow = d.of.slice(0, i) + "...";
        textWide = d.ofToShow.length * 11;
        i--;
      }


      if (d.wide > textWide && d.isOfVisible != true) self.__showTaskOf(this, d, textWide);
      if ((!d.isVisible || d.wide <= textWide) && d.isOfVisible == true) self.__hideTaskOp(this, d);
    })
    let filterAnimated = d3.selectAll('pattern.filter-animated'),
      x = 0;
    setInterval(() => {
      x += .5;
      filterAnimated.each(function () {
        this.setAttribute('x', x);
      });
    }, 50)
  }

  async __loadOverlaps(newData) {

    for (var k in this.data.overlappingPeriods) {
      this.data.overlappingPeriods[k].startDate = new Date(this.data.overlappingPeriods[k].startDate);
      this.data.overlappingPeriods[k].endDate = new Date(this.data.overlappingPeriods[k].endDate);
    }

    this.__displayOverlaps = this.__overlappingLineContainer.selectAll('rect')
      .data(this.data.overlappingPeriods)
      .enter().append('g')
      .attr('transform', (d) => {
        return this.__getTaskTransform3(d);
      });

    let self = this;

    this.__displayOverlapsRects = this.__displayOverlaps.append('rect')
      .attr('fill', "white")
      .attr('width', function (d) { return self.__getTaskWide(d, this.parentElement); })
      .attr('height', 20)

    let automaticScrollMargin = this.__realWidth * .1,
      [tooltip, tooltipText, tooltipArrow] = (() => {
        let arrow = document.createElement('div');
        arrow.classList.add('arrow');
        let p = document.createElement('p');
        let ttip = document.createElement('div');
        ttip.classList.add('tooltip-gantt');
        ttip.style.display = 'none';
        ttip.style.position = 'fixed';
        ttip.append(p);
        ttip.append(arrow);
        //this.__obj_container.append(ttip);
        document.body.append(ttip);
        return [ttip, p, arrow];
      })();
    let mousePressed;
    this.__displayOverlapsRectsGradient = this.__displayOverlaps.append('rect')
      .attr('fill', (d) => `url(${this.__getFillFilter(d)})`)
      /* .attr('x', (d) => ((d.wide > .3 && .4) || 0))
       .attr('y', .2)      */
      .attr('width', (d) => ((d.wide > .5 && d.wide - .4) || d.wide))
      .attr('height', (d) => (20 - .4))

      /* .on('mouseenter, mousemove', (d) => {
         tooltipText.innerHTML = d.detail(d);
         tooltip.style.borderColor = d.backgroundColor;
         tooltipArrow.style.borderTopColor = d.backgroundColor;
         tooltip.style.display = '';
         let offsetTop = (d.y + this.getChartSVGOffset().top + self.__zoomBrushHeight - tooltip.clientHeight - 20);
         if (offsetTop < 0) {
           tooltip.style.top = (d.y + this.getChartSVGOffset().top + self.__zoomBrushHeight + self.__taskHeight + 20) + 'px';
           if (!tooltip.classList.contains('bottom-reverse')) tooltip.classList.add('bottom-reverse');
         } else {
           tooltip.style.top = offsetTop + 20 + 'px';
           if (tooltip.classList.contains('bottom-reverse')) tooltip.classList.remove('bottom-reverse');
         }
         tooltip.style.left = (this.getSVGPosFromScreenPos(d3.event.pageX) - (tooltip.clientWidth / 2)) + 'px';
       })
       .on('mouseleave', () => tooltip.style.display = 'none')*/
      .call(d3.drag()
        .on("start", function (d) {
          setTimeout(() => {
            if (tooltip.style.display != 'none')
              tooltip.style.display = 'none';
            let shouldAdd = this.parentElement.classList.contains('fadeout') ? true : -1;
            self.__displayOverlapsRectsGradient.each(function (taskD) {
              if (taskD.of != d.of) {
                if (shouldAdd == -1)
                  shouldAdd = !this.parentElement.classList.contains('fadeout');
                if (shouldAdd)
                  this.parentElement.classList.add('fadeout');
                else
                  this.parentElement.classList.remove('fadeout');
              } else {
                if (this.parentElement.classList.contains('fadeout'))
                  this.parentElement.classList.remove('fadeout');
                d3.select(this.parentElement).moveToFront();
              }
            })
          }, 150);
        }));

    this.__displayOverlaps.each(function (d) {
      let textWide = d.machinesLeft.length * 10;
      if (d.wide > textWide && d.isOfVisible != true) self.__showPeriodOverlapping(this, d);
      if ((!d.isVisible || d.wide <= textWide) && d.isOfVisible == true) self.__hideTaskOp(this, d);
    })
    let filterAnimated = d3.selectAll('pattern.filter-animated'),
      x = 0;
    setInterval(() => {
      x += .5;
      filterAnimated.each(function () {
        this.setAttribute('x', x);
      });
    }, 50)

  }
  unsellectAllTasks() {
    //Deselecciona todos los tasks del gant
    this.__displayTasksRectsGradient.each(function (taskD) {
      this.parentElement.classList.remove('fadeout');
    })
  }
  getAnyUnsellected() {
    //Devuelve true si no hay ninguna maquina seleccionada y false si hay alguna maquina seleccionada
    var result = true;
    this.__displayTasksRectsGradient.each(function (taskD) {
      if (this.parentElement.classList.contains('fadeout') == true) {
        result = false;
      }
    })
    return result;
  }
  getChartSVGOffset() {
    return this.__obj.getBoundingClientRect();
  }
  getSVGPosFromScreenPos(x, y) {
    let svgBCR = this.getChartSVGOffset();
    return !isNaN(x) ? (
      !isNaN(y) ? {
        top: x - svgBCR.top,
        left: y - svgBCR.left
      } : x
    ) : !isNaN(y) ? y : false;
  }
  __getTaskTransform(d) {
    d.x = this.xScale(d.startDate);
    d.isVisible = this.__isVisible(d);
    return this.__getTranslation(d);
  }
  __getTaskTransform2(d) {
    if (d.y == undefined) {
      d.y = this.yScale(d.machine) + this.tasksPadding;
      if (d.overlap == true) {

        let kont = 0;
        var anterior = d;

        d.overlapWith.forEach(id => {
          let task = this.data.tasks.filter(f => f.id == id)[0];
          let newY = this.yScale(d.machine) + this.tasksPadding;
          // si se solapa con la anterior sumamos kont
          console.log(task)
          if (task.overlapWith.filter(f => f == anterior.id).length != 0) {
            kont++;
          }
          let lagKont = kont;


          while (lagKont > 0) {

            newY = newY + 4 + (this.__taskHeight - this.__overlappingLineHeight) / 3.5;


            lagKont--;
          }
          task.y = newY  // tartea solapatzen direnen artean;

          anterior = task;
          //newY = newY + (this.__taskHeight-this.__overlappingLineHeight)/d.overlapping;
        })


      }
    }


    /*
    var yfrog = this.yfrog.filter(f=>f.grupoM == d.id_grupoMaquinas)[0];
    d.y = d.y+yfrog.yf;
    yfrog.yf = yfrog.yf +(this.__taskHeight-this.__overlappingLineHeight)/d.overlapping;
    */

    d.x = this.xScale(d.startDate);
    d.isVisible = this.__isVisible(d);
    return this.__getTranslation(d);
  }
  __getTaskTransform3(d) {
    d.x = this.xScale(d.startDate);
    d.y = this.yScale(d.machine) + this.overlappingLinePadding;
    d.isVisible = this.__isVisible(d);
    return this.__getTranslation(d);
  }
  __getTranslation = (d) => 'translate(' + d.x + ', ' + d.y + ')';

  __getTaskWide(d) {
    if (d.isVisible) {

      d.wide = this.xScale(d.endDate) - d.x;

    } else {
      d.wide = 0;
    }
    return d.wide;
  }
  __getTaskHeight(d) {
    console.log(d)
    if (d.isVisible) {

      d.height = (this.__taskHeight - this.__overlappingLineHeight) / 3.5;
    } else {
      d.height = 0;
    }
    return d.height;
  }

  __isVisible(d) {
    return (d.startDate.getTime() < this.__cachedDomain[1].getTime() && d.endDate.getTime() > this.__cachedDomain[0].getTime());
  }
  __showTaskOf(taskElement, d, textWide) {
    d.isOfVisible = true;
    d3.select(taskElement).append('text')
      .attr('x', d.wide / 2)
      .attr('y', d.height / 2 + 4)
      .attr('text-anchor', "middle")
      .style('font-size', 11 + 'px')
      .style('font-family', '"Roboto", sans-serif')
      .text((d) => d.ofToShow)
  }
  __showPeriodOverlapping(taskElement, d) {
    var color = "black";
    if (Number(d.machinesLeft) < 0) {
      color = "red";
    }
    d.isOfVisible = true;
    d3.select(taskElement).append('text')
      .attr('x', d.wide / 2) // para que el número esté en el medio
      .attr('y', 14)
      .attr('text-anchor', "middle")
      .attr('fill', color)
      .text((d) => d.machinesLeft)
  }
  __hideTaskOp(taskElement, d) {
    delete d.isOfVisible;
    taskElement.children[taskElement.children.length - 1].remove()
  }
  async __updateTasks() {
    let self = this;
    //check if scale was modifyed or not (to cahnge only the transforms)        
    this.__displayTasks
      .each(function (d) {
        if (!d.dragging) {
          this.setAttribute('transform', self.__getTaskTransform(d));
          this.children[0].setAttribute('width', self.__getTaskWide(d))
          this.children[1].setAttribute('width', (d.wide > .5 && d.wide - .4) || d.wide)
          let textWide = d.of.length * 10;
          d.ofToShow = d.of;

          var i = d.of.length - 2;
          while (textWide >= d.wide && i > 0) {
            d.ofToShow = d.of.slice(0, i) + "...";
            textWide = d.ofToShow.length * 10;
            i--;
          }
          if (d.wide > textWide && d.isOfVisible != true) self.__showTaskOf(this, d);
          if ((!d.isVisible || d.wide <= textWide) && d.isOfVisible == true) self.__hideTaskOp(this, d);
        }
      });


    this.shiftLimiters
      .attr('transform', (d) => {
        d.x = this.xScale(d.min);
        return `translate(${d.x}, 0)`;
      });

    this.shiftLimiters.selectAll('rect')
      .attr('width', (d) => {
        d.wide = this.xScale(d.max) - d.x;
        return d.wide;
      });

    this.shiftLimiters.selectAll('line.end-stroke')
      .attr('x1', (d) => d.wide)
      .attr('x2', (d) => d.wide);

  }
  async __updateOverlaps() {
    let self = this;
    //check if scale was modifyed or not (to cahnge only the transforms)        
    this.__displayOverlaps
      .each(function (d) {
        if (!d.dragging) {
          this.setAttribute('transform', self.__getTaskTransform3(d));
          this.children[0].setAttribute('width', self.__getTaskWide(d))
          this.children[1].setAttribute('width', (d.wide > .5 && d.wide - .4) || d.wide)
          let textWide = d.machinesLeft.length * 10;
          if (d.wide > textWide && d.isOfVisible != true) self.__showPeriodOverlapping(this, d);
          if ((!d.isVisible || d.wide <= textWide) && d.isOfVisible == true) self.__hideTaskOp(this, d);
        }
      });


    this.shiftLimiters
      .attr('transform', (d) => {
        d.x = this.xScale(d.min);
        return `translate(${d.x}, 0)`;
      });

    this.shiftLimiters.selectAll('rect')
      .attr('width', (d) => {
        d.wide = this.xScale(d.max) - d.x;
        return d.wide;
      });

    this.shiftLimiters.selectAll('line.end-stroke')
      .attr('x1', (d) => d.wide)
      .attr('x2', (d) => d.wide);

  }
  updateZoomAllCharts(self, graficos, startDate, endDate) {
    self.__zoomBrush.call(self.__zoomBrushController.move, [self.xScaleRef(startDate), self.xScaleRef(endDate)]);
    for (var i = 0; i < graficos.length; i++) {
      graficos[i].__zoomBrush.call(graficos[i].__zoomBrushController.move, [graficos[i].xScaleRef(startDate), graficos[i].xScaleRef(endDate)]);

    }
  }
  dateToHHMM(fecha) {
    var horas = fecha.getHours().toString();
    var minutos = fecha.getMinutes().toString();
    if (Number(horas) < 10)
      horas = this.addZero(Number(horas));
    if (Number(minutos) < 10)
      minutos = this.addZero(Number(minutos));
    return horas + ':' + minutos;
  }



  dateToYYYY_MM_DD_guion(fecha) {
    //2020-10-25
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    return ano + '/' + this.addZero(mes) + '/' + this.addZero(dia);
  }

  addZero(n) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }


}


// ERIS Eskola reserva maquinas gantt
class GanttChartReservaMaquinas {
  width = 'fit';
  height = 250;
  machine = '';
  startDate = new Date('2020-1-1');
  endDate = new Date((new Date('2020-1-1')).getTime() + (24 * 60 * 60 * 1000 * 7));
  allowedMachines = [];
  shifts = [];
  allowCheckboxex = false;
  tasksPadding = 8;
  __fechaHeight = 40;

  __taskElements = [];
  __zoomBrushHeight = 20;
  __cachedDomain = [];
  __eventListeners = { 'task_clicked': [] };
  __margin = {
    top: 15,
    right: 15
  }

  constructor(elementID, config = {}, translate, height = 0, graficos = []) {
    d3.timeFormatDefaultLocale({
      "decimal": ".",
      "thousands": ",",
      "grouping": [3],
      "currency": ["$", ""],
      "dateTime": "%a %b %e %X %Y",
      "date": "%m/%d/%Y",
      "time": "%H:%M:%S",
      "periods": ["AM", "PM"],
      "days": [translate.instant('domingo'), translate.instant('lunes'), translate.instant('martes'), translate.instant('miercoles'), translate.instant('jueves'), translate.instant('viernes'), translate.instant('sabado')],
      "shortDays": [translate.instant('abrDomingo'), translate.instant('abrLunes'), translate.instant('abrMartes'), translate.instant('abrMiercoles'), translate.instant('abrJueves'), translate.instant('abrViernes'), translate.instant('abrSabado')],
      "months": [translate.instant('enero'), translate.instant('febrero'), translate.instant('marzo'), translate.instant('abril'), translate.instant('mayo'), translate.instant('junio'), translate.instant('julio'), translate.instant('agosto'), translate.instant('septiembre'), translate.instant('octubre'), translate.instant('noviembre'), translate.instant('diciembre')],
      "shortMonths": [translate.instant('abrEnero'), translate.instant('abrFebrero'), translate.instant('abrMarzo'), translate.instant('abrAbril'), translate.instant('abrMayo'), translate.instant('abrJunio'), translate.instant('abrJulio'), translate.instant('abrAgosto'), translate.instant('abrSeptiembre'), translate.instant('abrOctubre'), translate.instant('abrNoviembre'), translate.instant('abrDiciembre')]
    });

    if (height != 0) {
      this.height = height;
      this.tasksPadding = 4;
    }

    if (!elementID) return false;
    for (var k in config) {
      if (k == 'data') this.data = clone(config[k]);
      else this[k] = config[k];
    }
    this.__obj_container = document.getElementById(elementID);
    this.__obj_container.innerHTML = "";
    this.__obj_container.style.display = 'flex';

    if (this.width == 'fit') this.width = this.__obj_container.clientWidth;
    this.__realWidth = this.width - (this.__margin.right);
    this.__realHeight = this.height - (this.__margin.top)

    this.__contentHeight = this.__realHeight - (this.__zoomBrushHeight + 20/*bottom axis height*/) - this.__fechaHeight;

    this.__taskHeight = (this.__contentHeight / this.data.machines.length);

    this.__obj_machione_container = document.createElement('div');
    this.__obj_machione_container.classList.add('machines-container');
    if (this.data.visible) {
      this.__obj_machione_container.setAttribute('hidden', true);
    }
    let machinesHTML = '';
    this.data.machines.forEach(value => {
      machinesHTML += `<div class="machine" style="height: ${this.__taskHeight}px;"><div><img src="${value.image}"></div><div><span>${value.name}</span></div></div>`;
    });
    // this.data.machines.forEach(value => {
    //   if (!this.allowCheckboxex) {
    //     machinesHTML += `<div class="machine" style="height: ${this.__taskHeight}px;"><div></div><div><span>${value.name}</span></div></div>`;
    //   } else {
    //     machinesHTML += `<div class="machine" style="height: ${this.__taskHeight}px;"><div></div><div><input type="checkbox" id="gantt-select-checkbox-${value.id}" class="k-checkbox gantt-select-checkbox" style="margin-top: -2px;" value="${value.id}" checked><span style="padding: 0 5px 0 5px;">${value.name}</span></div></div>`;
    //   }
    // });
    this.__obj_machione_container.innerHTML = machinesHTML;
    this.__obj_machione_container.style.marginTop = (this.__margin.top + this.__zoomBrushHeight + this.__fechaHeight) + 'px';
    this.__obj_container.append(this.__obj_machione_container);

    this.__realWidth -= this.__obj_machione_container.clientWidth;
    if (this.data.visible) {
      this.__obj = document.createElementNS("http://www.w3.org/2000/svg", "svg")
      this.__obj_container.append(this.__obj);
      this.__obj_d3 = d3.select(this.__obj);
      let self = this;
      let selectRect;
      this.__obj_d3
        .attr('width', this.__realWidth + 'px')
        .attr('height', this.height + 'px')
        .attr('class', "gantt-chart")
        .on("contextmenu", () => d3.event.preventDefault())
        .on('mousedown', function () {
          if (d3.event.button == 2) {
            var e = this,
              origin = d3.mouse(e);
            selectRect.attr('width', 0).attr('display', '')
            origin = [origin[0], origin[1]]
            d3.select("body").classed("noselect", true);
            origin[0] = Math.max(0, Math.min(self.__realWidth, origin[0]));
            origin[1] = Math.max(0, Math.min(self.__realWidth, origin[1]));
            d3.select(window)
              .on("mousemove.zoomRect", function () {
                var m = d3.mouse(e);
                if (m[0] > self.__realWidth) m[0] = self.__realWidth;
                if (m[1] > self.__realHeight) m[1] = self.__realHeight;
                m = [m[0], m[1]]
                m[0] = Math.max(0, Math.min(self.__realWidth, m[0]));
                m[1] = Math.max(0, Math.min(self.__realWidth, m[1]));
                selectRect.attr("x", Math.min(origin[0], m[0]))
                  .attr("y", self.__margin.top + self.__zoomBrushHeight)
                  .attr("width", Math.abs(((m[0]) - (origin[0]))))
                  .attr("height", self.__contentHeight);
              }, true)
              .on("mouseup.zoomRect", function () {
                d3.event.preventDefault();
                d3.select(window).on("mousemove.zoomRect", null).on("mouseup.zoomRect", null);
                d3.select("body").classed("noselect", false);
                var m = d3.mouse(e);
                m[0] = Math.max(0, Math.min(self.__realWidth, m[0]));
                m[1] = Math.max(0, Math.min(self.__contentHeight, m[1]));
                if (m[0] !== origin[0] && m[1] !== origin[1]) {
                  let newDomain;
                  if (origin[0] < m[0]) {
                    newDomain = [self.xScale.invert(origin[0]), self.xScale.invert(m[0])];
                  } else {
                    newDomain = [self.xScale.invert(m[0]), self.xScale.invert(origin[0])];
                  }

                  self.__zoomBrush.call(self.__zoomBrushController.move, [self.xScaleRef(newDomain[0]), self.xScaleRef(newDomain[1])]);
                  for (var i = 0; i < graficos.length; i++) {
                    graficos[i].__zoomBrush.call(graficos[i].__zoomBrushController.move, [graficos[i].xScaleRef(newDomain[0]), graficos[i].xScaleRef(newDomain[1])]);
                  }
                }
                selectRect.attr('display', 'none')
              }, true);
          }
          d3.event.stopPropagation();
        })

      this.xScale = d3.scaleTime()
        .domain([this.startDate, this.endDate])
        .range([0, this.__realWidth]);
      this.__cachedRange = this.xScale.range();
      this.__cachedDomain = this.xScale.domain();

      this.yScale = d3.scaleOrdinal()
        .domain(this.data.machines.map((d) => d.id))
        .range(this.data.machines.map((_, k) => k * this.__taskHeight))
      this.__cachedYScaleRange = this.yScale.range();

      this.__taskHeight = this.__taskHeight - (this.tasksPadding * 2); // apply padding after setting yDomain

      this.xScaleRef = d3.scaleTime()
        .domain(this.xScale.domain())
        .range([0, this.__realWidth]);

      this.xGridScale = d3.scaleTime()
        .domain(this.xScale.domain())
        .range(this.xScale.range());

      this.__zoomBrush = this.__obj_d3.append("g")
        .attr('class', "zoom-brush")
        .attr('transform', `translate(0,${this.__margin.top + this.__fechaHeight})`);

      this.__zoomBrush.append('rect')
        .attr('class', "bg")
        .attr('width', this.__realWidth)
        .attr('height', this.__zoomBrushHeight);

      this.__xScaleAxis = d3.axisBottom(this.xScale)
        .ticks(this.getXTicks(1))
        .tickFormat(d3.timeFormat("%H:%M"));

      let xScalOffsetX = this.__margin.top + this.__zoomBrushHeight + this.__contentHeight + this.__fechaHeight;

      this.__bottomAxis = this.__obj_d3.append("g")
        .attr('class', "axis-bottom")
        .attr('transform', `translate(0,${xScalOffsetX})`)
        .call(this.__xScaleAxis);
      this.__bottomAxis.selectAll('.domain, line').remove();


      this.__zoomBrushController = d3.brushX()
        .extent([[1, 1], [this.__realWidth - 2, this.__zoomBrushHeight - 1]])
        .on("start", function () {
          this.classList.add('grabbing');
        })
        .on("end", function () {
          this.classList.remove('grabbing');
        })
        .on("brush end", () => {
          if ((d3.event.selection && d3.event.sourceEvent) || d3.brushEventException)
            this.setDomain(d3.event.selection.map(this.xScaleRef.invert, this.xScale));
          for (var i = 0; i < graficos.length; i++) {
            graficos[i].setDomain(d3.event.selection.map(this.xScaleRef.invert, this.xScale));
          }
          try {
            var a = d3.event.sourceEvent.sourceEvent.path;
          } catch (error) {
            this.eventoMoverBarraZoom();
          }
        });

      this.eventoMoverBarraZoom = function func() {
      }


      this.__xGridAxis = d3.axisTop()
        .scale(this.xGridScale)
        .ticks(this.getXTicks(0))
        .tickSize(this.__contentHeight, 0, 0)
        .tickFormat("");

      this.__xGridContainer = this.__obj_d3.append("g")
        .attr('class', "x grid")
        .attr('transform', `translate(0,${xScalOffsetX})`)
        .call(this.__xGridAxis);

      this.__yGridAxis = d3.axisRight()
        .scale(this.yScale)
        .tickSize(this.__realWidth, 0, 0)
        .tickFormat("");

      this.__yGridContainer = this.__obj_d3.append("g")
        .attr('class', "y grid")
        .attr('transform', `translate(0,${this.__margin.top + this.__zoomBrushHeight + this.__fechaHeight})`)
        .call(this.__yGridAxis)
        .call((e) => e.selectAll('path, g.tick:first-of-type').remove());

      this.shiftContainers = this.__obj_d3.append('g')
        .attr('transform', `translate(0, ${this.__margin.top + this.__zoomBrushHeight + this.__fechaHeight})`)
        .attr('clip-path', "url(#taskClip)");

      this.shiftLimiters = this.shiftContainers.selectAll('.delimiter')
        .data(this.shifts)
        .enter().append('g')
        .attr('class', (d) => {
          return "delimiter " + d.clase;
        })
        .attr('transform', (d) => {
          d.x = this.xScale(d.min);
          return `translate(${d.x}, 0)`;
        });

      this.shiftLimiters.append('rect')
        .attr('width', (d) => {
          d.wide = this.xScale(d.max) - d.x;
          return d.wide;
        })
        .attr('height', this.__contentHeight)

      this.shiftLimiters.append('line')
        .attr('class', 'end-stroke')
        .attr('x1', (d) => d.wide)
        .attr('x2', (d) => d.wide)
        .attr('y2', this.__contentHeight)

      this.shiftLimiters.append('line')
        .attr('y2', this.__contentHeight)

      this.__taskContainer = this.__obj_d3.append('g')
        .attr('transform', `translate(0, ${this.__margin.top + this.__zoomBrushHeight + this.__fechaHeight})`)
        .attr('class', "task-container")
        .attr('clip-path', "url(#taskClip)");



      this.__definitions = this.__taskContainer.append('defs')
        .html(`
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style="stop-color:rgb(255,255,255,0.15);stop-opacity:1" />
                    <stop offset="100%" style="stop-color:rgb(255,255,255,0);stop-opacity:1" />
                </linearGradient>`);

      this.__fecha = this.__obj_d3.append('g')
        .attr('class', "fecha_dia")
        .attr('transform', `translate(${this.__realWidth / 4}, ${this.__margin.top})`)



      this.__fechaContainer = this.__fecha.append('rect')
        .attr('height', this.__fechaHeight)
        .attr('width', this.__realWidth / 2)
        .attr('opacity', "0")

      this.__fecha.append('text')
        .attr('fill', "#000000")
        .attr('class', "data")
        .attr('opacity', "1")
        .attr('x', this.__realWidth / 4)
        .attr('y', this.__fechaHeight / 2 - 10)
        .attr('text-anchor', "middle")
        .text(this.dateToYYYY_MM_DD_guion(this.startDate))
      this.__fecha.append('text')
        .attr('fill', "#000000")
        .attr('class', "orduak")
        .attr('opacity', "1")
        .attr('x', this.__realWidth / 4)
        .attr('y', this.__fechaHeight - 10)
        .attr('text-anchor', "middle")
        .text(this.dateToHHMM(this.startDate) + " - " + this.dateToHHMM(this.endDate))
      let showAllButtonWidth = 110,
        showAllButtonHeight = 35;
      // this.__obj_d3.append('g')
      //   .attr('class', "show-all-button")
      //   .attr('transform', `translate(${this.__realWidth - showAllButtonWidth},${this.__margin.top + this.__zoomBrushHeight})`)
      //   .html(` <rect x="0" y="0" width="${showAllButtonWidth}" height="${showAllButtonHeight}" opacity="0" ></rect>
      //                 <image x="5" y="${showAllButtonHeight / 2 - 10}" width="20" height="20" xlink:href="assets/img/lens.svg" class="amcharts-zoom-out-image"></image>
      //                 <text x="30" y="${showAllButtonHeight / 2 + 4}" fill="#000000" font-family="Verdana" font-size="11px" opacity="1" text-anchor="center" class="amcharts-zoom-out-label">` + translate.instant('mostrarTodos') + `</text>`)
      //   //.on('click', () => this.__zoomBrush.call(this.__zoomBrushController.move, [this.xScaleRef(this.startDate), this.xScaleRef(this.endDate)]));
      //   .on('click', () => this.updateZoomAllCharts(this, graficos, this.startDate, this.endDate));

      // selectRect = self.__obj_d3.append("rect").attr("class", "zoom-rect").attr('display', 'none');


      // this.__zoomBrushSidesHandlers = this.__zoomBrush.selectAll('.handle--custom')
      //   .data([{ type: "w" }, { type: "e" }])
      //   .enter()
      //   .append('image')
      //   .attr('class', (d) => `handle--custom handle--${d}`)
      //   .attr("width", '30px')
      //   .attr("height", '30px')
      //   .attr("x", (d) => this.xScaleRef(d.type == 'w' && this.__cachedDomain[0] || this.__cachedDomain[1]) - 15)
      //   .attr("y", '-6px')
      //   .attr("xlink:href", 'assets/img/dragIconRoundBig.svg');

      this.__zoomBrush
        .call(this.__zoomBrushController);

      this.__zoomBrush.call(this.__zoomBrushController.move, this.xScale.range()); //we set the brush with the default domain

      this.__zoomBrushTicks = this.__zoomBrush.append('g')
        .attr('class', "x-top-axis")
        .attr('clip-path', "url(#taskClip)")
        .append('g')
        .attr('transform', `translate(0,${this.__zoomBrushHeight + 3})`)
        .call(d3.axisTop(this.xScaleRef)
          .ticks(Math.floor(this.__realWidth / 70))
          .tickFormat((d) => {
            var dayNames = [translate.instant('abrDomingo'), translate.instant('abrLunes'), translate.instant('abrMartes'), translate.instant('abrMiercoles'), translate.instant('abrJueves'), translate.instant('abrViernes'), translate.instant('abrSabado')];
            let checkDate = new Date(d.getTime());
            checkDate.setHours(0); checkDate.setMinutes(0); checkDate.setSeconds(0);
            return checkDate.getTime() == d.getTime() && dayNames[d.getDay()] + " " + d.getDate() || (d.getHours() < 10 ? '0' : '') + d.getHours() + ":" + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
          })
        ).call((d) => this.tickSizeFix(d));

      this.__zoomBrushTicks.selectAll('.domain, line').remove();

      this.__zoomBrushTicks.selectAll("g.tick text")
        .attr("style", (d) => {
          let checkDate = new Date(d.getTime());
          checkDate.setHours(0); checkDate.setMinutes(0); checkDate.setSeconds(0);
          return checkDate.getTime() == d.getTime() && 'font-weight: bold!important;' || null;
        });

      this.__zoomBrush.moveToFront(); // <=
      // this.__zoomBrushSidesHandlers.moveToFront(); // <=

      this.__obj_d3.append('clipPath')
        .attr('id', "taskClip")
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', this.__realWidth)
        .attr('height', this.__contentHeight);

      //Base loaded, load tasks
      this.__loadDataTasks()
    }
  }
  setDomain(domain) {
    this.__cachedDomain = domain;
    this.xScale.domain(domain);
    this.__bottomAxis.call(this.__xScaleAxis)
    this.xGridScale.domain(domain);
    this.__xGridContainer.call(this.__xGridAxis)

    this.__zoomBrushSidesHandlers.attr("x", (d) => this.xScaleRef(d.type == 'w' && this.__cachedDomain[0] || this.__cachedDomain[1]) - 15)

    //Checking x grid ticks to be updated or not
    let targetTickIntervals = this.calcXTickInterval();
    if (!this.__xTickIntervals || this.__xTickIntervals[0] != targetTickIntervals[0]) {
      this.__xTickIntervals = targetTickIntervals;
      this.__xScaleAxis.ticks(this.getXTicks(1));
      this.__xGridAxis.ticks(this.getXTicks(0));
    }
    this.__updateTasks();
  }
  calcXTickInterval() {
    let localDomain = this.__cachedDomain,
      timerXpixelGroup = (localDomain[1] - localDomain[0]) / this.__realWidth; //group of 10 pixels
    if (timerXpixelGroup < 4000) {
      return ['2.5m', '5m'];
    } else if (timerXpixelGroup < 9000) {
      return ['5m', '10m'];
    } else if (timerXpixelGroup < 35192) {
      return ['15m', '30m'];
    } else if (timerXpixelGroup < 61314) {
      return ['30m', '1h'];
    } else if (timerXpixelGroup < 132061) {
      return ['1h', '2h'];
    } else if (timerXpixelGroup < 207524) {
      return ['2h', '4h'];
    } else if (timerXpixelGroup < 731053) {
      return ['4h', '8h'];
    } else if (timerXpixelGroup < 339586) {
      return ['8h', '12h'];
    } else if (timerXpixelGroup < 1419658) {
      return ['12h', '1d'];
    } else {
      return ['1d', '2d'];
    }// no soporta más de 2 meses, pero se puede adaptar
  }
  __d3Times = {
    '2.5m': d3.timeMinute.every(2.5),
    '5m': d3.timeMinute.every(5),
    '10m': d3.timeMinute.every(10),
    '15m': d3.timeMinute.every(15),
    '30m': d3.timeMinute.every(30),
    '1h': d3.timeHour.every(1),
    '2h': d3.timeHour.every(2),
    '4h': d3.timeHour.every(4),
    '8h': d3.timeHour.every(8),
    '1d': d3.timeDay.every(1),
    '2d': d3.timeDay.every(2)
  }
  getXTicks(type) {
    if (!this.__xTickIntervals) this.__xTickIntervals = this.calcXTickInterval();
    return this.__d3Times[this.__xTickIntervals[type]];
  }
  tickSizeFix(d, checkStart = false) {
    let self = this;
    d.selectAll('g').each(function () {
      let x = parseFloat(this.getAttribute('transform').replace('translate(', '').replace(')', '').split(',')[0]);
      if ((checkStart && x < 0) || self.__realWidth < x + this.getBBox().width) this.remove();
    });
  }
  on(event, callback) {
    this.__eventListeners[event].push(callback);
    return this;
  }
  __callEvent(event, ...args) {
    this.__eventListeners[event].forEach((func) => func.apply(this, args));
  }
  __imageFiltersCache = { length: 0 };
  __getFillFilter(d) {
    if (d.filter) {
      if (!this.__imageFiltersCache[d.filter.src + (d.filter.animated && 'animated' || '')]) {
        let uid = 'task-filter-' + this.__imageFiltersCache.length;
        this.__imageFiltersCache.length++;
        this.__imageFiltersCache[d.filter.src] = {
          uid: '#' + uid,
          element: this.__definitions.append('pattern')
            .attr('id', uid)
            .attr('x', 0)
            .attr('y', 0)
            .attr('class', d.filter.animated && 'filter-animated' || undefined)
            .attr('width', this.__taskHeight / d.filter.size[1] * d.filter.size[0] + 'px')
            .attr('height', this.__taskHeight + 'px')
            .attr('patternUnits', 'userSpaceOnUse')
            .html(`<image xlink:href="${d.filter.src}" x="0" y="0" height="${this.__taskHeight}"></image>`)
        };
      }
      return this.__imageFiltersCache[d.filter.src].uid;
    }
    return '#gradient';
  }
  updateTasks(newData) {
    this.data.tasks = clone(newData);
    this.__displayTasks.remove();
    this.__loadDataTasks();
  }
  updateTask(taskId, newData) {
    for (var taskKey in this.data.tasks) if (taskId == this.data.tasks[taskKey].id) this.data.tasks[taskKey] = clone(newData);
    this.__displayTasks.remove();
    this.__loadDataTasks();
  }
  async __loadDataTasks() {
    for (var k in this.data.tasks) {
      this.data.tasks[k].startDate = new Date(this.data.tasks[k].startDate);
      this.data.tasks[k].endDate = new Date(this.data.tasks[k].endDate);
    }


    this.__displayTasks = this.__taskContainer.selectAll('rect')
      .data(this.data.tasks)
      .enter().append('g')
      .attr('transform', (d) => {
        d.y = this.yScale(d.machine) + this.tasksPadding;
        return this.__getTaskTransform(d);
      });
    let self = this;

    this.__displayTasksRects = this.__displayTasks.append('rect')
      .attr('fill', (d) => d.backgroundColor)
      // .attr('rx', (d) => 5)
      // .attr('ry', (d) => 5)
      .attr('width', function (d) { return self.__getTaskWide(d, this.parentElement); })
      .attr('height', this.__taskHeight)


    let automaticScrollMargin = this.__realWidth * .1,
      // [tooltip, tooltipText, circulos] = (() => {
      //   let dt = document.createElement('td');
      //   let circulos = document.createElement('span');
      //   let p = document.createElement('span');
      //   p.classList.add('tooltiptext')
      //   let ttip = document.createElement('span');
      //   ttip.classList.add('tooltip-consumibles-contenido');
      //   // ttip.style.position = 'fixed';
      //   ttip.append(p);
      //   dt.append(ttip);
      //  var app = document.getElementsByTagName('app-reservamaquinas');
      //   app[0].append(dt);
      //   app[0].append(circulos);
      //   return [dt, p, circulos];
      // })();
      [tooltip, tooltipText, tooltipArrow] = (() => {
        let arrow = document.createElement('div');
        arrow.classList.add('arrow');
        let p = document.createElement('p');
        let ttip = document.createElement('div');
        ttip.classList.add('tooltip-gantt');
        ttip.style.display = 'none';
        ttip.style.position = 'fixed';
        ttip.append(p);
        ttip.append(arrow);
        //this.__obj_container.append(ttip);
        document.body.append(ttip);
        return [ttip, p, arrow];
      })();
    let mousePressed;
    this.__displayTasksRectsGradient = this.__displayTasks.append('rect')
      .attr('fill', (d) => `url(${this.__getFillFilter(d)})`)
      .attr('x', (d) => ((d.wide > .3 && .4) || 0))
      .attr('y', '.2')
      // .attr('rx', (d) => 5)
      // .attr('ry', (d) => 5)
      .attr('width', (d) => ((d.wide > .5 && d.wide - .4) || d.wide))
      .attr('height', this.__taskHeight - .4)
      .on('click', (d) => { this.__callEvent('task_clicked', d.id) })
      .on('mouseenter, mousemove', (d) => {

        var tooltipNombres = d.detail(d).tooltipNombres;
        if (tooltipNombres) {
          tooltipText.innerHTML = tooltipNombres;
          tooltip.style.borderColor = d.backgroundColor;
          tooltipArrow.style.borderTopColor = d.backgroundColor;
          tooltip.style.display = '';
          let offsetTop = (d.y + this.getChartSVGOffset().top + self.__zoomBrushHeight - tooltip.clientHeight - 20);
          if (offsetTop < 0) {
            tooltip.style.top = (d.y + this.getChartSVGOffset().top + self.__zoomBrushHeight + self.__taskHeight + 20) + 'px';
            if (!tooltip.classList.contains('bottom-reverse')) tooltip.classList.add('bottom-reverse');
          } else {
            tooltip.style.top = offsetTop + 20 + 'px';
            if (tooltip.classList.contains('bottom-reverse')) tooltip.classList.remove('bottom-reverse');
          }
          tooltip.style.left = (this.getSVGPosFromScreenPos(d3.event.pageX) - (tooltip.clientWidth / 2)) + 'px';
        }

      })
      .on('mouseleave', () => tooltip.style.display = 'none')
      .call(d3.drag()
        .on("start", function (d) {
          setTimeout(() => {
            if (tooltip.style.display != 'none')
              tooltip.style.display = 'none';
            let shouldAdd = this.parentElement.classList.contains('fadeout') ? true : -1;
            self.__displayTasksRectsGradient.each(function (taskD) {
              if (taskD.of != d.of) {
                if (shouldAdd == -1)
                  shouldAdd = !this.parentElement.classList.contains('fadeout');
                if (shouldAdd)
                  this.parentElement.classList.add('fadeout');
                else
                  this.parentElement.classList.remove('fadeout');
              } else {
                if (this.parentElement.classList.contains('fadeout'))
                  this.parentElement.classList.remove('fadeout');
                d3.select(this.parentElement).moveToFront();
              }
            })
          }, 150);
        }));
    this.__displayTasks.each(function (d) {
      let textWide = d.of.length * 10;
      if (/*d.wide > textWide && */ d.isOfVisible != true) self.__showTaskOf(this, d);
      if ((!d.isVisible  /*|| d.wide <= textWide*/) && d.isOfVisible == true) self.__hideTaskOp(this, d);
    })
    let filterAnimated = d3.selectAll('pattern.filter-animated'),
      x = 0;
    setInterval(() => {
      x += .5;
      filterAnimated.each(function () {
        this.setAttribute('x', x);
      });
    }, 50)

    // this.__displayCircles = this.__displayTasks.append('span')
    //   .attr('fill', 'none')
    //   .attr('x', (d) => ((d.wide > .3 && .4) || 0))
    //   .attr('y', '.2')
    // .attr('rx', (d) => 5)
    // .attr('ry', (d) => 5)
    //   .attr('width', (d) => ((d.wide > .5 && d.wide - .4) || d.wide))
    //   .attr('height', this.__taskHeight - .4)


    // // this.__displayTasks.innerHTML = this.__displayTasks.innerHTML & ( (d) => (d.detail(d).circulos))
    // this.__displayTasks.innerHTML = 'aratz'

  }

  getChartSVGOffset() {
    return this.__obj.getBoundingClientRect();
  }
  getSVGPosFromScreenPos(x, y) {
    let svgBCR = this.getChartSVGOffset();
    return !isNaN(x) ? (
      !isNaN(y) ? {
        top: x - svgBCR.top,
        left: y - svgBCR.left
      } : x
    ) : !isNaN(y) ? y : false;
  }
  __getTaskTransform(d) {
    d.x = this.xScale(d.startDate);
    d.isVisible = this.__isVisible(d);
    return this.__getTranslation(d);
  }
  __getTranslation = (d) => 'translate(' + d.x + ', ' + d.y + ')';
  __getTaskWide(d) {
    if (d.isVisible) {
      d.wide = this.xScale(d.endDate) - d.x;
    } else {
      d.wide = 0;
    }
    return d.wide;
  }
  __isVisible(d) {
    return (d.startDate.getTime() < this.__cachedDomain[1].getTime() && d.endDate.getTime() > this.__cachedDomain[0].getTime());
  }
  __showTaskOf(taskElement, d) {
    d.isOfVisible = true;
    //ORIGINAL
    // d3.select(taskElement).append('text')
    // .attr('x', d.wide/2)
    // .attr('y', 12)
    // .attr('text-anchor',"middle")
    // .style('font-size', 11 +'px')
    // .text(d.of)

    ////ESTO CARGA EL TEXTO BIEN 
    // var a = d3.select(taskElement).append('text')      
    // .attr('x', 5)
    // .attr('y', 15)
    // .text('elene')

    //   d3.select(taskElement).append('div')
    //   .attr('x', 5)
    //   .attr('y', 15)
    //   .html("aratz")

    //// carga pero no muestra el texto de dentro del div
    // d3.select(taskElement).append('text')
    //   .attr('x', 5)
    //   .attr('y', 15)
    //   .html('<div>aratz</div>')

    d3.select(taskElement).append('span')
      .style('position', "fixed")
      .style('top', 100 + 'px')
      .text("kaixo");


    console.log(d);
    // var circulos = d.detail(d).circulos
    // if(circulos){
    //   let span = document.createElement('span');
    // //arrow.classList.add('co');
    //   this.__obj_container.append(span);
    //   document.body.append(span);
    //   span.style.position = "fixed";
    //   span.style.top = (d.y+ this.getChartSVGOffset().top + this.__zoomBrushHeight + this.__taskHeight + 20) + 'px';
    //   span.style.left = "200px";
    //   span.innerHTML = circulos ;
    // }

  }
  __hideTaskOp(taskElement, d) {
    delete d.isOfVisible;
    taskElement.children[taskElement.children.length - 1].remove()
  }
  async __updateTasks() {
    let self = this;
    //check if scale was modifyed or not (to cahnge only the transforms)        
    this.__displayTasks
      .each(function (d) {
        if (!d.dragging) {
          this.setAttribute('transform', self.__getTaskTransform(d));
          this.children[0].setAttribute('width', self.__getTaskWide(d))
          this.children[1].setAttribute('width', (d.wide > .5 && d.wide - .4) || d.wide)
          let textWide = d.of.length * 10;
          if (d.wide > textWide && d.isOfVisible != true) self.__showTaskOf(this, d);
          if ((!d.isVisible || d.wide <= textWide) && d.isOfVisible == true) self.__hideTaskOp(this, d);
        }
      });


    this.shiftLimiters
      .attr('transform', (d) => {
        d.x = this.xScale(d.min);
        return `translate(${d.x}, 0)`;
      });

    this.shiftLimiters.selectAll('rect')
      .attr('width', (d) => {
        d.wide = this.xScale(d.max) - d.x;
        return d.wide;
      });

    this.shiftLimiters.selectAll('line.end-stroke')
      .attr('x1', (d) => d.wide)
      .attr('x2', (d) => d.wide);

  }
  updateZoomAllCharts(self, graficos, startDate, endDate) {
    self.__zoomBrush.call(self.__zoomBrushController.move, [self.xScaleRef(startDate), self.xScaleRef(endDate)]);
    for (var i = 0; i < graficos.length; i++) {
      graficos[i].__zoomBrush.call(graficos[i].__zoomBrushController.move, [graficos[i].xScaleRef(startDate), graficos[i].xScaleRef(endDate)]);
    }
  }
  dateToYYYY_MM_DD_guion(fecha) {
    //2020-10-25
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    return ano + '/' + this.addZero(mes) + '/' + this.addZero(dia);
  }

  addZero(n) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }
  dateToHHMM(fecha) {
    var horas = fecha.getHours().toString();
    var minutos = fecha.getMinutes().toString();
    if (Number(horas) < 10)
      horas = this.addZero(Number(horas));
    if (Number(minutos) < 10)
      minutos = this.addZero(Number(minutos));
    return horas + ':' + minutos;
  }

}


class GanttChart_estados {
  width = 'fit';
  height = 250;
  machine = '';
  startDate = new Date('2020-1-1');
  endDate = new Date((new Date('2020-1-1')).getTime() + (24 * 60 * 60 * 1000 * 7));
  allowedMachines = [];
  shifts = [];
  allowCheckboxex = false;
  tasksPadding = 8;

  __taskElements = [];
  __zoomBrushHeight = 20;
  __cachedDomain = [];
  __eventListeners = { 'task_clicked': [] };
  __margin = {
    top: 15,
    right: 15
  }

  constructor(elementID, config = {}, translate, height = 0, graficos = []) {
    d3.timeFormatDefaultLocale({
      "decimal": ".",
      "thousands": ",",
      "grouping": [3],
      "currency": ["$", ""],
      "dateTime": "%a %b %e %X %Y",
      "date": "%m/%d/%Y",
      "time": "%H:%M:%S",
      "periods": ["AM", "PM"],
      "days": [translate.instant('domingo'), translate.instant('lunes'), translate.instant('martes'), translate.instant('miercoles'), translate.instant('jueves'), translate.instant('viernes'), translate.instant('sabado')],
      "shortDays": [translate.instant('abrDomingo'), translate.instant('abrLunes'), translate.instant('abrMartes'), translate.instant('abrMiercoles'), translate.instant('abrJueves'), translate.instant('abrViernes'), translate.instant('abrSabado')],
      "months": [translate.instant('enero'), translate.instant('febrero'), translate.instant('marzo'), translate.instant('abril'), translate.instant('mayo'), translate.instant('junio'), translate.instant('julio'), translate.instant('agosto'), translate.instant('septiembre'), translate.instant('octubre'), translate.instant('noviembre'), translate.instant('diciembre')],
      "shortMonths": [translate.instant('abrEnero'), translate.instant('abrFebrero'), translate.instant('abrMarzo'), translate.instant('abrAbril'), translate.instant('abrMayo'), translate.instant('abrJunio'), translate.instant('abrJulio'), translate.instant('abrAgosto'), translate.instant('abrSeptiembre'), translate.instant('abrOctubre'), translate.instant('abrNoviembre'), translate.instant('abrDiciembre')]
    });

    if (height != 0) {
      this.height = height;
      this.tasksPadding = 4;
    }

    if (!elementID) return false;
    for (var k in config) {
      if (k == 'data') this.data = clone(config[k]);
      else this[k] = config[k];
    }
    this.__obj_container = document.getElementById(elementID);
    this.__obj_container.innerHTML = "";
    this.__obj_container.style.display = 'flex';

    if (this.width == 'fit') this.width = this.__obj_container.clientWidth;
    this.__realWidth = this.width - (this.__margin.right);
    this.__realHeight = this.height - (this.__margin.top)

    this.__contentHeight = this.__realHeight - (this.__zoomBrushHeight + 20/*bottom axis height*/)

    this.__taskHeight = (this.__contentHeight / this.data.machines.length);

    this.__obj_machione_container = document.createElement('div');
    this.__obj_machione_container.classList.add('machines-container');
    let machinesHTML = '';
    this.data.machines.forEach(value => {
      if (!this.allowCheckboxex) {
        machinesHTML += `<div class="machine" style="height: ${this.__taskHeight}px;"><div></div><div><span>${value.name}</span></div></div>`;
      } else {
        machinesHTML += `<div class="machine" style="height: ${this.__taskHeight}px;"><div></div><div><input type="checkbox" id="gantt-select-checkbox-${value.id}" class="k-checkbox gantt-select-checkbox" style="margin-top: -2px;" value="${value.id}" checked><span style="padding: 0 5px 0 5px;">${value.name}</span></div></div>`;
      }
    });
    this.__obj_machione_container.innerHTML = machinesHTML;
    this.__obj_machione_container.style.marginTop = (this.__margin.top + this.__zoomBrushHeight) + 'px';
    this.__obj_container.append(this.__obj_machione_container);
    this.__realWidth -= this.__obj_machione_container.clientWidth;

    this.__obj = document.createElementNS("http://www.w3.org/2000/svg", "svg")
    this.__obj_container.append(this.__obj);
    this.__obj_d3 = d3.select(this.__obj);
    let self = this;
    let selectRect;
    this.__obj_d3
      .attr('width', this.__realWidth + 'px')
      .attr('height', this.height + 'px')
      .attr('class', "gantt-chart")
      .on("contextmenu", () => d3.event.preventDefault())
      .on('mousedown', function () {
        if (d3.event.button == 2) {
          var e = this,
            origin = d3.mouse(e);
          selectRect.attr('width', 0).attr('display', '')
          origin = [origin[0], origin[1]]
          d3.select("body").classed("noselect", true);
          origin[0] = Math.max(0, Math.min(self.__realWidth, origin[0]));
          origin[1] = Math.max(0, Math.min(self.__realWidth, origin[1]));
          d3.select(window)
            .on("mousemove.zoomRect", function () {
              var m = d3.mouse(e);
              if (m[0] > self.__realWidth) m[0] = self.__realWidth;
              if (m[1] > self.__realHeight) m[1] = self.__realHeight;
              m = [m[0], m[1]]
              m[0] = Math.max(0, Math.min(self.__realWidth, m[0]));
              m[1] = Math.max(0, Math.min(self.__realWidth, m[1]));
              selectRect.attr("x", Math.min(origin[0], m[0]))
                .attr("y", self.__margin.top + self.__zoomBrushHeight)
                .attr("width", Math.abs(((m[0]) - (origin[0]))))
                .attr("height", self.__contentHeight);
            }, true)
            .on("mouseup.zoomRect", function () {
              d3.event.preventDefault();
              d3.select(window).on("mousemove.zoomRect", null).on("mouseup.zoomRect", null);
              d3.select("body").classed("noselect", false);
              var m = d3.mouse(e);
              m[0] = Math.max(0, Math.min(self.__realWidth, m[0]));
              m[1] = Math.max(0, Math.min(self.__contentHeight, m[1]));
              if (m[0] !== origin[0] && m[1] !== origin[1]) {
                let newDomain;
                if (origin[0] < m[0]) {
                  newDomain = [self.xScale.invert(origin[0]), self.xScale.invert(m[0])];
                } else {
                  newDomain = [self.xScale.invert(m[0]), self.xScale.invert(origin[0])];
                }

                self.__zoomBrush.call(self.__zoomBrushController.move, [self.xScaleRef(newDomain[0]), self.xScaleRef(newDomain[1])]);
                for (var i = 0; i < graficos.length; i++) {
                  graficos[i].__zoomBrush.call(graficos[i].__zoomBrushController.move, [graficos[i].xScaleRef(newDomain[0]), graficos[i].xScaleRef(newDomain[1])]);
                }
              }
              selectRect.attr('display', 'none')
            }, true);
        }
        d3.event.stopPropagation();
      })

    this.xScale = d3.scaleTime()
      .domain([this.startDate, this.endDate])
      .range([0, this.__realWidth]);
    this.__cachedRange = this.xScale.range();
    this.__cachedDomain = this.xScale.domain();

    this.yScale = d3.scaleOrdinal()
      .domain(this.data.machines.map((d) => d.id))
      .range(this.data.machines.map((_, k) => k * this.__taskHeight))
    this.__cachedYScaleRange = this.yScale.range();

    this.__taskHeight = this.__taskHeight - (this.tasksPadding * 2); // apply padding after setting yDomain

    this.xScaleRef = d3.scaleTime()
      .domain(this.xScale.domain())
      .range([0, this.__realWidth]);

    this.xGridScale = d3.scaleTime()
      .domain(this.xScale.domain())
      .range(this.xScale.range());

    this.__zoomBrush = this.__obj_d3.append("g")
      .attr('class', "zoom-brush")
      .attr('transform', `translate(0,${this.__margin.top})`);

    this.__zoomBrush.append('rect')
      .attr('class', "bg")
      .attr('width', this.__realWidth)
      .attr('height', this.__zoomBrushHeight);

    this.__xScaleAxis = d3.axisBottom(this.xScale)
      .ticks(this.getXTicks(1))
      .tickFormat(d3.timeFormat("%H:%M"));

    let xScalOffsetX = this.__margin.top + this.__zoomBrushHeight + this.__contentHeight;

    this.__bottomAxis = this.__obj_d3.append("g")
      .attr('class', "axis-bottom")
      .attr('transform', `translate(0,${xScalOffsetX})`)
      .call(this.__xScaleAxis);
    this.__bottomAxis.selectAll('.domain, line').remove();


    this.__zoomBrushController = d3.brushX()
      .extent([[1, 1], [this.__realWidth - 2, this.__zoomBrushHeight - 1]])
      .on("start", function () {
        this.classList.add('grabbing');
      })
      .on("end", function () {
        this.classList.remove('grabbing');
      })
      .on("brush end", () => {
        if ((d3.event.selection && d3.event.sourceEvent) || d3.brushEventException)
          this.setDomain(d3.event.selection.map(this.xScaleRef.invert, this.xScale));
        for (var i = 0; i < graficos.length; i++) {
          graficos[i].setDomain(d3.event.selection.map(this.xScaleRef.invert, this.xScale));
        }
        try {
          var a = d3.event.sourceEvent.sourceEvent.path;
        } catch (error) {
          this.eventoMoverBarraZoom();
        }
      });

    this.eventoMoverBarraZoom = function func() {
    }


    this.__xGridAxis = d3.axisTop()
      .scale(this.xGridScale)
      .ticks(this.getXTicks(0))
      .tickSize(this.__contentHeight, 0, 0)
      .tickFormat("");

    this.__xGridContainer = this.__obj_d3.append("g")
      .attr('class', "x grid")
      .attr('transform', `translate(0,${xScalOffsetX})`)
      .call(this.__xGridAxis);

    this.__yGridAxis = d3.axisRight()
      .scale(this.yScale)
      .tickSize(this.__realWidth, 0, 0)
      .tickFormat("");

    this.__yGridContainer = this.__obj_d3.append("g")
      .attr('class', "y grid")
      .attr('transform', `translate(0,${this.__margin.top + this.__zoomBrushHeight})`)
      .call(this.__yGridAxis)
      .call((e) => e.selectAll('path, g.tick:first-of-type').remove());

    this.shiftContainers = this.__obj_d3.append('g')
      .attr('transform', `translate(0, ${this.__margin.top + this.__zoomBrushHeight})`)
      .attr('clip-path', "url(#taskClip)");

    this.shiftLimiters = this.shiftContainers.selectAll('.delimiter')
      .data(this.shifts)
      .enter().append('g')
      .attr('class', (d) => {
        return "delimiter " + d.clase;
      })
      .attr('transform', (d) => {
        d.x = this.xScale(d.min);
        return `translate(${d.x}, 0)`;
      });

    this.shiftLimiters.append('rect')
      .attr('width', (d) => {
        d.wide = this.xScale(d.max) - d.x;
        return d.wide;
      })
      .attr('height', this.__contentHeight)

    this.shiftLimiters.append('line')
      .attr('class', 'end-stroke')
      .attr('x1', (d) => d.wide)
      .attr('x2', (d) => d.wide)
      .attr('y2', this.__contentHeight)

    this.shiftLimiters.append('line')
      .attr('y2', this.__contentHeight)

    this.__taskContainer = this.__obj_d3.append('g')
      .attr('transform', `translate(0, ${this.__margin.top + this.__zoomBrushHeight})`)
      .attr('class', "task-container")
      .attr('clip-path', "url(#taskClip)");



    this.__definitions = this.__taskContainer.append('defs')
      .html(`
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style="stop-color:rgb(255,255,255,0.15);stop-opacity:1" />
                    <stop offset="100%" style="stop-color:rgb(255,255,255,0);stop-opacity:1" />
                </linearGradient>`);


    let showAllButtonWidth = 110,
      showAllButtonHeight = 35;
    this.__obj_d3.append('g')
      .attr('class', "show-all-button")
      .attr('transform', `translate(${this.__realWidth - showAllButtonWidth},${this.__margin.top + this.__zoomBrushHeight})`)
      .html(` <rect x="0" y="0" width="${showAllButtonWidth}" height="${showAllButtonHeight}" opacity="0" ></rect>
                    <image x="5" y="${showAllButtonHeight / 2 - 10}" width="20" height="20" xlink:href="assets/img/lens.svg" class="amcharts-zoom-out-image"></image>
                    <text x="30" y="${showAllButtonHeight / 2 + 4}" fill="#000000" font-family="Verdana" font-size="11px" opacity="1" text-anchor="center" class="amcharts-zoom-out-label">` + translate.instant('mostrarTodos') + `</text>`)
      //.on('click', () => this.__zoomBrush.call(this.__zoomBrushController.move, [this.xScaleRef(this.startDate), this.xScaleRef(this.endDate)]));
      .on('click', () => this.updateZoomAllCharts(this, graficos, this.startDate, this.endDate));

    selectRect = self.__obj_d3.append("rect").attr("class", "zoom-rect").attr('display', 'none');


    this.__zoomBrushSidesHandlers = this.__zoomBrush.selectAll('.handle--custom')
      .data([{ type: "w" }, { type: "e" }])
      .enter()
      .append('image')
      .attr('class', (d) => `handle--custom handle--${d}`)
      .attr("width", '30px')
      .attr("height", '30px')
      .attr("x", (d) => this.xScaleRef(d.type == 'w' && this.__cachedDomain[0] || this.__cachedDomain[1]) - 15)
      .attr("y", '-6px')
      .attr("xlink:href", 'assets/img/dragIconRoundBig.svg');

    this.__zoomBrush
      .call(this.__zoomBrushController);

    this.__zoomBrush.call(this.__zoomBrushController.move, this.xScale.range()); //we set the brush with the default domain

    this.__zoomBrushTicks = this.__zoomBrush.append('g')
      .attr('class', "x-top-axis")
      .attr('clip-path', "url(#taskClip)")
      .append('g')
      .attr('transform', `translate(0,${this.__zoomBrushHeight + 3})`)
      .call(d3.axisTop(this.xScaleRef)
        .ticks(Math.floor(this.__realWidth / 70))
        .tickFormat((d) => {
          var dayNames = [translate.instant('abrDomingo'), translate.instant('abrLunes'), translate.instant('abrMartes'), translate.instant('abrMiercoles'), translate.instant('abrJueves'), translate.instant('abrViernes'), translate.instant('abrSabado')];
          let checkDate = new Date(d.getTime());
          checkDate.setHours(0); checkDate.setMinutes(0); checkDate.setSeconds(0);
          return checkDate.getTime() == d.getTime() && dayNames[d.getDay()] + " " + d.getDate() || (d.getHours() < 10 ? '0' : '') + d.getHours() + ":" + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
        })
      ).call((d) => this.tickSizeFix(d));

    this.__zoomBrushTicks.selectAll('.domain, line').remove();

    this.__zoomBrushTicks.selectAll("g.tick text")
      .attr("style", (d) => {
        let checkDate = new Date(d.getTime());
        checkDate.setHours(0); checkDate.setMinutes(0); checkDate.setSeconds(0);
        return checkDate.getTime() == d.getTime() && 'font-weight: bold!important;' || null;
      });

    this.__zoomBrush.moveToFront(); // <=
    this.__zoomBrushSidesHandlers.moveToFront(); // <=

    this.__obj_d3.append('clipPath')
      .attr('id', "taskClip")
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.__realWidth)
      .attr('height', this.__contentHeight);

    //Base loaded, load tasks
    this.__loadDataTasks()
  }
  setDomain(domain) {
    this.__cachedDomain = domain;
    this.xScale.domain(domain);
    this.__bottomAxis.call(this.__xScaleAxis)
    this.xGridScale.domain(domain);
    this.__xGridContainer.call(this.__xGridAxis)

    this.__zoomBrushSidesHandlers.attr("x", (d) => this.xScaleRef(d.type == 'w' && this.__cachedDomain[0] || this.__cachedDomain[1]) - 15)

    //Checking x grid ticks to be updated or not
    let targetTickIntervals = this.calcXTickInterval();
    if (!this.__xTickIntervals || this.__xTickIntervals[0] != targetTickIntervals[0]) {
      this.__xTickIntervals = targetTickIntervals;
      this.__xScaleAxis.ticks(this.getXTicks(1));
      this.__xGridAxis.ticks(this.getXTicks(0));
    }
    this.__updateTasks();
  }
  calcXTickInterval() {
    let localDomain = this.__cachedDomain,
      timerXpixelGroup = (localDomain[1] - localDomain[0]) / this.__realWidth; //group of 10 pixels
    if (timerXpixelGroup < 4000) {
      return ['2.5m', '5m'];
    } else if (timerXpixelGroup < 9000) {
      return ['5m', '10m'];
    } else if (timerXpixelGroup < 35192) {
      return ['15m', '30m'];
    } else if (timerXpixelGroup < 61314) {
      return ['30m', '1h'];
    } else if (timerXpixelGroup < 132061) {
      return ['1h', '2h'];
    } else if (timerXpixelGroup < 207524) {
      return ['2h', '4h'];
    } else if (timerXpixelGroup < 731053) {
      return ['4h', '8h'];
    } else if (timerXpixelGroup < 339586) {
      return ['8h', '12h'];
    } else if (timerXpixelGroup < 1419658) {
      return ['12h', '1d'];
    } else {
      return ['1d', '2d'];
    }// no soporta más de 2 meses, pero se puede adaptar
  }
  __d3Times = {
    '2.5m': d3.timeMinute.every(2.5),
    '5m': d3.timeMinute.every(5),
    '10m': d3.timeMinute.every(10),
    '15m': d3.timeMinute.every(15),
    '30m': d3.timeMinute.every(30),
    '1h': d3.timeHour.every(1),
    '2h': d3.timeHour.every(2),
    '4h': d3.timeHour.every(4),
    '8h': d3.timeHour.every(8),
    '1d': d3.timeDay.every(1),
    '2d': d3.timeDay.every(2)
  }
  getXTicks(type) {
    if (!this.__xTickIntervals) this.__xTickIntervals = this.calcXTickInterval();
    return this.__d3Times[this.__xTickIntervals[type]];
  }
  tickSizeFix(d, checkStart = false) {
    let self = this;
    d.selectAll('g').each(function () {
      let x = parseFloat(this.getAttribute('transform').replace('translate(', '').replace(')', '').split(',')[0]);
      if ((checkStart && x < 0) || self.__realWidth < x + this.getBBox().width) this.remove();
    });
  }
  on(event, callback) {
    this.__eventListeners[event].push(callback);
    return this;
  }
  __callEvent(event, ...args) {
    this.__eventListeners[event].forEach((func) => func.apply(this, args));
  }
  __imageFiltersCache = { length: 0 };
  __getFillFilter(d) {
    if (d.filter) {
      if (!this.__imageFiltersCache[d.filter.src + (d.filter.animated && 'animated' || '')]) {
        let uid = 'task-filter-' + this.__imageFiltersCache.length;
        this.__imageFiltersCache.length++;
        this.__imageFiltersCache[d.filter.src] = {
          uid: '#' + uid,
          element: this.__definitions.append('pattern')
            .attr('id', uid)
            .attr('x', 0)
            .attr('y', 0)
            .attr('class', d.filter.animated && 'filter-animated' || undefined)
            .attr('width', this.__taskHeight / d.filter.size[1] * d.filter.size[0] + 'px')
            .attr('height', this.__taskHeight + 'px')
            .attr('patternUnits', 'userSpaceOnUse')
            .html(`<image xlink:href="${d.filter.src}" x="0" y="0" height="${this.__taskHeight}"></image>`)
        };
      }
      return this.__imageFiltersCache[d.filter.src].uid;
    }
    return '#gradient';
  }
  updateTasks(newData) {
    this.data.tasks = clone(newData);
    this.__displayTasks.remove();
    this.__loadDataTasks();
  }
  updateTask(taskId, newData) {
    for (var taskKey in this.data.tasks) if (taskId == this.data.tasks[taskKey].id) this.data.tasks[taskKey] = clone(newData);
    this.__displayTasks.remove();
    this.__loadDataTasks();
  }
  async __loadDataTasks() {
    for (var k in this.data.tasks) {
      this.data.tasks[k].startDate = new Date(this.data.tasks[k].startDate);
      this.data.tasks[k].endDate = new Date(this.data.tasks[k].endDate);
    }

    this.__displayTasks = this.__taskContainer.selectAll('rect')
      .data(this.data.tasks)
      .enter().append('g')
      .attr('transform', (d) => {
        d.y = this.yScale(d.machine) + this.tasksPadding;
        return this.__getTaskTransform(d);
      });
    let self = this;
    this.__displayTasksRects = this.__displayTasks.append('rect')
      .attr('fill', (d) => d.backgroundColor)
      .attr('y', function (d) { if (d.machine.toString().includes('contador')) { return 5 } else { return 0 } })
      .attr('x', function (d) { if (d.machine.toString().includes('contador')) { return -3 } else { return 0 } })
      .attr('rx', function (d) { if (d.machine.toString().includes('contador')) { return 6 } else { return 0 } })
      .attr('ry', function (d) { if (d.machine.toString().includes('contador')) { return 6 } else { return 0 } })
      .attr('width', function (d) { if (d.machine.toString().includes('contador')) { return 6 } else { return self.__getTaskWide(d, this.parentElement) } })
      .attr('height', function (d) { if (d.machine.toString().includes('contador')) { return 6 } else { return self.__taskHeight } });

    let automaticScrollMargin = this.__realWidth * .1,
      [tooltip, tooltipText, tooltipArrow] = (() => {
        let arrow = document.createElement('div');
        arrow.classList.add('arrow');
        let p = document.createElement('p');
        let ttip = document.createElement('div');
        ttip.classList.add('tooltip-gantt');
        ttip.style.display = 'none';
        ttip.style.position = 'fixed';
        ttip.append(p);
        ttip.append(arrow);
        //this.__obj_container.append(ttip);
        document.body.append(ttip);
        return [ttip, p, arrow];
      })();
    let mousePressed;
    this.__displayTasksRectsGradient = this.__displayTasks.append('rect')
      .attr('fill', (d) => `url(${this.__getFillFilter(d)})`)
      .attr('x', (d) => ((d.wide > .3 && .4) || 0))
      .attr('y', '0.2')
      .attr('class', function (d) { if (d.machine.toString().includes('contador')) { return 'punto' } else { return 'barra' } })
      .attr('width', (d) => ((d.wide > .5 && d.wide - .4) || d.wide))
      .attr('height', this.__taskHeight - .4)
      .on('click', (d) => { if (d3.event.ctrlKey) this.__callEvent('task-ctrl-clicked', d.id); })
      .on('mouseenter, mousemove', (d) => {
        tooltipText.innerHTML = d.detail(d);
        tooltip.style.borderColor = d.backgroundColor;
        tooltipArrow.style.borderTopColor = d.backgroundColor;
        tooltip.style.display = '';
        let offsetTop = (d.y + this.getChartSVGOffset().top + self.__zoomBrushHeight - tooltip.clientHeight - 20);
        if (offsetTop < 0) {
          tooltip.style.top = (d.y + this.getChartSVGOffset().top + self.__zoomBrushHeight + self.__taskHeight + 20) + 'px';
          if (!tooltip.classList.contains('bottom-reverse')) tooltip.classList.add('bottom-reverse');
        } else {
          tooltip.style.top = offsetTop + 20 + 'px';
          if (tooltip.classList.contains('bottom-reverse')) tooltip.classList.remove('bottom-reverse');
        }
        tooltip.style.left = (this.getSVGPosFromScreenPos(d3.event.pageX) - (tooltip.clientWidth / 2)) + 'px';
      })
      .on('mouseleave', () => tooltip.style.display = 'none')
      .call(d3.drag()
        .on("start", function (d) {
          setTimeout(() => {
            if (tooltip.style.display != 'none')
              tooltip.style.display = 'none';
            let shouldAdd = this.parentElement.classList.contains('fadeout') ? true : -1;
            self.__displayTasksRectsGradient.each(function (taskD) {
              if (taskD.idOperacion != d.idOperacion) {
                if (shouldAdd == -1)
                  shouldAdd = !this.parentElement.classList.contains('fadeout');
                if (shouldAdd)
                  this.parentElement.classList.add('fadeout');
                else
                  this.parentElement.classList.remove('fadeout');
              } else {
                if (this.parentElement.classList.contains('fadeout'))
                  this.parentElement.classList.remove('fadeout');
                d3.select(this.parentElement).moveToFront();
              }
            })
          }, 150);
        }));
    this.__displayTasks.each(function (d) {
      let textWide = d.of.length * 10;
      if (d.wide > textWide && d.isOfVisible != true) self.__showTaskOf(this, d);
      if ((!d.isVisible || d.wide <= textWide) && d.isOfVisible == true) self.__hideTaskOp(this, d);
    })
    let filterAnimated = d3.selectAll('pattern.filter-animated'),
      x = 0;
    setInterval(() => {
      x += .5;
      filterAnimated.each(function () {
        this.setAttribute('x', x);
      });
    }, 50)

    this.__updateTasks();
  }

  getChartSVGOffset() {
    return this.__obj.getBoundingClientRect();
  }
  getSVGPosFromScreenPos(x, y) {
    let svgBCR = this.getChartSVGOffset();
    return !isNaN(x) ? (
      !isNaN(y) ? {
        top: x - svgBCR.top,
        left: y - svgBCR.left
      } : x
    ) : !isNaN(y) ? y : false;
  }
  __getTaskTransform(d) {
    d.x = this.xScale(d.startDate);
    d.isVisible = this.__isVisible(d);
    return this.__getTranslation(d);
  }
  __getTranslation = (d) => 'translate(' + d.x + ', ' + d.y + ')';
  __getTaskWide(d) {
    if (d.isVisible) {
      d.wide = this.xScale(d.endDate) - d.x;
    } else {
      d.wide = 0;
    }
    return d.wide;
  }
  __isVisible(d) {
    return (d.startDate.getTime() < this.__cachedDomain[1].getTime() && d.endDate.getTime() > this.__cachedDomain[0].getTime());
  }
  __showTaskOf(taskElement, d) {
    d.isOfVisible = true;
    if (d.machine.toString().includes('contador')) {
      d3.select(taskElement).append('text')
      .attr('x', function (d) { return - (d.of.length * 1.5) })
      .attr('y', function (d) { return 25 })
      .text((d) => d.piezasAmostrarGANTT)      
      .attr('class', function (d) { return 'punto'});
    }else {
      d3.select(taskElement).append('text')
        .attr('x', function (d) {  return 5 })
        .attr('y', function (d) {  return 15 })
        .text((d) => d.of)
        .attr('class', function (d) {  return 'barra' });
    }
  }
  __hideTaskOp(taskElement, d) {
    delete d.isOfVisible;
    taskElement.children[taskElement.children.length - 1].remove()
  }
  async __updateTasks() {
    let self = this;
    //check if scale was modifyed or not (to cahnge only the transforms)        
    this.__displayTasks
      .each(function (d) {
        if (!d.dragging) {
          this.setAttribute('transform', self.__getTaskTransform(d));
          this.children[0].setAttribute('width', self.__getTaskWide(d))
          this.children[1].setAttribute('width', (d.wide > .5 && d.wide - .4) || d.wide)
          if (d.machine.toString().includes('contador')) {
            this.children[0].setAttribute('width', 6)
          }
          let textWide = d.of.length * 10;
          if (d.wide > textWide && d.isOfVisible != true) self.__showTaskOf(this, d);
          if ((!d.isVisible || d.wide <= textWide) && d.isOfVisible == true) self.__hideTaskOp(this, d);
        }
      });


    this.shiftLimiters
      .attr('transform', (d) => {
        d.x = this.xScale(d.min);
        return `translate(${d.x}, 0)`;
      });

    this.shiftLimiters.selectAll('rect')
      .attr('width', (d) => {
        d.wide = this.xScale(d.max) - d.x;
        return d.wide;
      });

    this.shiftLimiters.selectAll('line.end-stroke')
      .attr('x1', (d) => d.wide)
      .attr('x2', (d) => d.wide);

  }
  updateZoomAllCharts(self, graficos, startDate, endDate) {
    self.__zoomBrush.call(self.__zoomBrushController.move, [self.xScaleRef(startDate), self.xScaleRef(endDate)]);
    for (var i = 0; i < graficos.length; i++) {
      graficos[i].__zoomBrush.call(graficos[i].__zoomBrushController.move, [graficos[i].xScaleRef(startDate), graficos[i].xScaleRef(endDate)]);
    }
  }
}